import React from "react";
import styled from "styled-components";

// Styled components
const StepperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 704px;
  margin: 20px auto;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;

  &:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 25%;
    left: calc(50% + 20px);
    width: calc(100% - 38px);
    height: 2px;
    background: ${({ isCompleted }) =>
      isCompleted ? "rgba(129, 53, 121, 1)" : "#ccc"};
    transform: translateY(-50%);
  }
`;

const StepIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid
    ${({ isCompleted, isActive }) =>
      isCompleted || isActive ? "rgba(129, 53, 121, 1)" : "#ccc"};
  background-color: ${({ isCompleted, isActive }) =>
    isCompleted || isActive ? "rgba(129, 53, 121, 1)" : "transparent"};
  color: ${({ isCompleted, isActive }) =>
    isCompleted || isActive ? "white" : "#302439"};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  // font-weight: bold;
`;

const StepLabel = styled.div`
  color: ${({ isActive }) => (isActive ? "rgba(129, 53, 121, 1)" : "#302439")};
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};
  text-align: center;
  margin-top: 5px;
`;

// CustomCompSteps Component
const CustomCompSteps = ({ steps, activeStep }) => {
  return (
    <StepperContainer>
      {steps.map((step, index) => {
        const isActive = index === activeStep;
        const isCompleted = index < activeStep;

        return (
          <Step key={index} isCompleted={isCompleted}>
            <StepIcon isActive={isActive} isCompleted={isCompleted}>
              {isCompleted ? <i className="fa fa-check" /> : `0${index + 1}`}
            </StepIcon>
            <StepLabel isActive={isActive}>{step}</StepLabel>
          </Step>
        );
      })}
    </StepperContainer>
  );
};

export default CustomCompSteps;
