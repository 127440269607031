import {
  abbrNum,
  APIHeaders,
  APIHeadersRAW,
  convertRegion,
  numberWithCommas,
  TO_ABBREVIATED,
} from "../../components/shared/helpers";
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { AppSettings } from "../../config/app-settings";
import Rating4Wall from "./Rating4Wall";
import CompanyTagsList from "./CompanyTagsList";
import GlassDoorIcon from "../../components/shared/GlassDoorIcon";
import IndeedIcon from "../../components/shared/IndeedIcon";
import RealEstateIcon from "../../components/shared/RealEstateIcon";
import FinanceIcon from "../../components/shared/FinanceIcon";
import ProductIcon from "../../components/shared/ProductIcon";
import SocialIcon from "../../components/shared/SocialIcon";
import PeopleIcon from "../../components/shared/PeopleIcon";
import {
  CompanyDescriptionStyle,
  CompanyDetailColumn,
  CompanyMainCardContainer,
  CompanyMetricsInfo,
  CompanyProfileStyle,
  FilterByComponent,
  LogoColumn,
  QuickStatsStyle,
  SocialButtons,
  WrapperLogo,
} from "./styles";
import Competitors from "./Competitors";
import CompanyDescription from "./CompanyDescription";
import { useRecoilState } from "recoil";
import {
  showMediaModalAtom,
  showSingleCompanyAtom,
  singleCompanyIDAtom,
} from "../../atoms/profileAtom";
import { WrapperComponent } from "../Companies";
import {
  colorVariantsCompany,
  ImageContainer,
  ItemGrid,
  MetricTitle,
} from "../StyledComponent/mediaPages";
import { findIndexByValue, lightenVeryDarkColor } from "../../utils/helpers";
import LoadingImage from "../../components/shared/LoadingImage";
import Masonry from "react-masonry-css";
import ModalImage from "../../components/modals/ModalImage";
import MapsByCompanyId from "../Maps/MapsByCompanyId";
import CustomCompIcon from "../../components/shared/CustomCompIcon";

const breakpointColumnsObj = {
  default: 4,
  1800: 3,
  1400: 2,
  500: 1,
};

const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const CompanyProfile = () => {
  const context = useContext(AppSettings);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const baseLogoURL = process.env.REACT_APP_LOGOS_BASE_URL;
  const signature = process.env.REACT_APP_LOGOS_SIGNATURE;
  const [companyDetailLoaded, setCompanyDetailLoaded] = useState(false);
  // const [ rating4Wall, setRating4Wall ] = useState( randomIntFromInterval( 2, 99 ) );
  const [rating4Wall, setRating4Wall] = useState("");
  const [companyDetail, setCompanyDetail] = useState([]);
  const [metricsInfo, setMetricsInfo] = useState([]);
  const [companyDesc, setCompanyDesc] = useState("");
  // const [ competitorsIDs, setCompetitorsIDs ] = useState( [ '45041', '45055', '45082', '45109', '45019' ] ); // temporary hardcoded
  const [competitorsIDs, setCompetitorsIDs] = useState([]); // temporary hardcoded
  const [openSingleCompany, setOpenSingleCompany] = useRecoilState(
    showSingleCompanyAtom
  );
  const [singleCompanyID, setSingleCompanyID] =
    useRecoilState(singleCompanyIDAtom);
  const { id } = useParams();
  const companyId = id || singleCompanyID;
  console.log("singleCompanyID: ", singleCompanyID);
  console.log("id: ", id);
  const [currentTab, setCurrentTab] = useState("Overview");
  const [filteredProductMedia, setFilteredProductMedia] = useState([]);
  const [filteredLocationMedia, setFilteredLocationMedia] = useState([]);
  const [pictureList, setPictureList] = useState([]);
  const [loadingImages, setLoadingImages] = useState(true);
  const [imageObject, setImageObject] = useState([]);
  const [modalLogo, setModalLogo] = useState([]);
  const [showModal, setShowModal] = useRecoilState(showMediaModalAtom);
  // const [ companiesList, setCompaniesList ] = useState( [] );
  const tabNames = ["Overview", "Media", "Map"];

  useEffect(() => {
    context.handleSetAppHeaderNone(false);
    context.handleSetAppSidebarNone(false);
    context.handleSetAppTitle("Company Profile");
    context.handleSetAppIcon(<CustomCompIcon />);
    // setSingleCompanyID('');
  }, []);

  useEffect(() => {
    getPictures();
  }, []);

  useEffect(() => {
    if (companyDetail.description) {
      setCompanyDesc(
        companyDetail.description !== "0" ? companyDetail.description : ""
      );
    }
  }, [companyDetail]);

  useEffect(() => {
    setCompanyDetailLoaded(false);
    setLoadingImages(true);
    // setRating4Wall( randomIntFromInterval( 50, 99 ) ) //temporary generation rating value
    // setCompanyDesc(lorem.generateWords(50));
    // setCompanyDesc(lorem.generateWords(30));
  }, [id, singleCompanyID]);

  useEffect(() => {
    if (!companyDetailLoaded) {
      getCompanyListByID();
      getMetricsInfoByID();
    }
  }, [companyDetailLoaded]);

  useEffect(() => {
    if (pictureList.length > 0 && companyDetail["refId"]) {
      const filteredCompanies = pictureList.filter((pic) => {
        return (
          pic.tags.company === companyDetail["refId"] &&
          pic.tags.metric !== undefined &&
          pic.tags.metric === "product"
        );
      });

      const _filteredLocationsCompanies = pictureList.filter((pic) => {
        return (
          pic.tags.company === companyDetail["refId"] &&
          (pic.tags.locationphototype === "Exterior" ||
            pic.tags.locationphototype === "Interior")
        );
      });

      console.log(_filteredLocationsCompanies);
      setFilteredProductMedia(filteredCompanies);
      setFilteredLocationMedia(_filteredLocationsCompanies);
    }
  }, [pictureList, companyDetail]);

  const getCompanyListByID = () => {
    setCompanyDetailLoaded(false);
    const params = "all=true&includeMetrics=true&ids[]=" + companyId;

    const config = {
      method: "get",
      url: `${baseURL}/companies?${params}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios(config)
      .then((resp) => {
        // console.log( resp.data.data );
        if (resp.data.data.length) {
          setCompanyDetail(resp.data.data[0]);
        }
      })
      .finally(() => {
        setCompanyDetailLoaded(true);
      });
  };

  const getMetricsInfoByID = () => {
    const config = {
      method: "get",
      url: `${baseURL}/companies/${companyId}`,
      headers: APIHeadersRAW,
      withCredentials: true,
      credentials: "include",
    };

    axios(config).then((resp) => {
      if (resp.data) {
        // console.log( resp.data.data[ id ] )
        setMetricsInfo(resp.data.data[companyId]);
      }
    });
  };

  const getCEOFullName = (data) => {
    let _name = "-";
    _name =
      data.ceoFirstName !== "0" && data.ceoFirstName !== null
        ? data.ceoFirstName + " "
        : "-";
    _name +=
      data.ceoLastName !== "0" && data.ceoLastName !== null
        ? data.ceoLastName
        : "";

    return _name;
  };

  const handleChangeTab = (tab) => {
    setCurrentTab(tab);
  };

  const getPictures = () => {
    // setLoadedCompanies( false )
    let data = JSON.stringify({
      filter: {
        thumbnail: false,
        company: [companyDetail["refId"]],
      },
    });
    const config = {
      method: "get",
      url: `${baseURL}/pictures?filter[thumbnail]=false&filter[company]=[${companyDetail["refId"]}]`,
      // url: `${baseURL}/pictures?filter[thumbnail]=true`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
      redirect: "follow",
    };
    axios(config)
      .then((response) => {
        console.log(response.data.data);
        // setPictureList( sortedList( response.data.data ) );
        setPictureList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingImages(false);
      });
  };

  const handlePutImageToModal = (pic, logoImg) => {
    setImageObject(pic);
    setShowModal(true);
    setModalLogo(logoImg);
  };

  const CountAndLink = (locations, openSingleCompany, companyId) => {
    return (
      <>
        {locations} <i className="fa fa-map-location-dot mx-1 color-purple"></i>
        <Link
          to="#"
          onClick={() => handleChangeTab("Map")}
          className="text-link"
        >
          View Map
        </Link>
      </>
    );
  };

  if (!companyDetailLoaded)
    return (
      <div className="d-flex justify-content-center align-items-center text-center h-500px">
        <div className="spinner-border m-5" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );

  return (
    <CompanyProfileStyle
      style={{
        maxWidth: currentTab === "Map" ? "100%" : "1500px",
      }}
    >
      {showModal && <ModalImage imageObject={imageObject} logo={modalLogo} />}
      {openSingleCompany && (
        <div className="mb-3 fs-16px">
          <i className="fa fa-arrow-left color-purple "></i>
          <Link
            to="/companies"
            // to="#"
            onClick={() => setOpenSingleCompany(false)}
            className="text-link mx-2"
          >
            Back to Companies
          </Link>
        </div>
      )}
      <FilterByComponent>
        <CompanyMainCardContainer>
          <div className="d-flex">
            <LogoColumn>
              <WrapperLogo>
                {companyDetail.logoFileName !== null ? (
                  <img
                    src={baseLogoURL + companyDetail.logoFileName + signature}
                    alt={companyDetail.name}
                    title={companyDetail.name}
                  />
                ) : (
                  <>{companyDetail.name}</>
                )}
              </WrapperLogo>
            </LogoColumn>
            <CompanyDetailColumn className="col-md-12 col-lg-12 col-xl-5">
              <div>
                <h2>{companyDetail.name}</h2>
                <Rating4Wall value={rating4Wall} />
              </div>
              <CompanyTagsList list={companyDetail.sectors} />
              <SocialButtons>
                {companyDetail.facebookUrl && (
                  <Link to={companyDetail.facebookUrl} target="_blank">
                    <i className="fa-brands fa-facebook-f"></i>
                  </Link>
                )}
                {companyDetail.instagramUrl && (
                  <Link to={companyDetail.instagramUrl} target="_blank">
                    <i className="fa-brands fa-instagram"></i>
                  </Link>
                )}
                {companyDetail.tiktokUrl && (
                  <Link to={companyDetail.tiktokUrl} target="_blank">
                    <i className="fa-brands fa-tiktok"></i>
                  </Link>
                )}
                {companyDetail.indeedUrl && (
                  <Link to={companyDetail.indeedUrl} target="_blank">
                    <IndeedIcon />
                  </Link>
                )}
                {companyDetail.glassdoorUrl && (
                  <Link to={companyDetail.glassdoorUrl} target="_blank">
                    <GlassDoorIcon />
                  </Link>
                )}
                {companyDetail.twitterUrl && (
                  <Link to={companyDetail.twitterUrl} target="_blank">
                    <i className="fa-brands fa-twitter"></i>
                  </Link>
                )}
                {companyDetail.linkedin && (
                  <Link to={companyDetail.linkedin} target="_blank">
                    <i className="fa-brands fa-linkedin"></i>
                  </Link>
                )}
              </SocialButtons>
            </CompanyDetailColumn>
          </div>
          <div className="link-button">
            {tabNames.map((tab) => (
              <Link
                to="#"
                className={currentTab === tab ? "active" : ""}
                onClick={() => handleChangeTab(tab)}
              >
                {tab}
              </Link>
            ))}
          </div>
        </CompanyMainCardContainer>

        {currentTab === "Overview" && (
          <div className="base-info  mt-4">
            <div className="info-wrapper">
              <CompanyDescriptionStyle>
                <h2>Information</h2>
                <CompanyDescription desc={companyDesc} />
              </CompanyDescriptionStyle>
              <div className="col-md-12 col-lg-12 col-xl-5">
                <QuickStatsStyle>
                  <h2>Quick Stats</h2>
                  <div className="row">
                    <div className="col-4">
                      <div className="title">HQ</div>
                      <div className="value">
                        {companyDetail.city ? companyDetail.city + ", " : ""}
                        {companyDetail.state
                          ? convertRegion(companyDetail.state, TO_ABBREVIATED)
                          : "-"}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="title">CEO</div>
                      <div className="value">
                        {companyDetail.ceoLinkedinProfile &&
                        companyDetail.ceoLinkedinProfile !== "0" ? (
                          <Link
                            to={companyDetail.ceoLinkedinProfile}
                            target="_blank"
                            className="text-link"
                          >
                            {getCEOFullName(companyDetail)}
                            {/*{companyDetail.ceoFirstName ? companyDetail.ceoFirstName + ' ' : ''}{companyDetail.ceoLastName ? companyDetail.LastName : '-'}*/}
                          </Link>
                        ) : (
                          <>{getCEOFullName(companyDetail)}</>
                        )}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="title">Total Funding</div>
                      <div className="value">
                        {metricsInfo.total_funding
                          ? "$" + abbrNum(+metricsInfo.total_funding, 0)
                          : "-"}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-4">
                      <div className="mb-1">
                        <span className="title">Locations: </span>
                        <span>
                          {metricsInfo.total_stores_open
                            ? numberWithCommas(+metricsInfo.total_stores_open)
                            : "-"}
                        </span>
                      </div>
                      <div>
                        <span className="title">AUV: </span>
                        <span>
                          {metricsInfo.auv
                            ? "$" + abbrNum(+metricsInfo.auv, 1)
                            : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="mb-1">
                        <span className="title">Avg. Rating: </span>
                        <span>
                          {+metricsInfo.avg_google_rating_location > 0
                            ? metricsInfo.avg_google_rating_location
                            : "-"}
                        </span>
                      </div>
                      <div>
                        <span className="title">
                          Avg. # Reviews per store:{" "}
                        </span>
                        <span>
                          {+metricsInfo.avg_google_reviews_location > 0
                            ? numberWithCommas(
                                Math.round(
                                  +metricsInfo.avg_google_reviews_location
                                )
                              )
                            : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </QuickStatsStyle>
              </div>
            </div>
          </div>
        )}

        {currentTab === "Media" && (
          <div className="mt-4 px-4">
            <h3 className="fw-400">Product Media</h3>
            <p>View company product media below</p>

            {loadingImages && (
              <div className="d-flex justify-content-center align-items-center center-block h-500px">
                <div className="spinner-border m-5" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}

            {filteredProductMedia.length === 0 && !loadingImages && (
              <div className="p-5 w-100 text-center">
                <h3 className="fw-400">Media Coming Soon</h3>
              </div>
            )}
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {filteredProductMedia.length > 0 &&
                !loadingImages &&
                filteredProductMedia.map((pic, i) => {
                  // return pic.tags.metric && pic.tags.metric === 'product' &&
                  return (
                    companyDetail && (
                      <ItemGrid
                        className="align-items-center"
                        key={"ItemsGrid" + pic.name + i}
                        style={{
                          // backgroundColor: (logoImg !== undefined && logoImg.color !== null) ? logoImg.color : 'rgba(177, 216, 211, 1)',
                          backgroundColor:
                            companyDetail !== undefined &&
                            companyDetail.color !== null
                              ? lightenVeryDarkColor(companyDetail.color, 0.7)
                              : "rgba(177, 216, 211, 1)",
                        }}
                      >
                        <MetricTitle
                          key={i + pic.tags.company}
                          style={{
                            backgroundColor: "rgba(255,255,255, .4)",
                          }}
                        >
                          <div>
                            {companyDetail !== undefined &&
                              companyDetail.logoFileName !== null &&
                              companyDetail.logoFileName !== undefined && (
                                <img
                                  src={
                                    baseLogoURL +
                                    companyDetail.logoFileName +
                                    signature
                                  }
                                  style={{
                                    width: "auto",
                                    "max-height": "50px",
                                    "max-width": "120px",
                                  }}
                                  alt={pic.name}
                                />
                              )}
                            {/*{logoImg === undefined && logoImg.logo600FileName === null && <h5 className="fw-400">Company name</h5>}*/}
                          </div>
                          <div>
                            <h4 className="fw-400">
                              {pic.tags.metric === "product"
                                ? pic.tags.product
                                : "Metric Name"}
                            </h4>
                            <p>
                              {pic.tags.source} - {pic.tags.date}
                            </p>
                          </div>
                        </MetricTitle>
                        <ImageContainer
                          onClick={() =>
                            handlePutImageToModal(pic, companyDetail)
                          }
                        >
                          <LoadingImage
                            src={pic.uri}
                            style={{ width: "100%" }}
                            alt=""
                          />
                        </ImageContainer>
                      </ItemGrid>
                    )
                  );
                })}
            </Masonry>
          </div>
        )}

        {currentTab === "Media" && (
          <div className="mt-4 px-4">
            <h3 className="fw-400">Location Media</h3>
            <p>View & filter through company location media below</p>

            {loadingImages && (
              <div className="d-flex justify-content-center align-items-center center-block h-500px">
                <div className="spinner-border m-5" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}

            {filteredLocationMedia.length === 0 && !loadingImages && (
              <div className="p-5 w-100 text-center">
                <h3 className="fw-400">Media Coming Soon</h3>
              </div>
            )}
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {filteredLocationMedia.length > 0 &&
                !loadingImages &&
                filteredLocationMedia.map((pic, i) => {
                  // return pic.tags.metric && pic.tags.metric === 'product' &&
                  return (
                    companyDetail && (
                      <ItemGrid
                        className="align-items-center"
                        key={"ItemsGrid" + pic.name + i}
                        style={{
                          // backgroundColor: (logoImg !== undefined && logoImg.color !== null) ? logoImg.color : 'rgba(177, 216, 211, 1)',
                          backgroundColor:
                            companyDetail !== undefined &&
                            companyDetail.color !== null
                              ? lightenVeryDarkColor(companyDetail.color, 0.7)
                              : "rgba(177, 216, 211, 1)",
                        }}
                      >
                        <MetricTitle
                          key={i + pic.tags.company}
                          style={{
                            backgroundColor: "rgba(255,255,255, .4)",
                          }}
                        >
                          <div>
                            {companyDetail !== undefined &&
                              companyDetail.logoFileName !== null &&
                              companyDetail.logoFileName !== undefined && (
                                <img
                                  src={
                                    baseLogoURL +
                                    companyDetail.logoFileName +
                                    signature
                                  }
                                  style={{
                                    width: "auto",
                                    "max-height": "50px",
                                    "max-width": "120px",
                                  }}
                                  alt={pic.name}
                                />
                              )}
                          </div>
                          <div>
                            <h4 className="fw-400">
                              {pic.tags.locationname || "Metric Name"}
                            </h4>
                            <p>
                              {pic.tags.source} - {pic.tags.date}
                            </p>
                          </div>
                        </MetricTitle>
                        <ImageContainer
                          onClick={() =>
                            handlePutImageToModal(pic, companyDetail)
                          }
                        >
                          <LoadingImage
                            src={pic.uri}
                            style={{ width: "100%" }}
                            alt=""
                          />
                        </ImageContainer>
                      </ItemGrid>
                    )
                  );
                })}
            </Masonry>
          </div>
        )}

        {currentTab === "Map" && (
          <div className="mt-4">
            <div className="px-4">
              <h3 className="fw-400">U.S. Map</h3>
              <p>
                See company U.S. locations on the map below.
                <br />
                Click into the state to get a closer look.
              </p>
            </div>
            <MapsByCompanyId />
          </div>
        )}
      </FilterByComponent>

      {currentTab === "Overview" && (
        <WrapperComponent>
          <CompanyMetricsInfo>
            <div className="column">
              <div className="header">
                <RealEstateIcon /> Real Estate
              </div>
              <div className="metric-row">
                <div className="title">Locations</div>
                <div className="value">
                  {metricsInfo.total_stores_open
                    ? CountAndLink(
                        numberWithCommas(+metricsInfo.total_stores_open),
                        openSingleCompany,
                        companyId
                      )
                    : "-"}
                </div>
              </div>
              <div className="metric-row">
                <div className="title">LTM Openings</div>
                <div className="value">
                  {+metricsInfo.new_stores_open > 0
                    ? +metricsInfo.new_stores_open
                    : "-"}
                </div>
              </div>
              <div className="metric-row">
                <div className="title">Total States</div>
                <div className="value">
                  {metricsInfo.total_states ? metricsInfo.total_states : "-"}
                </div>
              </div>
              <div className="metric-row">
                <div className="title">&nbsp;</div>
                <div className="value">&nbsp;</div>
              </div>
            </div>
            <div className="column">
              <div className="header">
                <FinanceIcon /> Finance
              </div>
              <div className="metric-row">
                <div className="title">AUV</div>
                <div className="value">
                  {metricsInfo.auv ? "$" + abbrNum(+metricsInfo.auv, 1) : "-"}
                </div>
              </div>
              <div className="metric-row">
                <div className="title">4-wall EBITDA%</div>
                <div className="value">
                  {metricsInfo.fw_ebitda
                    ? (+metricsInfo.fw_ebitda * 100).toFixed(1) + "%"
                    : "-"}
                </div>
              </div>
              <div className="metric-row">
                <div className="title">Total Funding</div>
                <div className="value">
                  {metricsInfo.total_funding
                    ? "$" + abbrNum(+metricsInfo.total_funding, 0)
                    : "-"}
                </div>
              </div>
              <div className="metric-row">
                <div className="title">Valuation</div>
                <div className="value">
                  {metricsInfo.valuation
                    ? "$" + abbrNum(+metricsInfo.valuation, 1)
                    : "-"}
                </div>
              </div>
              {/*<div className="links-row">*/}
              {/*  <div>*/}
              {/*    <Link to="#">More Financials</Link>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            <div className="column">
              <div className="header">
                <ProductIcon /> Product
              </div>
              <div className="metric-row">
                <div className="title">Avg. Store Rating</div>
                <div className="value">
                  {+metricsInfo.avg_google_rating_location > 0
                    ? +metricsInfo.avg_google_rating_location
                    : "-"}
                </div>
              </div>
              <div className="metric-row">
                <div className="title">Avg. # Reviews per store</div>
                <div className="value">
                  {+metricsInfo.avg_google_reviews_location > 0
                    ? numberWithCommas(
                        Math.round(+metricsInfo.avg_google_reviews_location)
                      )
                    : "-"}
                </div>
              </div>
              <div className="metric-row">
                <div className="title">&nbsp;</div>
                <div className="value">&nbsp;</div>
              </div>
              <div className="metric-row">
                <div className="title">&nbsp;</div>
                <div className="value">&nbsp;</div>
              </div>
              {/*<div className="links-row">*/}
              {/*  <div>*/}
              {/*    <Link to="#">More Financials</Link>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            <div className="column">
              <div className="header">
                <SocialIcon /> Social
              </div>
              <div className="metric-row">
                <div className="title">IG Following</div>
                <div className="value">
                  {metricsInfo.ig_followers_per_company
                    ? abbrNum(+metricsInfo.ig_followers_per_company, 0)
                    : "-"}
                </div>
              </div>
              <div className="metric-row">
                <div className="title">Tik Tok Following</div>
                <div className="value">
                  {metricsInfo.tt_followers_per_company
                    ? abbrNum(+metricsInfo.tt_followers_per_company, 0)
                    : "-"}
                </div>
              </div>
              <div className="metric-row">
                <div className="title">FB Following</div>
                <div className="value">
                  {metricsInfo.fb_followers_per_company
                    ? abbrNum(+metricsInfo.fb_followers_per_company, 0)
                    : "-"}
                </div>
              </div>
              <div className="metric-row">
                <div className="title">&nbsp;</div>
                <div className="value">&nbsp;</div>
              </div>
            </div>
            <div className="column">
              <div className="header">
                <PeopleIcon /> People
              </div>
              <div className="metric-row">
                <div className="title">Glassdoor Rating</div>
                <div className="value">
                  {metricsInfo.glassdoor_rating
                    ? +metricsInfo.glassdoor_rating
                    : "-"}
                </div>
              </div>
              <div className="metric-row">
                <div className="title">Indeed Rating</div>
                <div className="value">
                  {metricsInfo.indeed_rating ? +metricsInfo.indeed_rating : "-"}
                </div>
              </div>
              <div className="metric-row">
                <div className="title">Glassdoor CEO Rating</div>
                <div className="value">
                  {metricsInfo.glassdoor_ceo_rating
                    ? +metricsInfo.glassdoor_ceo_rating
                    : "-"}
                </div>
              </div>
              <div className="metric-row">
                <div className="title">Indeed CEO Rating</div>
                <div className="value">
                  {metricsInfo.indeed_ceo_rating
                    ? +metricsInfo.indeed_ceo_rating
                    : "-"}
                </div>
              </div>
            </div>
          </CompanyMetricsInfo>
        </WrapperComponent>
      )}

      {competitorsIDs && competitorsIDs.length ? (
        <Competitors competitors={competitorsIDs} />
      ) : (
        <></>
      )}
    </CompanyProfileStyle>
  );
};

export default CompanyProfile;
