import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ComposedChart,
  Label,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  abbrNum,
  APIHeaders,
  APIHeadersRAW,
  formatMetric,
} from "../../components/shared/helpers";
import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  chosenTagsAtom,
  CSMetricsAttributesAtom,
  customCompAtom,
  customCompIDAtom,
  inProgressNewCustomCompAtom,
  metricSameStartAtom,
  needUpdateCompAtom,
  newCustomCompNameAtom,
  newCustomCompTypeAtom,
  roleAtom,
  searchCompanyArrayAtom,
  selectedSectorsAtom,
  stepOfCreationCompsAtom,
  timeframeEndDateAtom,
  timeframeStartDateAtom,
} from "../../atoms/profileAtom";
import LegendComponent from "../../components/chart/LegendComponent";
import { colorPatternDefault } from "../../components/shared/Colors";
import MetricsInfoType from "../../components/chart/MetricsInfoType";
import CustomTooltip from "./CustomTooltip";
import CustomizedLabel from "./CustomizedLabel";
import CommonErrorComponent from "../../components/shared/CommonErrorComponent";
import TitleCustomComp from "../../components/shared/TitleCustomComp";
import { AppSettings } from "../../config/app-settings";
import CustomCompIcon from "../../components/shared/CustomCompIcon";
import TitleTagCustomComp from "../../components/shared/TitleTagCustomComp";
import { WrapperCustomComp } from "../market-tracker/MarketTrackerTimeline";

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul>
      {payload.map((entry, index) => (
        <li key={`item-${index}`}>{entry.value}</li>
      ))}
    </ul>
  );
};
<Legend content={renderLegend} />;

const remapData = (data, compMetrics) => {
  let _data = [];
  let _years = [];
  let _yearsSorted = [];

  function checkIfLine(id) {
    let metric_slug = id.split(" / ")[1];

    return compMetrics.find((elem) => {
      return elem.metric_slug === metric_slug && elem.ts_chart_type === "line";
    });
  }

  try {
    if (typeof data === "object") {
      Object.keys(data).forEach((key) => {
        data[key].map((el, i) => {
          if (el.year && !_years.includes(+el.year)) {
            _years.push(+el.year);
          } else if (el.year === undefined && !_years.includes(i)) {
            _years.push(+i);
          }
          return false;
        });
      });
    }

    if (_years) {
      _yearsSorted = _years.sort(function (a, b) {
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      });
      _yearsSorted.map((year, i) => {
        let _obj = {};
        _obj["year"] = year;
        if (typeof data === "object") {
          Object.keys(data).forEach((key) => {
            data[key].map((row, i) => {
              if (
                row.year === year ||
                +row.year === +year ||
                (+year > 1999 && +i === +year)
              ) {
                for (const [k, value] of Object.entries(row)) {
                  // if ( k === 'cogs' || k === 'ebitda' || k === 'fw_ebitda' || k === 'labor' || k === 'same_store_sales' || (+value > 0.01 && +value < 0.99)) {
                  if (
                    k === "cogs" ||
                    k === "ebitda" ||
                    k === "fw_ebitda" ||
                    k === "labor" ||
                    k === "same_store_sales" ||
                    k === "occupancy" ||
                    k === "ga" ||
                    k === "opex"
                  ) {
                    _obj[key + " / " + k] = (+value * 100).toFixed(1);
                  } else if (k !== "year") {
                    if (value !== null) {
                      _obj[key + " / " + k] = +value;
                    }
                  }
                }
              }
              return false;
            });
          });
        }
        _data.push(_obj);
        return false;
      });
    } else {
      return data;
    }
  } catch (e) {
    return data;
  }
  // console.log(_data)
  const currentYear = new Date().getFullYear();
  let lastItem = _data[_data.length - 1];
  let preLastItem = _data[_data.length - 2];

  if (lastItem?.year === currentYear) {
    let lastItemModified = { year: currentYear };
    let preLastItemModified = preLastItem;
    for (const key in preLastItem) {
      if (Object.hasOwnProperty.call(preLastItem, key)) {
        console.log(key);
        if (key !== "year" && checkIfLine(key)) {
          preLastItemModified[`${key} /predicted`] = preLastItem[key];
        }
      }
    }

    for (const key in lastItem) {
      if (Object.hasOwnProperty.call(lastItem, key)) {
        if (key !== "year") {
          lastItemModified[`${key} /predicted`] = lastItem[key];
        }
      }
    }
    // console.log(lastItemModified);
    // console.log(preLastItemModified)

    _data.pop();
    _data.pop();
    _data.push(preLastItemModified);
    _data.push(lastItemModified);
  }

  return _data;
};

function lightenColor(hex, percent) {
  // Remove the '#' character if present
  try {
    hex = hex.replace("#", "");

    // Convert hexadecimal to RGB
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Calculate the new RGB values
    const newR = Math.min(255, r + (255 - r) * (percent / 100));
    const newG = Math.min(255, g + (255 - g) * (percent / 100));
    const newB = Math.min(255, b + (255 - b) * (percent / 100));

    // Convert new RGB values to hexadecimal
    return `#${Math.round(newR).toString(16).padStart(2, "0")}${Math.round(newG)
      .toString(16)
      .padStart(2, "0")}${Math.round(newB).toString(16).padStart(2, "0")}`;
  } catch (e) {
    return hex;
  }
}

const CombineChart = ({ preview }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const context = useContext(AppSettings);
  const [customComp, setCustomComp] = useRecoilState(customCompAtom);
  const [compID, setCompID] = useRecoilState(customCompIDAtom);
  const [needUpdateComp] = useRecoilState(needUpdateCompAtom);
  const [isLoadedComp, setIsLoadedComp] = useState(false);
  const [compData, setCompData] = useState({});
  const [remappedData, setRemappedData] = useState({});
  const [dataKey, setDataKey] = useState({});
  const [compMetrics, setCompMetrics] = useState([]);
  const [invalidData, setInvalidData] = useState(true);
  const [companiesList, setCompaniesList] = useState([]);
  const [companiesListIDs, setCompaniesListIDs] = useState([]);
  const [errorMessageAPI, setErrorMessageAPI] = useState("");
  const chosenTags = useRecoilValue(chosenTagsAtom);
  const [, setInProgress] = useState(false);
  const navigate = useNavigate();
  const [compName] = useRecoilState(newCustomCompNameAtom);
  const [compType] = useRecoilState(newCustomCompTypeAtom);
  const [metricSameStart] = useRecoilState(metricSameStartAtom);
  const [timeframeStartDate] = useRecoilState(timeframeStartDateAtom);
  const [timeframeEndDate] = useRecoilState(timeframeEndDateAtom);
  const [searchCompanyArray] = useRecoilState(searchCompanyArrayAtom);
  const [selectedSectors] = useRecoilState(selectedSectorsAtom);
  const [CSMetricsAttributes] = useRecoilState(CSMetricsAttributesAtom);
  const [stepOfCreationComps, setStepOfCreationComps] = useRecoilState(
    stepOfCreationCompsAtom
  );
  const [inProgressNewCustomComp, setInProgressNewCustomComp] = useRecoilState(
    inProgressNewCustomCompAtom
  );
  const [extraTooltipProps, setExtraTooltipProps] = useState({});
  // console.log('showTooltip: ', showTooltip);
  // console.log("compData: ", compData);
  // console.log("remappedData: ", remappedData);
  // console.log("dataKey: ", dataKey);
  // console.log("compMetrics: ", compMetrics);
  // console.log("companiesList: ", companiesList);

  const role = useRecoilValue(roleAtom);
  let _dataKeys = [];
  const isAdmin = role === "admin";
  // console.log(compMetrics)

  useEffect(() => {
    context.handleSetAppTitle("My Comps Library");
    context.handleSetAppIcon(<CustomCompIcon />);
  }, []);

  useEffect(() => {
    if (preview === undefined && !isLoadedComp) {
      getCustomComp();
    }
  }, [needUpdateComp]);

  useEffect(() => {
    if (id !== compID) {
      setCompID(id);
      setIsLoadedComp(false);
    }
  }, [id]);

  useEffect(() => {
    if (!preview && !isLoadedComp) {
      getCustomComp();
    }
  }, []);

  useEffect(() => {
    if (preview && customComp !== undefined) {
      setIsLoadedComp(true);
      // setInvalidData( false );
    }
  }, [customComp]);

  useEffect(() => {
    if (compData !== undefined) {
      setRemappedData(remapData(compData, compMetrics));
    }
  }, [compData]);

  useEffect(() => {
    console.log("customComp1: ", customComp);
    if (customComp.comp_data) {
      setCompData(customComp.comp_data);
    }
    if (customComp.custom_comp_metrics) {
      setCompMetrics(customComp.custom_comp_metrics);
    }
  }, [customComp]);

  useEffect(() => {
    // console.log(companiesListIDs)
    if (compData) {
      let _list = [];

      Object.keys(compData).forEach((key) => {
        return (_list = [..._list, key]);
      });

      setCompaniesListIDs(_list);
    }
  }, [compData]);

  useEffect(() => {
    if (remappedData && remappedData.length > 0) {
      remappedData.map((el) => {
        for (const [k, value] of Object.entries(el)) {
          if (k !== "year" && !_dataKeys.includes(k)) {
            _dataKeys.push(k);
          }
        }
        return false;
      });
      setDataKey(_dataKeys);
      setInvalidData(false);
    }

    if (remappedData && remappedData.length === undefined) {
      for (const [k, value] of Object.entries(remappedData)) {
        Object.entries(value).map((metric) => {
          if (!_dataKeys.includes(metric[0])) {
            _dataKeys.push(metric[0]);
          }
          return false;
        });
      }

      setDataKey(_dataKeys);
      setInvalidData(false);
    }
  }, [remappedData]);

  const getCustomComp = () => {
    setIsLoadedComp(false);
    const data = new FormData();
    const config = {
      method: "get",
      url: `${baseURL}/custom_comps/${id}?include_data=true`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
      data: data,
    };
    axios(config)
      .then((resp) => {
        setCustomComp(resp.data.data);
        setCompData(resp.data.data.comp_data);
        setCompMetrics(resp.data.data.custom_comp_metrics);
        setIsLoadedComp(true);
        setInvalidData(false);
        // console.log(resp.data.data)
      })
      .catch((error) => {
        setErrorMessageAPI(error.response.data.error.title);
        setIsLoadedComp(true);
        setInvalidData(true);
      });
  };

  useEffect(() => {
    if (companiesListIDs.length) {
      getCompanyListByID();
    }
  }, [companiesListIDs]);
  const handleSaveCustomComps = (e, action) => {
    e.preventDefault();
    let _existingTags = [];
    let _newTags = [];
    chosenTags.map((tag) => {
      if (tag.id !== undefined) {
        _existingTags.push({ tag_id: tag.id });
      } else {
        _newTags.push({ label: tag.label });
      }
    });

    setInProgress(true);
    let _url = `${baseURL}/custom_comps/new`;
    if (action === "save") {
      _url = `${baseURL}/custom_comps`;
    }

    let _compType = "";
    if (compType === "Table") {
      _compType = "CustomComps::Table";
    } else if (compType === "Time Series") {
      _compType = "CustomComps::TimeSeries";
    } else if (compType === "Scatter Plot") {
      _compType = "CustomComps::ScatterPlot";
    } else if (compType === "Company Fundraising History") {
      _compType = "CustomComps::CompanyList";
    } else if (compType === "Sector Investment List") {
      _compType = "CustomComps::SectorList";
    } else if (compType === "Store List") {
      _compType = "CustomComps::StoreList";
    } else if (compType === "Firm Retail Investments") {
      _compType = "CustomComps::InvestorList";
    }

    let metricData = {};
    metricData["custom_comp"] = {
      name: compName,
      type: _compType,
      start_date: !metricSameStart ? timeframeStartDate : "",
      end_date: !metricSameStart ? timeframeEndDate : "",
      taggings_attributes: _existingTags,
      tags: _newTags,
      resource_ids:
        compType !== "Sector Investment List"
          ? searchCompanyArray.map((el) => el.id)
          : selectedSectors.map((el) => el.id),
      custom_comp_metrics_attributes:
        compType !== "Sector Investment List"
          ? CSMetricsAttributes.map((el) => {
              return {
                metric_id: el.metric_id,
                ts_chart_type: el.ts_chart_type,
                same_start: metricSameStart,
              };
            })
          : [],
      // } ) : [{metric_id: 1}],
    };
    // same_start: metricSameStart

    const config = {
      method: "post",
      url: _url,
      headers: APIHeadersRAW,
      withCredentials: true,
      credentials: "include",
      data: metricData,
    };
    axios(config)
      .then((resp) => {
        console.log(resp);
        if (resp.data) {
          setCustomComp(resp.data.data);
          setStepOfCreationComps(4);
        }
        if (action === "save") {
          // toasterNotify( 'Custom Comp was created', '', 'success' );
          navigate("/custom-comps", { replace: false });
          setInProgressNewCustomComp(false);
        }
        setInProgress(false);
      })
      .catch((error) => {
        console.log(error);
        setInProgress(false);
      });
  };

  const getCompanyListByID = () => {
    let params = "";
    companiesListIDs.map((el) => {
      return (params = params + "&ids[]=" + el);
    });

    const config = {
      method: "get",
      url: `${baseURL}/companies?${params}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios(config).then((resp) => {
      setCompaniesList(resp.data.data);
    });
  };

  console.log("remappedData: ", remappedData);

  const [opacity, setOpacity] = useState({});
  const [metricFocused, setMetricFocused] = useState();
  console.log("metricFocused: ", metricFocused);
  console.log("opacity: ", opacity);

  const handleMouseEnter = (o) => {
    const { dataKey } = o;
    setExtraTooltipProps({ active: false });

    // setOpacity((op) => ({ ...op, [dataKey]: 0.5 }));
    setMetricFocused(dataKey);
  };

  const handleMouseLeave = (o) => {
    // const { dataKey } = o;

    // setOpacity((op) => ({ ...op, [dataKey]: 1 }));
    setExtraTooltipProps(undefined);
    setMetricFocused(undefined);
  };

  const areTwoLineMetrics = useMemo(() => {
    return !compMetrics.some((metric) => metric.ts_chart_type === "bar");
  }, [compMetrics]);
  console.log("areTwoLineMetrics: ", areTwoLineMetrics);

  if (!isLoadedComp || id !== compID) {
    return (
      <div className="d-flex justify-content-center align-items-center text-center min-vh-100">
        <div className="spinner-border m-5" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  const comp1 = CustomizedLabel([compMetrics[0]], "left");
  const comp2 = CustomizedLabel([compMetrics[1]], "right");

  const formatXAxis = (tickItem) => {
    // @TODO: fix this
    let _tick = tickItem > 10000 ? "$" + abbrNum(tickItem, 1) : tickItem; // probably money

    return _tick.toString();
  };

  const getYAxisLabelPosition = (metric) => {
    console.log("metric: ", metric);
    let _position = "left";
    let _angle = -90;

    compMetrics.forEach((el, index) => {
      if (metric.includes(el.metric_slug)) {
        _position = index === 0 ? "left" : "right";
        _angle = _position === "left" ? -90 : 90;
      }
    });

    // Object.values(compMetrics).map((el) => {
    //   if (metric.includes(el.metric_slug) && el.ts_chart_type === "line") {
    //     _position = "left";
    //     _angle = -90;
    //   } else if (metric.includes(el.metric_slug) && el.ts_chart_type === "bar") {
    //     _position = "right";
    //     _angle = 90;
    //   }
    // });

    return { value: metric, angle: _angle, position: _position };
  };

  return (
    <div className="row">
      <WrapperCustomComp>
        <div className="col-12 position-relative">
          {!preview && (
            <TitleTagCustomComp
              compName={customComp.name}
              preview={preview}
              isPopular={customComp.default_comp}
              ownerID={customComp.user_id}
              taggings={customComp.taggings}
              id={id}
            />
          )}
          {!preview && (
            <TitleCustomComp
              compName={customComp.name}
              preview={preview}
              isPopular={customComp.default_comp}
              ownerID={customComp.user_id}
              taggings={customComp.taggings}
              id={id}
            />
          )}
          {/* {id === compID && <LegendComponent data={companiesList} />} */}

          {invalidData && (
            <CommonErrorComponent error={errorMessageAPI} admin={isAdmin} />
          )}

          {remappedData.length > 0 && !invalidData && companiesList ? (
            <ResponsiveContainer width="100%" height={500} debounce={1}>
              <ComposedChart
                data={remappedData}
                margin={{
                  top: 25,
                  // right: 30,
                  left: 20,
                  bottom: 10, //due to the Legend
                }}
              >
                <Legend
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  // align="left"
                  // width={0}
                  // height={0}
                  // height={0}
                  // style={{ position: "absolute" }}
                  // chartHeight={0}
                  // verticalAlign="top"
                  layout="vertical"
                  verticalAlign="middle"
                  // className="bg-light"

                  wrapperStyle={{
                    top: 25,
                    left: 100,
                    right: 0,
                    cursor: "pointer",
                    borderRadius: 8,
                    maxWidth: 300,
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#f2f3f4",
                    padding: "8px 24px",
                  }}
                  formatter={(val, entry) => {
                    // console.log("val:", val, entry);
                    const formattedMetric = formatMetric(
                      entry.value,
                      compMetrics,
                      companiesList
                    );
                    if (entry.value.includes("/predicted")) {
                      // return `${formattedMetric.companyName} - ${formattedMetric.metricName} (predicted)`;
                      return null;
                    }
                    return `${formattedMetric.companyName} - ${formattedMetric.metricName}`;
                  }}
                />
                <CartesianGrid vertical={false} strokeDasharray="2000" />
                <XAxis
                  dataKey="year"
                  label={{
                    value: "Year",
                    offset: "-5",
                    position: "insideBottom",
                  }}
                />
                <YAxis
                  yAxisId="left"
                  orientation="left"
                  label={comp1}
                  tickFormatter={formatXAxis}
                />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  label={comp2}
                  tickFormatter={formatXAxis}
                />
                <Tooltip
                  content={
                    <CustomTooltip
                      metric={compMetrics}
                      companiesList={companiesList}
                    />
                  }
                  {...extraTooltipProps}
                  cursor={{ fill: "transparent" }}
                />
                {dataKey && dataKey.length > 0
                  ? dataKey.map((el, i) => {
                      console.log("el: ", el);
                      let _color;
                      let _typeChart;
                      let countLines = 0;
                      const yAxisPosition = getYAxisLabelPosition(el).position;
                      console.log("el: ", el);
                      console.log("yAxisPosition: ", yAxisPosition);

                      companiesList.map((company, index) => {
                        console.log("company: ", company);
                        if (
                          el.indexOf(company.id) === 0 &&
                          company.color !== undefined &&
                          company.color !== null
                        ) {
                          _color = company.color;
                        } else if (el.indexOf(company.id) === 0) {
                          _color = colorPatternDefault[index];
                        }
                      });
                      console.log("_color: ", _color);

                      compMetrics.map((metric) => {
                        if (el.includes(metric.metric_slug)) {
                          _typeChart = metric.ts_chart_type;
                        }
                        if (metric.ts_chart_type === "line") {
                          countLines++;
                        }
                      });
                      // console.log(i % 2 === 0);

                      if (el.includes("/predicted")) {
                        const yAxisPosition =
                          getYAxisLabelPosition(el).position;
                        if (_typeChart === "line") {
                          return (
                            <Line
                              key={i}
                              yAxisId={yAxisPosition}
                              type="monotone"
                              dataKey={el}
                              stroke={_color}
                              strokeDasharray="5 5"
                              legendType="none"
                              strokeOpacity={
                                !!metricFocused
                                  ? metricFocused ===
                                    el.replace(" /predicted", "")
                                    ? 1
                                    : 0.2
                                  : 1
                              }
                            />
                          );
                        }
                        if (_typeChart === "bar") {
                          return (
                            <Bar
                              key={i}
                              yAxisId={yAxisPosition}
                              dataKey={el}
                              barSize={20}
                              strokeWidth="2"
                              strokeDasharray="5 5"
                              stroke={_color}
                              fill={lightenColor(_color, 40)}
                              legendType="none"
                            />
                          );
                        }
                      } else if (_typeChart === "bar") {
                        console.log("_typeChart: ", _typeChart);
                        return (
                          <Bar
                            opacity={
                              !!metricFocused
                                ? metricFocused === el
                                  ? 1
                                  : 0.2
                                : 1
                            }
                            key={i}
                            yAxisId={yAxisPosition}
                            dataKey={el}
                            barSize={20}
                            fill={_color}
                          />
                        );
                      } else {
                        console.log("rendering in last if, el: ", el);
                        console.log(
                          "getYAxisLabelPosition(el).position: ",
                          getYAxisLabelPosition(el).position
                        );

                        return (
                          <Line
                            strokeOpacity={
                              !!metricFocused
                                ? metricFocused === el
                                  ? 1
                                  : 0.2
                                : 1
                            }
                            // name={el.name}
                            // name={formatMetric(el, compMetrics, companiesList).companyName}
                            key={i}
                            dataKey={el}
                            // yAxisId="left"
                            yAxisId={yAxisPosition}
                            // yAxisId={countLines > 1 && i % 2 === 0 ? "right" : "left"}
                            connectNulls={true}
                            type="natural"
                            strokeWidth={1.5}
                            stroke={_color}
                            // strokeDasharray={ (countLines > 1 && i % 2 === 0) ? '3 3' : '0'}
                            strokeDasharray={
                              areTwoLineMetrics && yAxisPosition === "right"
                                ? "3 3"
                                : "0"
                            }
                          />
                        );
                      }
                    })
                  : null}
              </ComposedChart>
            </ResponsiveContainer>
          ) : (
            <></>
          )}
          {remappedData === compData && (
            <ComposedChart
              data={remappedData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis />
              <YAxis yAxisId="left" orientation="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip />
              <Line
                data={remappedData[4]}
                yAxisId="right"
                type="monotone"
                dataKey="fb_followers_per_company"
                stroke={"green"}
              />

              {dataKey && dataKey.length > 0
                ? dataKey.map((el, i) => {
                    let _color;

                    companiesListIDs.map((comp, i) => {
                      if (el.indexOf(comp) === 0) {
                        _color = colorPatternDefault[i];
                      }
                    });
                  })
                : null}
            </ComposedChart>
          )}
          <MetricsInfoType data={compMetrics} />
        </div>
        {preview && (
          <div className="d-flex justify-content-end my-3">
            <Link
              to={`/new-comp-set/${stepOfCreationComps - 1}`}
              className="fs-16px btn w-100px btn-outline-primary text-uppercase mx-4"
            >
              BACK
            </Link>
            <Link
              to="#"
              className="fs-16px w-100px h-40px btn btn-primary btn-outline-light"
              onClick={(e) => handleSaveCustomComps(e, "save")}
            >
              SAVE
            </Link>
          </div>
        )}
      </WrapperCustomComp>
    </div>
  );
};

export default CombineChart;
