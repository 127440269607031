import React from "react";
import {
  CircleLegend,
  DashCircleLegend,
} from "../../components/chart/LegendComponent";
import { abbrNum } from "../../components/shared/helpers";
import styled from "styled-components";

const StyledTooltipDiv = styled.div`
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px 24px;
  padding-top: 10px;
`;

function findKeysWithSubstring(obj, substring) {
  const keysWithSubstring = [];
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key) && key.includes(substring)) {
      keysWithSubstring.push(key);
    }
  }
  return keysWithSubstring;
}

const CustomTooltip = ({ active, payload, label, metric, companiesList }) => {
  // console.log('payload: ', payload);
  if (active && payload && payload.length) {
    let pldYear = payload.map((el) => el.payload.year)[0];

    return (
      <StyledTooltipDiv className="custom-tooltip bg-light color-light">
        <h5 className="text-center">{`${label}`}</h5>
        <div>
          {payload.map((pld) => {
            // console.log("pld: ", pld);
            let _name = "";
            let _value = "";
            let _metricName;
            // console.log(pld);
            companiesList.map((company) => {
              if (pld.name.indexOf(company.id) >= 0) {
                return (_name = company.name);
              }
            });
            metric.map((metric) => {
              if (pld.name.indexOf(metric.metric_slug) > 0) {
                return (_metricName = metric.metric_name);
              }
            });
            if (
              _metricName === "AUV ($M)" ||
              _metricName === "Enterprise Value" ||
              _metricName === "CAPEX / Unit" ||
              _metricName === "Valuation" ||
              _metricName === "Market Cap"
            ) {
              _value = "$" + abbrNum(pld.value, 1);
            } else if (
              _metricName === "Revenue" ||
              _metricName === "Total Funding"
            ) {
              _value = "$" + abbrNum(pld.value, 0);
            } else if (
              _metricName === "Sales / CAPEX" ||
              _metricName === "EV/LTM EBITDA" ||
              _metricName === "EV/LTM Sales"
            ) {
              let __value = +pld.value;
              _value = __value.toFixed(1) + "x";
            } else if (_metricName === "EBITDA / CAPEX") {
              let __value = +pld.value;
              _value = __value.toFixed(0) + "%";
            } else if (
              _metricName === "Same Store Sales %" ||
              _metricName === "COGS %" ||
              _metricName === "Labor %" ||
              _metricName === "OPEX %" ||
              _metricName === "Occupancy %" ||
              _metricName === "4-wall EBITDA %" ||
              _metricName === "G&A %" ||
              _metricName === "EBITDA %" ||
              _metricName === "Market Cap %"
            ) {
              let __value = +pld.value;
              _value = __value.toFixed(1);
            } else if (_metricName === "Avg. # Google Reviews / Location") {
              let __value = +pld.value;
              _value = __value.toFixed(0);
            } else {
              _value = +pld.value;
            }

            let preLastYear = new Date().getFullYear() - 1;
            let dublicatedItem =
              !pld.dataKey.includes("/predicted") &&
              preLastYear === pld.payload.year &&
              pld.strokeWidth;

            if (
              !dublicatedItem ||
              findKeysWithSubstring(pld.payload, "/predicted").length === 0
            ) {
              return (
                <div
                  style={{ display: "inline", zIndex: 120 }}
                  key={_name + _metricName}
                >
                  <div className="d-flex align-items-center my-1">
                    {pld.strokeDasharray === "3 3" ? (
                      <DashCircleLegend color={pld.color} />
                    ) : (
                      <CircleLegend color={pld.color} />
                    )}{" "}
                    {_name} -
                    <div>
                      {_metricName}: {_value}
                    </div>
                  </div>
                </div>
              );
            } else {
              return <></>
              // return (
              //   <div
              //     style={{ display: "inline", padding: 10, zIndex: 120 }}
              //   ></div>
              // );
            }
            // pld.payload.year === new Date().getFullYear() ? return <div><p>Data for the current year is being collected, and these numbers will be updated as more data comes in.</p></div> : <></>;
          })}
        </div>
        {pldYear === new Date().getFullYear() ? (
          <div style={{ maxWidth: "300px" }}>
            <p>
              Data for the current year is being collected, and these numbers
              will be updated as more data comes in.
            </p>
          </div>
        ) : (
          <></>
        )}
      </StyledTooltipDiv>
    );
  }

  return null;
};

export default CustomTooltip;
