import React, { useState, useEffect, useContext } from "react";
import {
  Link,
  useNavigate,
  useParams,
  Outlet,
  useLocation,
} from "react-router-dom";
import styled from "styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  chosenMetricsAtom,
  chosenTagsAtom,
  companiesLimitAtom,
  CSMetricsAttributesAtom,
  customCompAtom,
  inProgressNewCustomCompAtom,
  inProgressNewCustomCompDialogAtom,
  inProgressNewCustomCompTypeAtom,
  linkToMenuItemAtom,
  metricLimitAtom,
  metricListAtom,
  metricSameStartAtom,
  newCustomCompNameAtom,
  newCustomCompTypeAtom,
  nextStepTitleAtom,
  searchCompanyArrayAtom,
  sectorsLimitAtom,
  selectedSectorsAtom,
  stepOfCreationCompsAtom,
  tagsOptionsAtom,
  timeframeEndDateAtom,
  timeframeSelectedAtom,
  timeframeSelectedLabelAtom,
  timeframeStartDateAtom,
} from "../../atoms/profileAtom";
import { APIHeadersRAW, kitcut } from "../../components/shared/helpers";
import axios from "axios";
import CombineChart from "../Chart/CombineChart";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import { AppSettings } from "../../config/app-settings";
import MinusIcon from "../../components/shared/MinusIcon";
import MinusIconNoBorder from "../../components/shared/MinusIconNoBorder";
import { PublicTagStyle } from "../../components/modals/SelectCompanyBySector";
import MyComps from "../../components/shared/Icons/MyComps";
import CustomCompIcon from "../../components/shared/CustomCompIcon";
import LoadingImage from "../../components/shared/LoadingImage";
import NewCompSetStep1 from "./NewCompSetStep1";
import NewCompSetStep2 from "./NewCompSetStep2";
import NewCompSetStep3 from "./NewCompSetStep3";
import SubSectorsList from "./SubSectorsList";
import InvestorDealList from "./InvestorDealList";
import InvestmentTable from "./InvestmentTable";
import StoreListTable from "./StoreListTable";
import ScatterPlot from "../Chart/ScatterPlot";
import TableChart from "../Chart/TableChart";
import ScatterPlotIcon from "../../components/shared/ScatterPlotIcon";
import TimeSeriesIcon from "../../components/shared/TimeSeriesIcon";
import ListIcon from "../../components/shared/ListIcon";
import TableIcon from "../../components/shared/TableIcon";
import CustomCompSteps from "./CustomCompSteps";
import CircleScatterPlotIcon from "../../components/shared/CircleScatterPlotIcon";
import CircleTimeSeriesIcon from "../../components/shared/CircleTimeSeriesIcon";
import CircleTableIcon from "../../components/shared/CircleTableIcon";

const CompSetContainer = styled.div`
  display: flex;
  margin-bottom: -20px;
  max-height: calc(100vh - 190px);
  justify-content: space-between;
`;

const WizardContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-right: -30px;
  margin-left: -30px;
  height: 60px;
  width: calc(100% + 60px);
  padding-left: 15px;
  margin-bottom: 32px;
`;

const Sidebar = styled.div`
  width: 450px;
  background-color: #fff;
  border-radius: 20px;
  margin-right: 16px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 12px 0px;
  min-height: 100%;
`;

const BarLineOptions = styled.div`
  display: flex;
  align-items: center;

  .form-check {
    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
`;

const SelectedCompanyRow = styled.div`
  height: 50px;
  padding: 9px 16px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 18px;
  background: rgba(129, 53, 121, 0.08);
  justify-content: space-between;
`;

const SelectedCompanyLogo = styled.div`
  display: flex;
  margin-right: 12px;
  width: 31px;
  height: 31px;
  padding: 0px 4px;
  align-items: center;
  border-radius: 25px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 5px 0.5px;
`;

const renderTooltip = (text) => (
  <Popover
    id="popover-contained"
    style={{ color: "black", background: "white" }}
  >
    {text}
  </Popover>
);

const getContainerStyle = (step) => {
  switch (step) {
    case 1:
      return { width: "100%" };
    // return { maxWidth: "1335px", width: "100%" };
    case 2:
      return { width: "100%", marginRight: 12 };
    case 4:
      return { width: "100%", padding: "0 20px 20px 20px" };
    default:
      return {
        maxWidth: "1335px",
        width: "100%",
        overflow: "auto",
        backgroundColor: "#fff",
        borderRadius: "20px",
        boxShadow: "0 0 4px -0.5px rgba(0, 0, 0, 0.14)",
        marginBottom: "16px",
        marginRight: "16px",
      };
  }
};

export const stylesSelect = {
  control: (styles) => ({
    backgroundColor: "var(--app-component-bg)",
    color: "var(--app-component-color)",
    border: "1px solid var(--app-component-border-color)",
    borderRadius: "10px",
    display: "flex",
    zIndex: "777",
  }),
  indicatorSeparator: (styles) => ({ backgroundColor: "transparent" }),
  input: (styles) => ({
    color: "var(--app-component-color)",
    fontWeight: "600",
    gridArea: "1/1/2/3",
    flex: "1 1 auto",
    display: "flex",
    margin: "2px",
    gridTemplateColumns: "0 min-content",
    boxSizing: "content-box",
    paddingTop: "2px",
    paddingBottom: "2px",
    visibility: "visible",
  }),
  singleValue: (styles) => ({
    color: "var(--app-component-color)",
    gridArea: "1/1/2/3",
    marginLeft: "2px",
    marginRight: "2px",
    width: "140px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    boxSizing: "border-box",
  }),
  placeholder: (styles) => ({
    color: "rgba(var(--app-component-color-rgb), .5)",
    fontWeight: "600",
    width: "140px",
    gridArea: "1/1/2/3",
  }),
  menu: (styles) => ({
    backgroundColor: "var(--app-component-dropdown-bg)",
    position: "relative",
    top: "100%",
    borderRadius: "10px",
    margin: "8px 0",
    zIndex: "8888",
    boxSizing: "border-box",
    width: "100%",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    backgroundColor: isFocused ? "var(--app-component-dropdown-hover-bg)" : "",
    color: "var(--app-component-color)",
    cursor: isDisabled ? "not-allowed" : "default",
    padding: "8px 12px",
  }),
};

export const InProgress = () => (
  <div className="text-center">
    <div className="spinner-border spinner-border-sm" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

const timeframeOption = [
  { value: 120, label: "All Years" },
  { value: 12, label: "Last Year" },
  { value: 24, label: "Last 2 Years" },
  { value: 36, label: "Last 3 Years" },
  { value: "Custom", label: "Custom" },
];

const NewCompSet = () => {
  const params = useParams();
  const location = useLocation();
  const { step } = params;
  const navigate = useNavigate();
  const [stepOfCreationComps, setStepOfCreationComps] = useRecoilState(
    stepOfCreationCompsAtom
  );
  console.log("stepOfCreationComps: ", stepOfCreationComps);
  const [searchCompanyArray, setSearchCompanyArray] = useRecoilState(
    searchCompanyArrayAtom
  );
  const companiesLimit = useRecoilValue(companiesLimitAtom);
  const [compName, setCompName] = useRecoilState(newCustomCompNameAtom);
  const [compType, setCompType] = useRecoilState(newCustomCompTypeAtom);
  console.log("compType: ", compType);
  const [classStep2, setClassStep2] = useState("nav-link disabled");
  const [classStep3, setClassStep3] = useState("nav-link disabled");
  const [wizardClass, setWizardClass] = useState(
    "nav-wizards-container col-12"
  );
  const [nextStepTitle, setNextStepTitle] = useRecoilState(nextStepTitleAtom);
  const [chosenMetrics, setChosenMetrics] = useRecoilState(chosenMetricsAtom);
  const metricLimit = useRecoilValue(metricLimitAtom);
  const sectorsLimit = useRecoilValue(sectorsLimitAtom);
  const metricList = useRecoilValue(metricListAtom);
  const [selectedSectors, setSelectedSectors] =
    useRecoilState(selectedSectorsAtom);
  const [metricSameStart, setMetricSameStart] =
    useRecoilState(metricSameStartAtom);
  const [CSMetricsAttributes, setCSMetricsAttributes] = useRecoilState(
    CSMetricsAttributesAtom
  );
  const [filteredMetrics, setFilteredMetrics] = useState([]);
  console.log("filteredMetrics: ", filteredMetrics);
  const [sidebarTitle, setSidebarTitle] = useState("");
  const [successComp, setSuccessComp] = useState(false);
  const [customComp, setCustomComp] = useRecoilState(customCompAtom);
  const [brandsOptionsTimeframe, setBrandsOptionsTimeframe] =
    useState(timeframeOption);
  const [timeframeSelected, setTimeframeSelected] = useRecoilState(
    timeframeSelectedAtom
  );
  const [timeframeSelectedLabel, setTimeframeSelectedLabel] = useRecoilState(
    timeframeSelectedLabelAtom
  );
  const [inProgressNewCustomComp, setInProgressNewCustomComp] = useRecoilState(
    inProgressNewCustomCompAtom
  );
  const [inProgressNewCustomCompType, setInProgressNewCustomCompType] =
    useRecoilState(inProgressNewCustomCompTypeAtom);
  const [linkToMenuItem, setLinkToMenuItem] =
    useRecoilState(linkToMenuItemAtom);
  const [showModal, setShowModal] = useRecoilState(
    inProgressNewCustomCompDialogAtom
  );
  const [timeframeStartDate, setTimeframeStartDate] = useRecoilState(
    timeframeStartDateAtom
  );
  const [timeframeEndDate, setTimeframeEndDate] =
    useRecoilState(timeframeEndDateAtom);
  const [timeframeCustomStartDate, setTimeframeCustomStartDate] = useState("");
  const [timeframeCustomEndDate, setTimeframeCustomEndDate] = useState("");
  const [isAllSameStartOption, setIsAllSameStartOption] = useState(false);
  const [validCustomTimeframe, setValidCustomTimeframe] = useState(true);
  const chosenTags = useRecoilValue(chosenTagsAtom);
  const [inProgress, setInProgress] = useState(false);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const context = useContext(AppSettings);
  const baseLogoURL = process.env.REACT_APP_LOGOS_BASE_URL_600;
  const signature = process.env.REACT_APP_LOGOS_SIGNATURE;

  const IconStar = () => (
    <i className="fa-solid fa-folder-plus fs-16px mt-1" title="media"></i>
  );

  useEffect(() => {
    console.log("-----inside useEffect-----");
    console.log("location: ", location);
    console.log("compType: ", compType);
    console.log("step: ", step);
    if (!compType && (!step || (step && +step !== 1))) {
      navigate("/new-comp-set/1");
      return;
    }
    if (!step) navigate("/new-comp-set/1");
    else setStepOfCreationComps(Number(step));
    console.log("----end useEffect-----");
  }, [compType, navigate, setStepOfCreationComps, step, location]);

  useEffect(() => {
    if (stepOfCreationComps === 2) {
      setInProgressNewCustomComp(true);
    }
  }, [setInProgressNewCustomComp, stepOfCreationComps]);

  useEffect(() => {
    context.handleSetAppHeaderNone(false);
    context.handleSetAppSidebarNone(false);
    context.handleSetAppContentClass("");
    context.handleSetAppTitle("New Custom Comp");
    context.handleSetAppIcon(<IconStar />);
    setInProgressNewCustomCompType("Custom Comp");
    setCompType("");
    setSearchCompanyArray([]);
    setChosenMetrics([]);
    setCSMetricsAttributes([]);
    setSelectedSectors([]);
    setTimeframeSelectedLabel("");

    if (!compName) {
      const currentDate = new Date();
      const formattedDate = moment(currentDate).format("M/D/YYYY");
      const firstName = localStorage.getItem("first_name");
      const lastName = localStorage.getItem("last_name");
      const newName = `${firstName[0]}${lastName} - ${formattedDate}`;
      setCompName(newName);
    }
  }, []);

  const getTSType = (id) => {
    const metric = CSMetricsAttributes.find((el) => el.metric_id === id);
    return metric ? metric.ts_chart_type : null;
  };

  const handleRemoveCompany = (e) => {
    const _filtered = searchCompanyArray.filter((item) => item.id !== e);
    setSearchCompanyArray(_filtered);
  };

  const handleRemoveSector = (e) => {
    const _filtered = selectedSectors.filter((item) => item.id !== e);
    setSelectedSectors(_filtered);
  };

  const handleRemoveMetric = (e) => {
    const _filtered = chosenMetrics.filter((item) => +item !== e);
    const _filteredAtributeMetrics = CSMetricsAttributes.filter(
      (item) => item.metric_id !== e
    );
    setChosenMetrics(_filtered);
    setCSMetricsAttributes(_filteredAtributeMetrics);
  };

  const handlerRadioChange = (e) => {
    const _newMetric = CSMetricsAttributes.map((el) => {
      if (+el.metric_id === +e.target.id) {
        return { ...el, ts_chart_type: e.target.value };
      }
      return el;
    });
    setCSMetricsAttributes(_newMetric);
  };

  const handlerSameStartChange = (e) => {
    setMetricSameStart(e.target.value === "0");
  };

  const handleDateSelectStartDate = (e) => {
    setTimeframeCustomStartDate(e);
  };

  const handleDateSelectEndDate = (e) => {
    setTimeframeCustomEndDate(e);
  };

  useEffect(() => {
    if (timeframeCustomEndDate) {
      setTimeframeEndDate(moment(timeframeCustomEndDate).format("MM/DD/Y"));
    }
  }, [timeframeCustomEndDate]);

  useEffect(() => {
    if (timeframeCustomStartDate) {
      setTimeframeStartDate(moment(timeframeCustomStartDate).format("MM/DD/Y"));
    }
  }, [timeframeCustomStartDate]);

  useEffect(() => {
    if (
      timeframeSelected === "Custom" &&
      (timeframeCustomStartDate === "" || timeframeCustomEndDate === "")
    ) {
      setValidCustomTimeframe(false);
    } else {
      setValidCustomTimeframe(true);
    }
  }, [timeframeCustomStartDate, timeframeCustomEndDate, timeframeSelected]);

  const handleSaveCustomComps = (e, action) => {
    e.preventDefault();
    let _existingTags = [];
    let _newTags = [];
    console.log("chosenTags: ", chosenTags);
    chosenTags
      .filter((t) => !!t)
      .forEach((tag) => {
        if (tag.id !== undefined) {
          _existingTags.push({ tag_id: tag.id });
        } else {
          _newTags.push({ label: tag.label });
        }
      });

    setInProgress(true);
    let _url = `${baseURL}/custom_comps/new`;
    if (action === "save") {
      _url = `${baseURL}/custom_comps`;
    }

    let _compType;
    switch (compType) {
      case "Table":
        _compType = "CustomComps::Table";
        break;
      case "Time Series":
        _compType = "CustomComps::TimeSeries";
        break;
      case "Scatter Plot":
        _compType = "CustomComps::ScatterPlot";
        break;
      case "Company Fundraising History":
        _compType = "CustomComps::CompanyList";
        break;
      case "Sector Investment List":
        _compType = "CustomComps::SectorList";
        break;
      case "Store List":
        _compType = "CustomComps::StoreList";
        break;
      case "Firm Retail Investments":
        _compType = "CustomComps::InvestorList";
        break;
      default:
        _compType = "";
    }

    const metricData = {
      custom_comp: {
        name: compName,
        type: _compType,
        start_date: !metricSameStart ? timeframeStartDate : "",
        end_date: !metricSameStart ? timeframeEndDate : "",
        taggings_attributes: _existingTags,
        tags: _newTags,
        resource_ids:
          compType !== "Sector Investment List"
            ? searchCompanyArray.map((el) => el.id)
            : selectedSectors.map((el) => el.id),
        custom_comp_metrics_attributes:
          compType !== "Sector Investment List"
            ? CSMetricsAttributes.map((el) => ({
                metric_id: el.metric_id,
                ts_chart_type: el.ts_chart_type,
                same_start: metricSameStart,
              }))
            : [],
      },
    };

    axios
      .post(_url, metricData, { headers: APIHeadersRAW, withCredentials: true })
      .then((resp) => {
        if (resp.data) {
          setCustomComp(resp.data.data);
          setSuccessComp(true);
          navigate(`/new-comp-set/4`);
        }
        if (action === "save") {
          navigate("/custom-comps", { replace: false });
          setInProgressNewCustomComp(false);
        }
        setInProgress(false);
      })
      .catch((error) => {
        setInProgress(false);
      });
  };

  const handleChangeTimeframeFilter = (e) => {
    const _endDate = moment(new Date()).format("MM/DD/Y");
    const _startDate = moment().add(-e.value, "month").format("MM/DD/Y");
    setTimeframeSelected(e.value);
    setTimeframeSelectedLabel(e);
    setTimeframeStartDate(_startDate);
    setTimeframeEndDate(_endDate);
  };

  useEffect(() => {
    if (
      compType === "Sector Investment List" ||
      compType === "Firm Retail Investments" ||
      compType === "Store List" ||
      compType === "Company Fundraising History"
    ) {
      setWizardClass("nav-wizards-container col-9");
    }
    if (
      (compType === "Table" ||
        compType === "Time Series" ||
        compType === "Scatter Plot") &&
      stepOfCreationComps < 3
    ) {
      setWizardClass("nav-wizards-container col-9");
    }
    if (
      (compType === "Table" ||
        compType === "Time Series" ||
        compType === "Scatter Plot") &&
      stepOfCreationComps > 3
    ) {
      setWizardClass("nav-wizards-container col-11");
    }
    if (
      (compType === "Table" ||
        compType === "Time Series" ||
        compType === "Scatter Plot") &&
      stepOfCreationComps === 2
    ) {
      setSidebarTitle("Companies");
    }
    if (
      compType === "Company Fundraising History" &&
      stepOfCreationComps === 2
    ) {
      setSidebarTitle("Company");
    }
    if (
      (compType === "Table" ||
        compType === "Time Series" ||
        compType === "Scatter Plot") &&
      stepOfCreationComps === 3
    ) {
      setSidebarTitle("Metrics");
    }
    if (compType === "Sector Investment List" && stepOfCreationComps === 2) {
      setSidebarTitle("Sectors");
    }
    if (compType === "Firm Retail Investments" && stepOfCreationComps === 2) {
      setSidebarTitle("Firm");
    }
    if (compType === "Store List" && stepOfCreationComps === 2) {
      setSidebarTitle("Store");
    }
    if (
      stepOfCreationComps === 3 &&
      (compType === "Table" ||
        compType === "Time Series" ||
        compType === "Scatter Plot")
    ) {
      setNextStepTitle("Generate Chart");
    }
    if (
      stepOfCreationComps === 2 &&
      (compType === "Table" ||
        compType === "Time Series" ||
        compType === "Scatter Plot")
    ) {
      setNextStepTitle("Next");
    }
  }, [compType, stepOfCreationComps]);

  useEffect(() => {
    console.log("stepOfCreationComps inside useEffect: ", stepOfCreationComps);
    if (stepOfCreationComps > 2) {
      setClassStep2("nav-link completed");
    } else if (stepOfCreationComps === 2) {
      setClassStep2("nav-link active");
    } else if (stepOfCreationComps === 1) {
      setClassStep2("nav-link disabled");
    }
    if (stepOfCreationComps < 3) {
      setClassStep3("nav-link disabled");
    } else if (stepOfCreationComps === 4) {
      setClassStep3("nav-link completed");
    } else {
      setClassStep3("nav-link active");
    }
  }, [stepOfCreationComps]);

  console.log("stepOfCreationComps: ", stepOfCreationComps);
  useEffect(() => {
    console.log("inside useEffect chosenMetrics: ", chosenMetrics);
    console.log("inside useEffect metricList: ", metricList);
    if (metricList && chosenMetrics) {
      const metrics = chosenMetrics.map((metric) =>
        metricList.find((el) => el.id === metric)
      );
      console.log("metrics: ", metrics);
      setFilteredMetrics(metrics);
      // const _temp = metricList.filter((metric) =>
      //   chosenMetrics.includes(metric.id)
      // );
      // setFilteredMetrics(_temp);

      const _isAllSameStartOption = chosenMetrics.map(
        (choose) =>
          metricList.find((metric) => +metric.id === +choose)?.same_start
      );
      setIsAllSameStartOption(_isAllSameStartOption.every((v) => v === true));
    }
  }, [metricList, chosenMetrics]);

  useEffect(() => {
    if (!isAllSameStartOption) {
      setMetricSameStart(false);
    }
  }, [isAllSameStartOption]);

  const steps = [
    "Name & Type",
    "Pick Companies/ Firms",
    "Choose Metrics",
    "Review & Save",
    "Thank You",
  ];

  return (
    <>
      <WizardContainer>
        <div className={wizardClass}>
          <CustomCompSteps steps={steps} activeStep={stepOfCreationComps - 1} />
          {/* <nav className="nav nav-wizards-2 mb-20px">
            <div className="nav-item col" style={{ maxWidth: "100px" }}>
              <Link
                to="#"
                className={"nav-link completed"}
                onClick={(e) => {
                  e.preventDefault();
                  setShowModal(true);
                  setLinkToMenuItem("/custom-comps/");
                }}
              >
                <div className="nav-text">
                  <MyComps height="24" />
                </div>
              </Link>
            </div>
            <div className="nav-item col">
              <Link
                to="/new-comp-set/1"
                className={
                  stepOfCreationComps === 1
                    ? "nav-link active"
                    : "nav-link completed"
                }
                // onClick={() => setStepOfCreationComps(1)}
                // onClick={() => navigate("/new-comp-set/1")}
              >
                <div className="nav-text">1</div>
                Name & Type
              </Link>
            </div>
            {compType === "" && (
              <>
                <div className="nav-item col">
                  <Link to="#" className={"nav-link disabled"}>
                    <div className="nav-text">2</div>
                    Pick Companies/Firms
                  </Link>
                </div>
                <div className="nav-item col">
                  <Link to="#" className={"nav-link disabled"}>
                    <div className="nav-text">3</div>
                    Choose Metrics
                  </Link>
                </div>
                <div className="nav-item col">
                  <Link to="#" className={"nav-link disabled"}>
                    <div className="nav-text">4</div>
                    Review & Save
                  </Link>
                </div>
              </>
            )}
            {compType && (
              <>
                {compType === "Sector Investment List" && (
                  <div className="nav-item col">
                    <Link
                      to="#"
                      onClick={() => navigate("/new-comp-set/2")}
                      className={
                        stepOfCreationComps < 2
                          ? "nav-link disabled"
                          : stepOfCreationComps === 2
                          ? "nav-link active"
                          : "nav-link completed"
                      }
                    >
                      <div className="nav-text">2. Choose Sector(s)</div>
                    </Link>
                  </div>
                )}
                {compType === "Company Fundraising History" && (
                  <div className="nav-item col">
                    <Link
                      to="#"
                      onClick={() => navigate("/new-comp-set/2")}
                      className={
                        stepOfCreationComps < 2
                          ? "nav-link disabled"
                          : stepOfCreationComps === 2
                          ? "nav-link active"
                          : "nav-link completed"
                      }
                    >
                      <div className="nav-text">2. Choose a Company</div>
                    </Link>
                  </div>
                )}
                {compType === "Firm Retail Investments" && (
                  <div className="nav-item col">
                    <Link
                      to="#"
                      onClick={() => navigate("/new-comp-set/2")}
                      className={
                        stepOfCreationComps < 2
                          ? "nav-link disabled"
                          : stepOfCreationComps === 2
                          ? "nav-link active"
                          : "nav-link completed"
                      }
                    >
                      <div className="nav-text">2. Choose Firm(s)</div>
                    </Link>
                  </div>
                )}
                {compType === "Store List" && (
                  <div className="nav-item col">
                    <Link
                      to="#"
                      onClick={() => navigate("/new-comp-set/2")}
                      className={
                        stepOfCreationComps < 2
                          ? "nav-link disabled"
                          : stepOfCreationComps === 2
                          ? "nav-link active"
                          : "nav-link completed"
                      }
                    >
                      <div className="nav-text">2. Choose a Store</div>
                    </Link>
                  </div>
                )}
                {(compType === "Store List" ||
                  compType === "Firm Retail Investments" ||
                  compType === "Sector Investment List" ||
                  compType === "Company Fundraising History") && (
                  <div className="nav-item col">
                    <Link
                      to="#"
                      className={
                        stepOfCreationComps >= 3
                          ? "nav-link active"
                          : "nav-link disabled"
                      }
                    >
                      <div className="nav-text">3. Review & Save</div>
                    </Link>
                  </div>
                )}
                {(compType === "Table" ||
                  compType === "Time Series" ||
                  compType === "Scatter Plot") && (
                  <>
                    <div className="nav-item col">
                      <Link
                        to="#"
                        className={classStep2}
                        onClick={() => navigate("/new-comp-set/2")}
                      >
                        <div className="nav-text">2. Pick Companies</div>
                      </Link>
                    </div>
                    <div className="nav-item col">
                      <Link
                        to="#"
                        onClick={() => navigate("/new-comp-set/3")}
                        className={classStep3}
                      >
                        <div className="nav-text">3. Choose Metrics</div>
                      </Link>
                    </div>
                    <div className="nav-item col">
                      <Link
                        to="#"
                        className={
                          stepOfCreationComps >= 4
                            ? "nav-link active"
                            : "nav-link disabled"
                        }
                      >
                        <div className="nav-text">4. Review & Save</div>
                      </Link>
                    </div>
                  </>
                )}
              </>
            )}
          </nav> */}
        </div>
      </WizardContainer>
      <CompSetContainer>
        <div style={getContainerStyle(stepOfCreationComps)}>
          {stepOfCreationComps === 1 && <Outlet />}
          {stepOfCreationComps === 2 &&
            (compType === "Table" ||
              compType === "Time Series" ||
              compType === "Scatter Plot" ||
              compType === "Company Fundraising History") && <Outlet />}
          {stepOfCreationComps === 3 &&
            (compType === "Table" ||
              compType === "Time Series" ||
              compType === "Scatter Plot") && <Outlet />}
          {stepOfCreationComps === 2 &&
            compType === "Firm Retail Investments" && <InvestorDealList />}
          {stepOfCreationComps === 4 &&
            (compType === "Company Fundraising History" ||
              compType === "Sector Investment List" ||
              compType === "Firm Retail Investments") && (
              <InvestmentTable preview={true} />
            )}
          {stepOfCreationComps === 2 && compType === "Store List" && <Outlet />}
          {stepOfCreationComps === 2 &&
            compType === "Sector Investment List" && <SubSectorsList />}
          {stepOfCreationComps === 4 && compType === "Time Series" && (
            <Outlet />
          )}
          {stepOfCreationComps === 4 && compType === "Scatter Plot" && (
            <ScatterPlot preview={true} />
          )}
          {stepOfCreationComps === 4 && compType === "Table" && (
            <TableChart preview={true} />
          )}
          {stepOfCreationComps === 4 && compType === "Store List" && (
            <StoreListTable preview={true} />
          )}
          {/* <Outlet /> */}
        </div>
        {stepOfCreationComps > 1 && stepOfCreationComps < 4 && (
          <Sidebar
            className={
              stepOfCreationComps < 3 && compType === "Sector Investment List"
                ? "right-sidebar col-3 px-3 py-4 sectors"
                : "right-sidebar col-3 px-3 py-4"
            }
          >
            <div className="d-flex flex-column justify-content-between h-100">
              <div style={{ overflowX: "hidden", height: "100%" }}>
                <div className="color-neutral-black d-flex align-items-center my-2">
                  {compType === "Scatter Plot" && (
                    <CircleScatterPlotIcon size="60" />
                  )}
                  {compType === "Time Series" && (
                    <CircleTimeSeriesIcon size="60" />
                  )}
                  {(compType === "Sector Investment List" ||
                    compType === "Company Fundraising History" ||
                    compType === "Firm Retail Investments" ||
                    compType === "Store List") && <ListIcon size="25" />}
                  {compType === "Table" && <CircleTableIcon size="60" />}
                  <div className="mx-2 d-flex flex-column">
                    <span className="fs-18px">{compType}</span>
                    <h4 className="fw-400">{compName}</h4>
                  </div>
                </div>

                {stepOfCreationComps === 2 &&
                  compType === "Firm Retail Investments" && (
                    <>
                      <p className="py-2 fs-14px">
                        Search or browse to add{" "}
                        <strong>
                          {companiesLimit > 1
                            ? `up to ${companiesLimit} companies`
                            : `a company`}
                        </strong>{" "}
                        to your list
                      </p>
                      {searchCompanyArray &&
                        searchCompanyArray.map((el) => (
                          <div
                            className="lh-18 d-flex align-items-center mb-1 fs-14px"
                            key={el.name}
                          >
                            <span
                              className="cursor-pointer"
                              onClick={() => handleRemoveCompany(el.id)}
                            >
                              <MinusIcon />
                            </span>
                            <span className="mx-2">
                              {el.name}
                              {el.isPublic && (
                                <PublicTagStyle>public</PublicTagStyle>
                              )}
                            </span>
                          </div>
                        ))}
                      {searchCompanyArray.length ? (
                        <div className="py-3">
                          <Link
                            to="#"
                            className="btn btn-primary px-2 fs-16px w-100px"
                            // className="btn btn-outline-green2 color-green2 px-2 fs-16px w-100px"
                            onClick={() => setSearchCompanyArray([])}
                          >
                            Clear All
                          </Link>
                        </div>
                      ) : null}
                    </>
                  )}

                {stepOfCreationComps < 3 &&
                  compType !== "Sector Investment List" &&
                  compType !== "Firm Retail Investments" && (
                    <>
                      <p className="py-2 fs-14px">
                        Search or browse to add{" "}
                        <strong>
                          {companiesLimit > 1
                            ? `up to ${companiesLimit} companies`
                            : `a company`}
                        </strong>{" "}
                        to your list
                      </p>
                      {searchCompanyArray &&
                        searchCompanyArray.map((el) => (
                          <SelectedCompanyRow
                            className="lh-18 d-flex align-items-center mb-3 fs-14px"
                            key={el.name}
                          >
                            <span className="d-flex align-items-center">
                              <SelectedCompanyLogo className="">
                                {el.logo && (
                                  <LoadingImage
                                    className="w-30px h-30px"
                                    variant="small"
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "30px",
                                    }}
                                    src={`${baseLogoURL}${el.logo}${signature}`}
                                    alt={el.name}
                                  />
                                )}
                              </SelectedCompanyLogo>{" "}
                              {el.name}
                              {el.isPublic && (
                                <PublicTagStyle>public</PublicTagStyle>
                              )}
                            </span>
                            <span
                              className="cursor-pointer"
                              onClick={() => handleRemoveCompany(el.id)}
                            >
                              <i className="fa-solid fa-xmark cursor-pointer"></i>
                            </span>
                          </SelectedCompanyRow>
                        ))}
                      {searchCompanyArray.length && companiesLimit > 1 ? (
                        <div className="py-3">
                          <Link
                            to="#"
                            className="btn btn-primary px-2 fs-16px w-100px"
                            onClick={() => setSearchCompanyArray([])}
                          >
                            Clear All
                          </Link>
                        </div>
                      ) : null}
                    </>
                  )}

                {stepOfCreationComps < 3 &&
                  compType === "Sector Investment List" && (
                    <>
                      <p className="py-2 fs-14px">
                        Search or browse to add{" "}
                        <strong>
                          {sectorsLimit > 1
                            ? `up to ${sectorsLimit} sectors`
                            : `a sector`}
                        </strong>{" "}
                        to your list
                      </p>
                      {selectedSectors &&
                        selectedSectors.map((el) => (
                          <div
                            className="lh-18 d-flex align-items-center mb-1 fs-14px"
                            key={el.name}
                          >
                            <span
                              className="cursor-pointer"
                              onClick={() => handleRemoveSector(el.id)}
                            >
                              <MinusIcon />
                            </span>
                            <span className="mx-2">{el.name}</span>
                          </div>
                        ))}
                      {selectedSectors.length && sectorsLimit > 1 ? (
                        <div className="py-3">
                          <Link
                            to="#"
                            className="btn btn-outline-green2 color-green2 px-2 fs-16px w-100px"
                            onClick={() => setSelectedSectors([])}
                          >
                            Clear All
                          </Link>
                        </div>
                      ) : null}
                    </>
                  )}

                {stepOfCreationComps > 2 && (
                  <>
                    <p className="py-2 fs-14px">
                      Add <strong>up to {metricLimit} metrics</strong> to your
                      list{" "}
                    </p>
                    {filteredMetrics &&
                      filteredMetrics.length > 0 &&
                      compType === "Time Series" && (
                        <div className="d-flex justify-content-end mb-2">
                          <div className="mx-1">
                            <span className="m-3">Line</span>
                            <span className="m-1">Bar</span>
                          </div>
                        </div>
                      )}

                    <div className="h-150px fs-14px">
                      {filteredMetrics &&
                        filteredMetrics.map((metric) => (
                          <div
                            className="lh-18 d-flex mb-2 justify-content-between"
                            key={metric.name}
                          >
                            <div className="d-flex align-items-center">
                              <span
                                className="cursor-pointer"
                                onClick={() => handleRemoveMetric(metric.id)}
                              >
                                <MinusIcon />
                              </span>
                              <span className="mx-2">{`${metric.section}: ${metric.name}`}</span>
                            </div>
                            {compType === "Time Series" && (
                              <BarLineOptions>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    disabled={metric.ts_chart_type !== "both"}
                                    checked={getTSType(metric.id) === "line"}
                                    onChange={handlerRadioChange}
                                    value="line"
                                    id={`${metric.id}`}
                                    name={`radio-${metric.id}`}
                                  />
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    disabled={metric.ts_chart_type !== "both"}
                                    checked={getTSType(metric.id) === "bar"}
                                    onChange={handlerRadioChange}
                                    value="bar"
                                    id={`${metric.id}`}
                                    name={`radio-${metric.id}`}
                                  />
                                </div>
                              </BarLineOptions>
                            )}
                          </div>
                        ))}
                      {filteredMetrics.length ? (
                        <div className="py-3">
                          <Link
                            to="#"
                            className="btn btn-primary px-2 fs-16px w-100px"
                            onClick={() => {
                              setFilteredMetrics([]);
                              setChosenMetrics([]);
                              setCSMetricsAttributes([]);
                            }}
                          >
                            Clear All
                          </Link>
                        </div>
                      ) : null}
                    </div>
                  </>
                )}

                {stepOfCreationComps === 3 &&
                  compType === "Time Series" &&
                  chosenMetrics.length > 0 && (
                    <>
                      <div className="row h-200px fs-14px">
                        <div className="col-5">
                          <h5 className="mx-4px">Line type</h5>
                          <div className="form-check my-2 mx-4px">
                            <input
                              className="form-check-input"
                              type="radio"
                              value="1"
                              checked={!metricSameStart}
                              id="same_start_false"
                              onChange={handlerSameStartChange}
                              name="same_start_false"
                            />
                            <label htmlFor="same_start_false">
                              Chronological{" "}
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 400, hide: 400 }}
                                overlay={renderTooltip(
                                  "Look at the metrics progression starting in whatever calendar year you'd like to start from, e.g. 2015, 2018, 2021, etc."
                                )}
                              >
                                <i className="mx-1 fa fa-circle-info fs-11px"></i>
                              </OverlayTrigger>
                            </label>
                          </div>
                          <div className="form-check my-2 mx-4px">
                            <input
                              className={
                                !isAllSameStartOption
                                  ? "form-check-input muted"
                                  : "form-check-input"
                              }
                              type="radio"
                              value="0"
                              checked={metricSameStart}
                              disabled={!isAllSameStartOption}
                              id="same_start_true"
                              onChange={handlerSameStartChange}
                              name="same_start_true"
                            />
                            <label
                              htmlFor="same_start_true"
                              className={
                                !isAllSameStartOption
                                  ? "color-secondary opacity-50"
                                  : ""
                              }
                            >
                              Same Start{" "}
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 400, hide: 400 }}
                                overlay={renderTooltip(
                                  "Look at the metrics progression starting from each brand's first year in business, e.g. if Brand A opened its first location in 2017 and Brand B opened its first location in 2021, this sets both clocks to zero so you can see how the metrics evolved  at the same point in their lifespans, or X-Axis increments are Y1 (first year in business), Y2 (second year in business), etc."
                                )}
                              >
                                <i className="mx-1 fa fa-circle-info fs-11px"></i>
                              </OverlayTrigger>
                            </label>
                          </div>
                        </div>
                        <div className="col-7">
                          <h5
                            className={
                              metricSameStart
                                ? "color-secondary opacity-50"
                                : ""
                            }
                          >
                            Timeframe
                          </h5>
                          <Select
                            className={metricSameStart ? "opacity-50" : ""}
                            isSearchable={false}
                            isDisabled={metricSameStart}
                            options={brandsOptionsTimeframe}
                            value={timeframeSelectedLabel}
                            onChange={handleChangeTimeframeFilter}
                            styles={stylesSelect}
                          />
                          {timeframeSelected === "Custom" &&
                            !metricSameStart && (
                              <>
                                <DatePicker
                                  showMonthYearPicker
                                  className="form-control h-40px my-1"
                                  selected={
                                    timeframeCustomStartDate
                                      ? new Date(timeframeCustomStartDate)
                                      : ""
                                  }
                                  placeholderText="Start Date"
                                  format="MM/DD/Y"
                                  onChange={handleDateSelectStartDate}
                                />
                                <DatePicker
                                  showMonthYearPicker
                                  className="form-control h-40px my-1"
                                  selected={
                                    timeframeCustomEndDate
                                      ? new Date(timeframeCustomEndDate)
                                      : ""
                                  }
                                  placeholderText="End Date"
                                  format="MM/DD/Y"
                                  onChange={handleDateSelectEndDate}
                                />
                              </>
                            )}
                        </div>
                      </div>
                    </>
                  )}
              </div>
              <div>
                <div className="d-flex justify-content-end m-2">
                  <Link
                    to={`/new-comp-set/${stepOfCreationComps - 1}`}
                    className="fs-16px btn w-100px btn-outline-primary text-uppercase"
                  >
                    Back
                  </Link>
                  <div className="w-20px"></div>

                  {stepOfCreationComps !== 3 &&
                  (compType === "Table" ||
                    compType === "Time Series" ||
                    compType === "Scatter Plot") ? (
                    <Link
                      to={`/new-comp-set/${stepOfCreationComps + 1}`}
                      className={
                        searchCompanyArray.length && !inProgress
                          ? "fs-16px btn w-100px text-light btn-primary text-uppercase"
                          : "fs-16px btn w-100px text-light btn-primary text-uppercase disabled"
                      }
                      // onClick={() => {
                      //   debugger;
                      //   const step = stepOfCreationComps + 1;
                      //   console.log("step to: ", step);
                      //   // navigate(`/new-comp-set/${step}`);
                      //   // navigate(`/new-comp-set/${stepOfCreationComps + 1}`);
                      // }}
                    >
                      {inProgress ? <InProgress /> : nextStepTitle}
                    </Link>
                  ) : null}

                  {(stepOfCreationComps === 3 &&
                    (compType === "Table" ||
                      compType === "Time Series" ||
                      compType === "Scatter Plot")) ||
                  (stepOfCreationComps === 2 &&
                    (compType === "Company Fundraising History" ||
                      compType === "Sector Investment List" ||
                      compType === "Firm Retail Investments" ||
                      compType === "Store List")) ? (
                    <Link
                      to="#"
                      className={
                        (CSMetricsAttributes.length > 0 &&
                          !inProgress &&
                          validCustomTimeframe) ||
                        (searchCompanyArray.length &&
                          (compType === "Company Fundraising History" ||
                            compType === "Firm Retail Investments" ||
                            compType === "Store List")) ||
                        (selectedSectors.length &&
                          compType === "Sector Investment List")
                          ? "fs-16px btn btn-primary text-light text-uppercase"
                          : "fs-16px btn btn-primary text-light text-uppercase disabled"
                      }
                      onClick={(e) => handleSaveCustomComps(e, "preview")}
                    >
                      {inProgress ? <InProgress /> : nextStepTitle}
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
          </Sidebar>
        )}
      </CompSetContainer>
    </>
  );
};

export default NewCompSet;
