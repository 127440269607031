import React from "react";

const CircleScatterPlotIcon = ({ color, size }) => {
  let _size = 108;
  let fillColor = "#B668AE";
  if (color) {
    fillColor = color;
  }
  if (size) _size = size;

  return (
    <svg
      width={_size}
      height={_size}
      viewBox="0 0 109 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="107.165"
        height="106"
        rx="53"
        fill="white"
        stroke="#D8D2FB"
        stroke-opacity="0.4"
      />
      <path
        d="M58.8647 43.8489C60.6454 43.8489 62.089 42.4053 62.089 40.6246C62.089 38.8439 60.6454 37.4004 58.8647 37.4004C57.084 37.4004 55.6405 38.8439 55.6405 40.6246C55.6405 42.4053 57.084 43.8489 58.8647 43.8489Z"
        fill="#813579"
      />
      <path
        d="M68.516 34.7512C70.2967 34.7512 71.7402 33.3077 71.7402 31.527C71.7402 29.7463 70.2967 28.3027 68.516 28.3027C66.7353 28.3027 65.2917 29.7463 65.2917 31.527C65.2917 33.3077 66.7353 34.7512 68.516 34.7512Z"
        fill="#813579"
      />
      <path
        d="M37.323 61.6555C39.1037 61.6555 40.5472 60.212 40.5472 58.4313C40.5472 56.6506 39.1037 55.207 37.323 55.207C35.5423 55.207 34.0988 56.6506 34.0988 58.4313C34.0988 60.212 35.5423 61.6555 37.323 61.6555Z"
        fill="#813579"
      />
      <path
        d="M72.5438 68.929C74.3245 68.929 75.7681 67.4854 75.7681 65.7047C75.7681 63.924 74.3245 62.4805 72.5438 62.4805C70.7631 62.4805 69.3196 63.924 69.3196 65.7047C69.3196 67.4854 70.7631 68.929 72.5438 68.929Z"
        fill="#813579"
      />
      <path
        d="M79.1304 43.8274C80.9111 43.8274 82.3546 42.3838 82.3546 40.6031C82.3546 38.8224 80.9111 37.3789 79.1304 37.3789C77.3497 37.3789 75.9061 38.8224 75.9061 40.6031C75.9061 42.3838 77.3497 43.8274 79.1304 43.8274Z"
        fill="#813579"
      />
      <path
        d="M70.9247 53.4622C72.7054 53.4622 74.1489 52.0186 74.1489 50.2379C74.1489 48.4572 72.7054 47.0137 70.9247 47.0137C69.144 47.0137 67.7004 48.4572 67.7004 50.2379C67.7004 52.0186 69.144 53.4622 70.9247 53.4622Z"
        fill="#813579"
      />
      <path
        d="M54.3046 60.9485C56.0852 60.9485 57.5288 59.5049 57.5288 57.7242C57.5288 55.9435 56.0852 54.5 54.3046 54.5C52.5239 54.5 51.0803 55.9435 51.0803 57.7242C51.0803 59.5049 52.5239 60.9485 54.3046 60.9485Z"
        fill="#813579"
      />
      <path
        d="M44.0441 47.2688C45.8248 47.2688 47.2683 45.8253 47.2683 44.0446C47.2683 42.2639 45.8248 40.8203 44.0441 40.8203C42.2634 40.8203 40.8198 42.2639 40.8198 44.0446C40.8198 45.8253 42.2634 47.2688 44.0441 47.2688Z"
        fill="#813579"
      />
      <path
        d="M59.9384 70.8635C61.7191 70.8635 63.1626 69.42 63.1626 67.6393C63.1626 65.8586 61.7191 64.415 59.9384 64.415C58.1577 64.415 56.7141 65.8586 56.7141 67.6393C56.7141 69.42 58.1577 70.8635 59.9384 70.8635Z"
        fill="#813579"
      />
      <path
        d="M47.2081 74.3694C48.9888 74.3694 50.4324 72.9258 50.4324 71.1451C50.4324 69.3644 48.9888 67.9209 47.2081 67.9209C45.4274 67.9209 43.9839 69.3644 43.9839 71.1451C43.9839 72.9258 45.4274 74.3694 47.2081 74.3694Z"
        fill="#813579"
      />
      <path
        d="M28.303 26V53.6364V81.2727H83.5757"
        stroke="#813579"
        stroke-width="2"
      />
    </svg>
  );
};

export default CircleScatterPlotIcon;
