import React from "react";
import styled from "styled-components";
import { colorPatternDefault } from "../shared/Colors";

export const DashCircleLegend = styled.div`
  width: 14px;
  height: 14px;
  display: inline-block;
  border-radius: 50%;
  background-color: transparent;
  border: 1px dashed ${(props) => props.color};
  margin-right: 5px;
`;

export const CircleLegend = styled.div`
  width: 14px;
  height: 14px;
  display: inline-block;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 5px;
  border: 1px solid #777;
`;

const LegendStyle = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #666262;
  border-top: 1px solid #666262;
  min-height: 40px;
  margin-bottom: 30px;
  padding: 10px 60px;
  display: flex;
  flex-wrap: wrap;

  .item {
    display: flex;
    padding: 4px 0;
    align-items: center;
  }
`;

const LegendComponent = ({ data }) => {
  return (
    <LegendStyle>
      {data &&
        data.length > 0 &&
        data.map((company, i) => {
          return (
            <div className="col-md-4 col-lg-3" key={"legend-" + company.id + i}>
              <div className="item">
                <CircleLegend color={company.color || colorPatternDefault[i]} />
                {company.name}
              </div>
            </div>
          );
        })}
    </LegendStyle>
  );
};

export default LegendComponent;
