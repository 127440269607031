import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  companiesLimitAtom,
  companiesListInvestorAtom,
  newModalTitleAtom,
  roleAtom,
  searchCompanyArrayAtom,
  sectorsInvestListAtom,
  sectorsLimitAtom,
  selectedInvestSectorAtom,
  showNewCompanyModalAtom,
  showSelectCompaniesBySectorAtom,
} from "../../atoms/profileAtom";
import { APIHeaders } from "../../components/shared/helpers";
import axios from "axios";
import { Link } from "react-router-dom";
import SelectCompanyByInvestor from "../../components/modals/SelectCompanyByInvestor";
import { ReactNotifications } from "react-notifications-component";
import { PublicTagStyle } from "../../components/modals/SelectCompanyBySector";
import MoneyIcon from "../../components/shared/Icons/MoneyIcon";
import styled from "styled-components";

const SubSectorListWrapper = styled.div`
  width: 100%;
  max-height: calc(100vh - 220px);
  padding-bottom: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  // overflow: hidden;

  .search-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  .form-control {
    padding-left: 30px;
  }
`;

const SubSectorListInner = styled.div`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  //height: calc(100vh - 300px);
  margin-top: 12px;
  position: absolute;
  background: white;
  border: 1px solid var(--app-component-border-color);
  top: 30px;
  border-radius: 4px;
  z-index: 22;
`;

const SubSectorsListStyle = styled.div`
  width: 100%;
  padding-top: 5px;
  columns: ${(props) =>
    props.count > 30 ? Math.round(props.count / 40) : "1"};
  padding-left: 5px;
  font-size: 14px;

  @media (max-width: 1500px) {
    columns: ${(props) => (props.count > 30 ? "2" : "1")};
    padding-bottom: 26px;
  }
`;

const InvestorDealList = () => {
  const [companiesListInvestor, setCompaniesListInvestorAtom] = useRecoilState(
    companiesListInvestorAtom
  );
  const [loadedInvestList, setLoadedInvestList] = useState(false);
  const [loadedInvestSectors, setLoadedInvestSectors] = useState(false);
  const [sectorsInvestList, setSectorsInvestList] = useRecoilState(
    sectorsInvestListAtom
  );
  const [searchCompany, setSearchCompany] = useState("");
  const [searchCompanyArray, setSearchCompanyArray] = useRecoilState(
    searchCompanyArrayAtom
  );
  const [selectedSector, setSelectedSector] = useRecoilState(
    selectedInvestSectorAtom
  );
  const [showNewCompanyModal, setShowNewCompanyModal] = useRecoilState(
    showNewCompanyModalAtom
  );
  const [newModalTitle, setNewModalTitle] = useRecoilState(newModalTitleAtom);
  const [found, setFound] = useState([]);
  const companiesLimit = useRecoilValue(companiesLimitAtom);
  const [showSelectCompaniesBySector, setShowSelectCompaniesBySector] =
    useRecoilState(showSelectCompaniesBySectorAtom);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const role = useRecoilValue(roleAtom);
  const isAdmin = role === "admin";

  const handleShowModal = (e) => {
    setSelectedSector(e.target.id);
    setShowSelectCompaniesBySector(true);
  };

  useEffect(() => {
    if (searchCompanyArray.length > companiesLimit) {
      setSearchCompanyArray([]);
    }
  }, []);

  const handleSearchCompany = (e) => {
    setSearchCompany(e.target.value);
    let _found = [];
    companiesListInvestor.map((comp) => {
      if (comp.name.toUpperCase().indexOf(e.target.value.toUpperCase()) >= 0) {
        _found.push(comp);
      }
    });
    setFound(_found);
  };

  const getCompaniesListInvestor = () => {
    const config = {
      method: "get",
      url: `${baseURL}/investors`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios(config)
      .then((response) => {
        // console.log( response.data.data )
        setCompaniesListInvestorAtom(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.status);
      })
      .finally(() => {
        setLoadedInvestList(true);
      });
  };

  const getSectorsList = () => {
    const config = {
      method: "get",
      url: `${baseURL}/investor_types`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios(config)
      .then((response) => {
        // console.log( response.data.data )
        setSectorsInvestList(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.status);
      })
      .finally(() => {
        setLoadedInvestSectors(true);
      });
  };

  const handleCheckOption = (e) => {
    searchCompanyArray?.map((el) => {
      if (searchCompanyArray.map((el) => el.id).includes(e.target.value)) {
        let _filtered = searchCompanyArray.filter(
          (item) => item.id !== e.target.value
        );
        setSearchCompanyArray(_filtered);
      } else {
        let _temp = searchCompanyArray.concat({
          id: e.target.value,
          name: e.target.name,
          isPublic: e.target.isPublic,
        });
        setSearchCompanyArray(_temp);
      }
    });

    if (searchCompanyArray.length === 0)
      setSearchCompanyArray([
        {
          id: e.target.value,
          name: e.target.name,
          isPublic: e.target.isPublic,
        },
      ]);
    // console.log( searchCompanyArray )
  };

  useEffect(() => {
    if (!companiesListInvestor.length && !loadedInvestList) {
      getCompaniesListInvestor();
    }

    if (!sectorsInvestList.length && !loadedInvestSectors) {
      getSectorsList();
    }
  }, []);

  return (
    <SubSectorListWrapper className="p-4">
      <h3 className="fw-400">Search Investment Firms</h3>
      <div className="d-flex justify-content-between">
        <p className="fs-14px">
          Add an investment firm to your list by searching below
        </p>
      </div>

      <div className="col-12 col-md-4 py-1 fs-14px position-relative">
        {loadedInvestList || companiesListInvestor.length ? (
          <div className="position-relative">
            <span className="search-icon">
              <i className="fa fa-search"></i>
            </span>
            <input
              type="text"
              value={searchCompany}
              disabled={searchCompanyArray.length >= companiesLimit}
              onChange={handleSearchCompany}
              className="form-control"
              placeholder="Search"
            />
            {searchCompany && (
              <i
                onClick={() => setSearchCompany("")}
                className="fa fa-times-circle clear-field-button"
              ></i>
            )}
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center text-center">
            <div className="spinner-border m-5" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {searchCompany && found && (
          <SubSectorListInner>
            <SubSectorsListStyle
            // className={found.length > 3 ?
            //   "m-1 p-3 position-relative overflow-auto border-radius-1 h-150px" :
            //   "m-1 p-3 position-relative overflow-auto border-radius-1"}
            // style={{
            //   backgroundColor: "#fff",
            //   borderRadius: "3px",
            //   border: "1px solid var(--app-component-border-color)"
            // }}>
            >
              {found.map((el, index) => {
                if (
                  index < 300 &&
                  !searchCompanyArray.map((comp) => comp.name).includes(el.name)
                )
                  return (
                    <div className="form-check mb-2" key={index}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={searchCompanyArray
                          .map((comp) => comp.name)
                          .includes(el.name)}
                        disabled={
                          searchCompanyArray.length >= companiesLimit &&
                          !searchCompanyArray
                            .map((comp) => comp.name)
                            .includes(el.name)
                        }
                        onChange={handleCheckOption}
                        id={`checkbox-${el.id}`}
                        name={el.name}
                        value={el.id}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`checkbox-${el.id}`}
                      >
                        {el.name}
                        {el.isPublic && <PublicTagStyle>public</PublicTagStyle>}
                      </label>
                    </div>
                  );
              })}
              {found.length === 0 && searchCompany.length && (
                <div className="color-secondary">no results</div>
              )}
            </SubSectorsListStyle>
          </SubSectorListInner>
        )}
      </div>
      {!isAdmin && (
        <div className="d-flex align-items-center justify-content-end">
          <span className="fs-16px mx-3">Don't see a Firm?</span>
          <Link
            to="#"
            onClick={() => {
              setShowNewCompanyModal(true);
              setNewModalTitle("Firm");
            }}
            className="text-link cursor-pointer fs-16px"
          >
            {" "}
            Request New Firm
          </Link>
        </div>
      )}
      <hr />
      <h3 className="fw-400">Browse Firms by Type</h3>
      <p className="fs-14px">
        Add an investment firm to your list from the categories below
      </p>
      <div className="col-12 py-2 ">
        {loadedInvestList ||
        (sectorsInvestList.length > 0 && companiesListInvestor.length > 0) ? (
          <div className="col-12 w-100">
            {sectorsInvestList.map((sector) => {
              if (sector.investors.length) {
                console.log(companiesLimit, searchCompanyArray.length);
                return (
                  <div key={sector.name} className="mb-3 fs-20px">
                    <MoneyIcon
                      color={
                        companiesLimit <= searchCompanyArray.length
                          ? "#2E2B30"
                          : "#B668AE"
                      }
                    />
                    <span
                      
                      // to=""
                      // className="text-decoration-none color-neutral-black mx-1"
                      className={
                        companiesLimit <= searchCompanyArray.length
                          ? "text-decoration-none color-neutral-black mx-1 cursor-pointer"
                          : "text-decoration-none color-purple mx-1 cursor-pointer"
                      }
                      // style={{color: "#42663A"}}
                      id={sector.id}
                      onClick={handleShowModal}
                    >
                      {" "}
                      {sector.name} ({sector.investors.length})
                    </span>
                  </div>
                );
              }
            })}
          </div>
        ) : (
          <div className="d-flex col-md-4 justify-content-center align-items-center text-center">
            <div className="spinner-border m-5" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
      <SelectCompanyByInvestor />
      <ReactNotifications />
    </SubSectorListWrapper>
  );
};

export default InvestorDealList;
