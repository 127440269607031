import React, { useEffect, useState } from "react";
import TableIcon from "../../components/shared/TableIcon";
import styled from "styled-components";
import ScatterPlotIcon from "../../components/shared/ScatterPlotIcon";
import TimeSeriesIcon from "../../components/shared/TimeSeriesIcon";
import ListIcon from "../../components/shared/ListIcon";
import CreatableSelect from "react-select/creatable";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  chosenMetricsAtom,
  chosenTagsAtom,
  companiesLimitAtom,
  CSMetricsAttributesAtom,
  metricLimitAtom,
  newCustomCompNameAtom,
  newCustomCompTypeAtom,
  nextStepTitleAtom,
  searchCompanyArrayAtom,
  sectorsInvestListAtom,
  stepOfCreationCompsAtom,
  tagsOptionsAtom,
} from "../../atoms/profileAtom";
import { APIHeaders } from "../../components/shared/helpers";
import axios from "axios";
import CircleTableIcon from "../../components/shared/CircleTableIcon";
import CircleTimeSeriesIcon from "../../components/shared/CircleTimeSeriesIcon";
import CircleScatterPlotIcon from "../../components/shared/CircleScatterPlotIcon";

const LabelTitle = styled.span`
  font-weight: 500;
  font-size: 18px;
  color: #000;
  display: block;
  line-height: 18px;
  margin-left: 10px;
`;

export const SelectCard = styled.div`
  //width: 306px;
  padding: 20px;
  //border: ${(props) =>
    props.selected === true ? "1px solid #aaa" : "1px solid #aaa"};
  box-shadow: ${(props) =>
    props.selected === true
      ? "0px 0px 4px 0px rgba(0, 0, 0, 0.13)"
      : "0px 0px 10px 0px #eee"};
  border-radius: 20px;
  margin-right: 25px;
  transition: all 0.3s;
  cursor: pointer;
  background-color: #fff;
  opacity: ${(props) => (props.selected === true ? "1" : "0.75")};
  flex: 1 1 0px;
  position: relative;
  padding-top: 55px;

  &:hover {
    opacity: 1;
    box-shadow: 0px 0px 10px 0px #555;

    .check-wrapper {
      color: var(--bs-secondary);
      opacity: 0.7;
    }
  }

  &:nth-last-of-type(1) {
    margin-right: 0;
  }

  label {
    cursor: pointer;
    width: 100%;
  }

  .check-wrapper {
    transition: all 0.3s;
    opacity: 0.4;
  }
`;

export const CheckWrapper = styled.div`
  color: ${(props) =>
    props.selected === true
      ? "var(--bs-primary) !important"
      : "var(--bs-secondary)"};
  opacity: ${(props) => (props.selected === true ? "1 !important" : "0")};
`;

const LabelDescription = styled.span`
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  margin-top: 15px;
  margin-bottom: 5px;
  color: #000;
  display: block;
  min-height: 4.5rem;
`;

const ChartIconsContainer = styled.div`
  margin-top: 70px;
`;

const Card = styled.div`
  border-radius: 12px;
  background: #fff;
  padding: 32px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
`;

const CircleIconContainer = styled.div`
  position: absolute;
  top: -30%;
  left: 30%;
`;

const NewCompSetStep1 = () => {
  const navigate = useNavigate();
  const { step } = useParams();
  console.log("step: ", step);
  const [compName, setCompName] = useRecoilState(newCustomCompNameAtom);
  const [compType, setCompType] = useRecoilState(newCustomCompTypeAtom);
  const [companiesLimit, setCompaniesLimit] =
    useRecoilState(companiesLimitAtom);
  const [metricLimit, setMetricLimit] = useRecoilState(metricLimitAtom);
  const [stepOfCreationComps, setStepOfCreationComps] = useRecoilState(
    stepOfCreationCompsAtom
  );
  const [nextStepTitle, setNextStepTitle] = useRecoilState(nextStepTitleAtom);
  const [sectorsInvestList, setSectorsInvestList] = useRecoilState(
    sectorsInvestListAtom
  );
  const [searchCompanyArray, setSearchCompanyArray] = useRecoilState(
    searchCompanyArrayAtom
  );
  const [chosenMetrics, setChosenMetrics] = useRecoilState(chosenMetricsAtom);
  const [tagsOptions, setTagsOptions] = useRecoilState(tagsOptionsAtom);
  console.log("tagsOptions: ", tagsOptions);
  const [CSMetricsAttributes, setCSMetricsAttributes] = useRecoilState(
    CSMetricsAttributesAtom
  );
  const [chosenTags, setChosenTags] = useRecoilState(chosenTagsAtom);
  const [apiTags, setApiTags] = useState([]);
  const [loadedTags, setLoadedTags] = useState(false);
  console.log("loadedTags: ", loadedTags);
  const [defaultTag, setDefaultTag] = useState();
  console.log("defaultTag: ", defaultTag);
  const baseURL = process.env.REACT_APP_BASE_URL;

  const listOptionsObj = [
    {
      id: "company-deal-list",
      name: "Company Fundraising History",
      description:
        "Funding history of any company: round, date, size, valuation, investors",
    },
    {
      id: "sector-deal-list",
      name: "Sector Investment List",
      description:
        "Investments in a given sector: company, round, date, size, valuation, investors",
    },
    {
      id: "investor-list",
      name: "Firm Retail Investments",
      description:
        "All 4wall investments made by a specific firm: company, round, date, size, valuation, co-investors",
    },
    {
      id: "store-list",
      name: "Store List",
      description:
        "All store information for a company: address, name, region, open/close status, customer rating, # customer reviews",
    },
  ];

  const handleName = (e) => {
    setCompName(e.target.value);
  };

  const getTagsList = () => {
    setLoadedTags(false);
    const config = {
      method: "get",
      url: `${baseURL}/tags`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios(config)
      .then((resp) => {
        let _options = resp.data.data;
        let _filteredOption = _options.filter(
          (t) => t.label === "General Research"
        )[0];
        // console.log(_filteredOption)
        setApiTags(resp.data.data);
        setTagsOptions(_options);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/user/login", { replace: true });
        }
      })
      .finally(() => {
        setLoadedTags(true);
      });
    // console.log(tagsOptions.length)
  };

  useEffect(() => {
    if (tagsOptions.length > 0) {
      let _filteredOption = tagsOptions.filter(
        (t) => t.label === "General Research"
      )[0];
      setDefaultTag(_filteredOption);
      setChosenTags([_filteredOption]);
    }
  }, [tagsOptions]);
  // console.log(chosenTags, defaultTag, typeof defaultTag)

  useEffect(() => {
    if (compType !== "Firm Retail Investments") {
      if (tagsOptions.length === 0) {
        getTagsList();
      } else {
        setLoadedTags(true);
      }
      setSectorsInvestList([]);
      setSearchCompanyArray([]);
    }
  }, []);

  const handleType = (e) => {
    console.log("fff");
    const type = e.target.value;
    setCompType(type);
    setChosenMetrics([]);
    setCSMetricsAttributes([]);
    setSearchCompanyArray([]);
    if (type === "Table") {
      setCompaniesLimit(10);
    }
    if (type === "Scatter Plot") {
      setCompaniesLimit(15);
    }
    if (type === "Time Series") {
      setCompaniesLimit(3);
      setMetricLimit(2);
    }
    if (type === "Table" || type === "Time Series" || type === "Scatter Plot") {
      // setNextStepTitle( 'Choose Metrics' );
    }
    if (type === "Company Fundraising History") {
      setCompaniesLimit(1);
    }
    if (
      type === "Company Fundraising History" ||
      type === "Sector Investment List" ||
      type === "Firm Retail Investments" ||
      type === "Store List"
    ) {
      // setNextStepTitle( 'Generate list' );
    }
    if (type === "Table") {
      setMetricLimit(10);
    }
    if (type === "Scatter Plot") {
      setMetricLimit(2);
    }
    if (type === "Store List") {
      setCompaniesLimit(1);
    }
    if (type === "Firm Retail Investments") {
      setCompaniesLimit(3);
    }

    if (compName) {
      // setStepOfCreationComps(2);
      console.log("navigating to step2");
      navigate("/new-comp-set/2");
    }
  };

  const handleChangeTag = (e) => {
    console.log(e);
    setChosenTags(e);
  };

  return (
    <Card className="pb-4 pt-4">
      <div className="row justify-content-between mb-2">
        <div className="d-flex flex-column">
          <div className="col-md-5 col-12 mb-4">
            <div className="mb-3 d-flex align-items-center">
              <h4 className="color-neutral-black fw-400 mb-0">My Comp Name:</h4>
            </div>
            <div className="position-relative">
              <input
                className="form-control w100"
                type="text"
                value={compName}
                onChange={handleName}
                placeholder="e.g. VC-backed winners, Premium Coffee in LA, etc."
              />
              {compName && (
                <span
                  className="clear-field"
                  style={{ right: "15px" }}
                  onClick={() => setCompName("")}
                >
                  <i className="fa fa-times"></i>
                </span>
              )}
            </div>
          </div>

          {loadedTags ||
          (tagsOptions.length > 0 &&
            defaultTag &&
            Object.keys(defaultTag).length > 0) ? (
            <div className="col-md-8 col-12 mb-4">
              <div className="d-flex align-items-center mb-3">
                <h4 className="color-neutral-black fw-400 mb-0">
                  Project tag(s):
                </h4>
                <span className="px-2">
                  Start typing to search for an existing project tag or create a
                  new one.
                </span>
              </div>
              <CreatableSelect
                onChange={(e) => handleChangeTag(e)}
                classNamePrefix="select"
                defaultValue={defaultTag}
                isLoading={tagsOptions.length === 0 && !loadedTags}
                name="tags"
                isClearable={false}
                isMulti
                placeholder="Select project tag or create new"
                options={tagsOptions}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "#b787b3 !important"
                      : "var(--app-component-border-color) ",
                    boxShadow: state.isFocused
                      ? "0 0 0 0.25rem rgba(159, 95, 153, 0.25) !important"
                      : "0 0 0 0.25rem rgba(159, 95, 153, 0)",
                  }),
                }}
              />
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center text-center h-40px">
              <div className="spinner-border m-5" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="col-12 mb-4">
        <h4 className="color-neutral-black mb-3 fw-400">Create a Chart:</h4>
        <ChartIconsContainer className="d-flex mb-4">
          <SelectCard selected={compType === "Table"}>
            <CircleIconContainer>
              <CircleTableIcon />
            </CircleIconContainer>
            <input
              className="form-check-input d-none"
              type="radio"
              id="comp-table"
              name="type-comp"
              checked={compType === "Table"}
              // onChange={handleType}
              onClick={handleType}
              value="Table"
            />
            <label htmlFor="comp-table">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <LabelTitle>Comps Table</LabelTitle>
                </div>
                <CheckWrapper
                  className="check-wrapper"
                  selected={compType === "Table"}
                >
                  {compType === "Table" ? (
                    <i className="fa fa-check-circle fs-18px"></i>
                  ) : (
                    <i className="fa-regular fa-circle fs-18px"></i>
                  )}
                </CheckWrapper>
              </div>
              <LabelDescription>
                Classic comps table. Companies stacked on the left and up to 10
                metrics in the columns on the right
              </LabelDescription>
            </label>
          </SelectCard>
          <SelectCard selected={compType === "Time Series"}>
            <CircleIconContainer>
              <CircleTimeSeriesIcon />
            </CircleIconContainer>
            <input
              className="form-check-input d-none"
              type="radio"
              id="time-series"
              name="type-comp"
              checked={compType === "Time Series"}
              // onChange={handleType}
              onClick={handleType}
              value="Time Series"
            />
            <label htmlFor="time-series">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <LabelTitle>Time Series</LabelTitle>
                </div>
                <CheckWrapper
                  className="check-wrapper"
                  selected={compType === "Time Series"}
                >
                  {compType === "Time Series" ? (
                    <i className="fa fa-check-circle fs-18px"></i>
                  ) : (
                    <i className="fa-regular fa-circle fs-18px"></i>
                  )}
                </CheckWrapper>
              </div>
              <LabelDescription>
                Make a bar or line chart showing data over time for up to 3
                companies
              </LabelDescription>
            </label>
          </SelectCard>
          <SelectCard selected={compType === "Scatter Plot"}>
            <CircleIconContainer>
              <CircleScatterPlotIcon />
            </CircleIconContainer>
            <input
              className="form-check-input d-none"
              type="radio"
              id="scatter-plot"
              name="type-comp"
              checked={compType === "Scatter Plot"}
              // onChange={handleType}
              onClick={handleType}
              value="Scatter Plot"
            />
            <label htmlFor="scatter-plot">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <LabelTitle>Scatter Plot</LabelTitle>
                </div>
                <CheckWrapper
                  className="check-wrapper"
                  selected={compType === "Scatter Plot"}
                >
                  {compType === "Scatter Plot" ? (
                    <i className="fa fa-check-circle fs-18px"></i>
                  ) : (
                    <i className="fa-regular fa-circle fs-18px"></i>
                  )}
                </CheckWrapper>
              </div>
              <LabelDescription>
                Compare 2 metrics for up to 15 companies
              </LabelDescription>
            </label>
          </SelectCard>
        </ChartIconsContainer>
      </div>
      <div className="col-12 mb-4">
        <h4 className="color-neutral-black mb-3 fw-400">Create a List:</h4>
        <div className="d-flex justify-content-between">
          {listOptionsObj.map((list) => {
            return (
              <SelectCard selected={compType === list.name} key={list.id}>
                <input
                  className="form-check-input d-none"
                  type="radio"
                  id={list.id}
                  name="type-comp"
                  checked={compType === list.name}
                  onChange={handleType}
                  onClick={handleType}
                  value={list.name}
                />
                <label htmlFor={list.id}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <div>
                        <ListIcon size="40" />
                      </div>
                      <LabelTitle>{list.name}</LabelTitle>
                    </div>
                    <CheckWrapper
                      className="check-wrapper"
                      selected={compType === list.name}
                    >
                      {compType === list.name ? (
                        <i className="fa fa-check-circle fs-18px"></i>
                      ) : (
                        <i className="fa-regular fa-circle fs-18px"></i>
                      )}
                    </CheckWrapper>
                  </div>
                  <LabelDescription>{list.description}</LabelDescription>
                </label>
              </SelectCard>
            );
          })}
        </div>
      </div>
      {/*<div className="d-flex justify-content-end">*/}
      {/*  <Link to=""*/}
      {/*        className={compType && compName ? "fs-16px btn w-150px text-light bg-green2 text-uppercase" : "fs-16px btn w-150px text-light bg-green2 text-uppercase disabled"}*/}
      {/*        onClick={() => setStepOfCreationComps( 2 )}*/}
      {/*  >Next</Link>*/}
      {/*</div>*/}
    </Card>
  );
};

export default NewCompSetStep1;
