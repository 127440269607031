import styled from "styled-components";
import BeautyIcon from "./Icons/BeautyIcon";
import EducationIcon from "./Icons/EducationIcon";
import HealthIcon from "./Icons/HealthCare";
import ExperientialIcon from "./Icons/ExperientialIcon";
import ExerciseIcon from "./Icons/ExerciseIcon";
import FnbIcon from "./Icons/fnbIcon";
import GroceryIcon from "./Icons/GroceryIcon";
import HomeIcon from "./Icons/HomeIcon";
import HospitalityIcon from "./Icons/HospitalityIcon";
import D2cIcon from "./Icons/D2cIcon";
import PetIcon from "./Icons/PetIcon";

const SectorIconWrapper = styled.div`
  width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SectorIcon = ( { sector, size } ) => {
  let _icon = '';

  switch ( sector ) {
    case 'Beauty':
      _icon = <BeautyIcon size={size}/>
      break;
    case 'Child, Daycare, Education':
      _icon = <EducationIcon size={size}/>
      break;
    case 'Consumer Healthcare':
      _icon = <HealthIcon  size={size}/>
      break;
    case 'Experiential':
      _icon = <ExperientialIcon size={size} />
      break;
    case 'Fitness':
      _icon = <ExerciseIcon  size={size}/>
      break;
    case 'FnB: Fast Casual, QSR, Casual, Lounge':
      _icon = <FnbIcon size={size} />
      break;
    case 'Grocery & Convenience':
      _icon = <GroceryIcon size={size} />
      break;
    case 'Home':
      _icon = <HomeIcon size={size} />
      break;
    case 'Hospitality, Social, Co-Working':
      _icon = <HospitalityIcon size={size} />
      break;
    case 'Omnichannel & D2C':
      _icon = <D2cIcon size={size} />
      break;
    case 'Pet & Veterinary':
      _icon = <PetIcon size={size} />
      break;
    default:
      _icon = <D2cIcon size={size} />
      break;
  }

  return <SectorIconWrapper>
    {_icon}
  </SectorIconWrapper>
}

export default SectorIcon;
