import { useState } from "react";
import { kitcut } from "../../components/shared/helpers";
import { Link } from "react-router-dom";

const CompanyDescription = ( { desc } ) => {
  const [ showMore, setShowMore ] = useState( false )
  let _limitChar = 300;

  if ( desc.length > _limitChar ) {
    return <>{!showMore ? kitcut( desc, _limitChar ) : desc} {showMore ? <br/> : <></>} <Link
      to="#"
      onClick={() => setShowMore( !!!showMore )}
      className="text-link"
    >{showMore ? 'less' : 'more'}</Link></>
  } else {
    return <>{desc}</>
  }
}
export default CompanyDescription;
