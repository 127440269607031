import { useRecoilState, useRecoilValue } from "recoil";
import {
  chosenTagsAtom,
  CSMetricsAttributesAtom,
  customCompAtom,
  inProgressNewCustomCompAtom,
  metricSameStartAtom,
  needUpdateCompAtom,
  newCustomCompNameAtom,
  newCustomCompTypeAtom,
  roleAtom,
  searchCompanyArrayAtom,
  selectedSectorsAtom,
  stepOfCreationCompsAtom,
  timeframeEndDateAtom,
  timeframeStartDateAtom,
} from "../../atoms/profileAtom";
import React, { useEffect, useState, useContext } from "react";
import { TableChartWrapper } from "../Chart/TableChart";
import {
  abbrNum,
  APIHeaders,
  APIHeadersRAW,
} from "../../components/shared/helpers";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import CommonErrorComponent from "../../components/shared/CommonErrorComponent";
import TitleCustomComp from "../../components/shared/TitleCustomComp";
import TitleTagCustomComp from "../../components/shared/TitleTagCustomComp";
import { WrapperCustomComp } from "../market-tracker/MarketTrackerTimeline";
import ListIcon from "../../components/shared/ListIcon";
import { AppSettings } from "../../config/app-settings";

const remapCompData = (data) => {
  let _data = [...data];
  let __data = [];
  let _rounds = [];
  _data.map((round) => _rounds.push(round.roundNumber));
  _rounds = new Set(_rounds);

  _rounds.forEach((r) => {
    let _investors = [];
    _data.forEach((round) => {
      if (+round.roundNumber === +r) {
        _investors.push(round.investor.name);
      }
    });
    let _baseRound = _data.find((el) => el.roundNumber === r);
    let _round = Object.assign(
      {
        investorList: _investors,
      },
      _baseRound
    );
    // console.log(_round)
    __data.push(_round);
  });

  __data.sort(function (a, b) {
    if (a.roundNumber > b.roundNumber) {
      return 1;
    }
    if (a.roundNumber < b.roundNumber) {
      return -1;
    }
    return 0;
  });

  return __data;
};

const investorsList = (list) => {
  if (list) {
    let _length = list.length;
    return list.map((item, index) => {
      if (index < _length - 1) {
        return `${item}, `;
      } else {
        return item;
      }
    });
  }

  return <></>;
};

const baseURL = process.env.REACT_APP_BASE_URL;

const InvestmentTable = ({ preview }) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const context = useContext(AppSettings);
  const [customComp, setCustomComp] = useRecoilState(customCompAtom);
  const [needUpdateComp, setNeedUpdateComp] =
    useRecoilState(needUpdateCompAtom);
  const chosenTags = useRecoilValue(chosenTagsAtom);
  const role = useRecoilValue(roleAtom);
  const [compName, setCompName] = useRecoilState(newCustomCompNameAtom);
  const [compType, setCompType] = useRecoilState(newCustomCompTypeAtom);
  const [metricSameStart, setMetricSameStart] =
    useRecoilState(metricSameStartAtom);
  const [timeframeStartDate, setTimeframeStartDate] = useRecoilState(
    timeframeStartDateAtom
  );
  const [timeframeEndDate, setTimeframeEndDate] =
    useRecoilState(timeframeEndDateAtom);
  const [searchCompanyArray, setSearchCompanyArray] = useRecoilState(
    searchCompanyArrayAtom
  );
  const [selectedSectors, setSelectedSectors] =
    useRecoilState(selectedSectorsAtom);
  const [CSMetricsAttributes, setCSMetricsAttributes] = useRecoilState(
    CSMetricsAttributesAtom
  );
  const [stepOfCreationComps, setStepOfCreationComps] = useRecoilState(
    stepOfCreationCompsAtom
  );
  const [inProgressNewCustomComp, setInProgressNewCustomComp] = useRecoilState(
    inProgressNewCustomCompAtom
  );
  const isAdmin = role === "admin";

  const [compData, setCompData] = useState([]);
  const [isLoadedComp, setIsLoadedComp] = useState(false);
  const [totalAmount, setTotalAmount] = useState("");
  const [totalValuation, setTotalValuation] = useState("");
  const [customCompType, setCustomCompType] = useState("");
  const [errorMessageAPI, setErrorMessageAPI] = useState("");
  const [invalidData, setInvalidData] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  console.log("invalidData: ", invalidData);
  console.log("compData in InvestmentTable: ", compData);

  useEffect(() => {
    context.handleSetAppHeaderNone(false);
    context.handleSetAppSidebarNone(false);
    context.handleSetAppContentClass("mh-100vh-88");
    context.handleSetAppTitle("Sector Investment List");
    context.handleSetAppIcon(<ListIcon />);
  }, []);

  useEffect(() => {
    let _compType = "";
    if (customCompType === "CustomComps::CompanyList") {
      _compType = "Company Fundraising History";
    } else if (customCompType === "CustomComps::SectorList") {
      _compType = "Sector Investment List";
    } else if (customCompType === "CustomComps::StoreList") {
      _compType = "Store List";
    } else if (customCompType === "CustomComps::InvestorList") {
      _compType = "Firm Retail Investments";
    }
    context.handleSetAppTitle(_compType);
  }, [customCompType]);

  const handleSaveCustomComps = (e, action) => {
    e.preventDefault();
    let _existingTags = [];
    let _newTags = [];
    chosenTags.map((tag) => {
      if (tag.id !== undefined) {
        _existingTags.push({ tag_id: tag.id });
      } else {
        _newTags.push({ label: tag.label });
      }
    });

    setInProgress(true);
    let _url = `${baseURL}/custom_comps/new`;
    if (action === "save") {
      _url = `${baseURL}/custom_comps`;
    }

    let _compType = "";
    if (compType === "Table") {
      _compType = "CustomComps::Table";
    } else if (compType === "Time Series") {
      _compType = "CustomComps::TimeSeries";
    } else if (compType === "Scatter Plot") {
      _compType = "CustomComps::ScatterPlot";
    } else if (compType === "Company Fundraising History") {
      _compType = "CustomComps::CompanyList";
    } else if (compType === "Sector Investment List") {
      _compType = "CustomComps::SectorList";
    } else if (compType === "Store List") {
      _compType = "CustomComps::StoreList";
    } else if (compType === "Firm Retail Investments") {
      _compType = "CustomComps::InvestorList";
    }

    let metricData = {};
    metricData["custom_comp"] = {
      name: compName,
      type: _compType,
      start_date: !metricSameStart ? timeframeStartDate : "",
      end_date: !metricSameStart ? timeframeEndDate : "",
      taggings_attributes: _existingTags,
      tags: _newTags,
      resource_ids:
        compType !== "Sector Investment List"
          ? searchCompanyArray.map((el) => el.id)
          : selectedSectors.map((el) => el.id),
      custom_comp_metrics_attributes:
        compType !== "Sector Investment List"
          ? CSMetricsAttributes.map((el) => {
              return {
                metric_id: el.metric_id,
                ts_chart_type: el.ts_chart_type,
                same_start: metricSameStart,
              };
            })
          : [],
      // } ) : [{metric_id: 1}],
    };
    // same_start: metricSameStart

    const config = {
      method: "post",
      url: _url,
      headers: APIHeadersRAW,
      withCredentials: true,
      credentials: "include",
      data: metricData,
    };
    axios(config)
      .then((resp) => {
        console.log(resp);
        if (resp.data) {
          setCustomComp(resp.data.data);
          setStepOfCreationComps(4);
        }
        if (action === "save") {
          // toasterNotify( 'Custom Comp was created', '', 'success' );
          navigate("/custom-comps", { replace: false });
          setInProgressNewCustomComp(false);
        }
        setInProgress(false);
      })
      .catch((error) => {
        console.log(error);
        setInProgress(false);
      });
  };

  const getCustomComp = () => {
    setIsLoadedComp(false);
    const data = new FormData();
    const config = {
      method: "get",
      url: `${baseURL}/custom_comps/${id}?include_data=true`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
      data: data,
    };
    axios(config)
      .then((resp) => {
        setCustomComp(resp.data.data);
        setInvalidData(false);
        setCustomCompType(resp.data.data.type);
        // console.log(resp.data.data)
      })
      .finally(() => {
        setIsLoadedComp(true);
      })
      .catch((error) => {
        console.log("error: ", error);
        setInvalidData(true);
        setErrorMessageAPI(error.response.data.error.title);
      });
  };

  useEffect(() => {
    if (!preview && !customComp.length) {
      getCustomComp();
    }
  }, [needUpdateComp]);

  useEffect(() => {
    if (customComp.comp_data !== undefined && customComp.comp_data.length) {
      setCompData(remapCompData(customComp.comp_data));
    }
  }, [customComp]);

  useEffect(() => {
    setTotalAmount(
      compData.reduce((acc, currentValue) => acc + currentValue.amount, 0)
    );

    setTotalValuation(compData.slice(-1)[0]?.postValuation);
  }, [compData]);

  if (!isLoadedComp && !preview)
    return (
      <div className="d-flex justify-content-center align-items-center text-center min-vh-100">
        <div className="spinner-border m-5" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );

  return (
    <>
      {
        <TableChartWrapper className={preview ? "" : ""}>
          <TitleTagCustomComp
            compName={customComp.name}
            id={id}
            isPopular={customComp.default_comp}
            ownerID={customComp.user_id}
            taggings={customComp.taggings}
            preview={preview}
          />

          <WrapperCustomComp>
            {!preview && (
              <TitleCustomComp
                compName={customComp.name}
                id={id}
                isPopular={customComp.default_comp}
                ownerID={customComp.user_id}
                taggings={customComp.taggings}
                preview={preview}
              />
            )}

            {compData.length > 0 && (
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    {/*<th scope="col">Company</th>*/}
                    <th scope="col">Deal</th>
                    <th scope="col">Investors</th>
                    <th scope="col">Company</th>
                    <th scope="col">Date</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Valuation</th>
                  </tr>
                </thead>
                <tbody>
                  {compData.map((entry, index) => {
                    // console.log(entry);
                    return (
                      <tr key={`tr-${index}`} className="text-center">
                        {/*<td>{entry.company.name}</td>*/}
                        <td>{entry.round}</td>
                        <td>{investorsList(entry.investorList)}</td>
                        <td>{entry.company.name}</td>
                        <td>
                          {moment(entry.investmentDate).format("MMMM YYYY")}
                        </td>
                        <td title={entry.amount}>
                          {entry.amount ? "$" + abbrNum(entry.amount, 1) : ""}
                        </td>
                        <td title={entry.postValuation}>
                          {entry.postValuation
                            ? "$" + abbrNum(entry.postValuation, 2)
                            : ""}
                        </td>
                      </tr>
                    );
                  })}
                  <tr className="text-center">
                    <th
                      colSpan="4"
                      style={{ textAlign: "right", paddingRight: "30px" }}
                    >
                      Total:
                    </th>
                    <td>{totalAmount ? "$" + abbrNum(totalAmount, 1) : ""}</td>
                    <td>
                      {totalValuation ? "$" + abbrNum(totalValuation, 1) : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}

            {compData.length === 0 && (
              <div className="py-3">
                <h5>No Data available</h5>
              </div>
            )}
            {preview && (
              <div className="d-flex justify-content-end my-3">
                <Link
                  to="/new-comp-set/2"
                  className="fs-16px w-100px h-40px buttn-green-outline mx-3 text-center "
                >
                  BACK
                </Link>
                <Link
                  to="#"
                  className={
                    compData.length > 0
                      ? "fs-16px w-100px h-40px btn bg-green2 btn-outline-light"
                      : "fs-16px w-100px h-40px btn btn-muted disabled"
                  }
                  onClick={
                    compData.length > 0
                      ? (e) => handleSaveCustomComps(e, "save")
                      : null
                  }
                >
                  SAVE
                </Link>
              </div>
            )}
          </WrapperCustomComp>
        </TableChartWrapper>
      }

      {invalidData && (
        <CommonErrorComponent error={errorMessageAPI} admin={isAdmin} />
      )}
    </>
  );
};

export default InvestmentTable;
