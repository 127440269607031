import React, { useCallback, useContext, useEffect, useState } from "react";
import { AppSettings } from "../config/app-settings";
import { APIHeaders } from "../components/shared/helpers";
import axios from "axios";
import { useRecoilState } from "recoil";
import {
  showMediaModalAtom,
  showNewCompanyModalAtom,
} from "../atoms/profileAtom";
import { Link, useNavigate } from "react-router-dom";
import { sortArrayWithPrefixFirst } from "./custom-comps/NewCompSetStep2";
import ModalImage from "../components/modals/ModalImage";
import LoadingImage from "../components/shared/LoadingImage";
import SectorIcon from "../components/shared/SectorIcon";
import Masonry from "react-masonry-css";
import {
  lightenVeryDarkColor,
  sortArrayByLocationName,
  sortArrayByMetric,
  sortDates,
  sortedList,
  metricOptions,
} from "../utils/helpers";

import {
  SortDropdown,
  SortDropdownOptionStyle,
  colorVariantsCompany,
  FilterByComponent,
  ItemsGridWrapper,
  ItemGrid,
  SearchedCompanyList,
  SearchedItem,
  MetricTitle,
  CompaniesBySectorContainer,
  ImageContainer,
  LogosToggle,
  ListCompaniesBySector,
  ListMetricsByCategory,
  LogoWrapperSmall,
} from "./StyledComponent/mediaPages";
import { getInvalidDateObject } from "./MediaLibrary";
import CustomCompIcon from "../components/shared/CustomCompIcon";

const baseURL = process.env.REACT_APP_BASE_URL;
const baseLogoURL = process.env.REACT_APP_LOGOS_BASE_URL_600;
const signature = process.env.REACT_APP_LOGOS_SIGNATURE;

const MarketInsights = () => {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  const [pictureList, setPictureList] = useState([]);
  const [filteredPictureList, setFilteredPictureList] = useState([]);
  console.log("filteredPictureList: ", filteredPictureList);
  const [loadingImages, setLoadingImages] = useState(true);
  const [metricsList, setMetricsList] = useState([]);
  console.log("metricsList: ", metricsList);
  const [sectorsList, setSectorsList] = useState([]);
  console.log("sectorsList: ", sectorsList);
  const [companiesList, setCompaniesList] = useState([]);

  const [filterBy, setFilterBy] = useState("company");
  const [sortBy, setSortBy] = useState("");
  const [sortByDropdown, setSortByDropdown] = useState(false);
  const [order, setOrder] = useState("asc"); //desc

  const [showModal, setShowModal] = useRecoilState(showMediaModalAtom);
  const [searchCompany, setSearchCompany] = useState("");
  const [searchCompanyArray, setSearchCompanyArray] = useState([]);
  const [showLogos, setShowLogo] = useState(true);
  const [showSectorsList, setShowSectorsList] = useState(false);
  const [sectorCollapse, setSectorCollapse] = useState("");
  console.log("sectorCollapse: ", sectorCollapse);
  const [found, setFound] = useState([]);
  const [filterCompaniesBySector, setFilterCompaniesBySector] = useState("");
  console.log("filterCompaniesBySector: ", filterCompaniesBySector);
  const [filterMetricsByCategory, setFilterMetricsByCategory] = useState("");
  const [foundBySector, setFoundBySector] = useState([]);
  const [showImageWOMetric, setShowImageWOMetric] = useState(false);
  const [imageObject, setImageObject] = useState([]);
  const [modalLogo, setModalLogo] = useState([]);
  const [showNewCompanyModal, setShowNewCompanyModal] = useRecoilState(
    showNewCompanyModalAtom
  );
  const [filterMetricsList, setFilterMetricsList] = useState([]);
  console.log("filterMetricsList: ", filterMetricsList);
  const [metricsFilter, setMetricsFilter] = useState([]);

  const companiesLimit = 10;
  const metricsLimit = 10;
  const sortOption = ["Alphabetically", "Date"];
  const breakpointColumnsObj = {
    default: 4,
    1800: 3,
    1400: 2,
    500: 1,
  };

  const IconStar = () => {
    return <i className="fa fa-image fs-16px mt-1" title="media"></i>;
  };

  useEffect(() => {
    context.handleSetAppHeaderNone(false);
    context.handleSetAppSidebarNone(false);
    context.handleSetAppContentClass("mh-100vh-88");
    context.handleSetAppTitle("Market Insights ");
    context.handleSetAppIcon(<IconStar />);
  }, []);

  useEffect(() => {
    getPictures();
    getMetrics();
    getSectors();
  }, []);

  useEffect(() => {
    if (sortBy === "Alphabetically" && filterBy === "locations") {
      console.log("sortArrayByLocationName");
      setFilteredPictureList(
        sortArrayByLocationName(filteredPictureList, order)
      );
    } else if (sortBy === "Alphabetically" && filterBy === "company") {
      setFilteredPictureList(sortArrayByMetric(filteredPictureList, order));
    } else if (sortBy === "Date") {
      console.log("sortDates");
      setFilteredPictureList(sortDates(filteredPictureList, order));
    }
  }, [filteredPictureList, sortBy, order]);

  const handleSearchCompany = (e) => {
    setSearchCompany(e.target.value);
    // console.log( e.target.value );
    let _found = [];
    companiesList.map((comp) => {
      if (comp.name.toUpperCase().indexOf(e.target.value.toUpperCase()) >= 0) {
        _found.push(comp);
      }
    });
    let _sortedFound = sortArrayWithPrefixFirst(
      _found,
      e.target.value.toUpperCase()
    );
    // console.log( _sortedFound )
    setFound(_sortedFound);
  };

  function findIndexByValue(array, value) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].name === value) {
        return i;
      }
    }
    return -1; // Return -1 if the value is not found
  }

  const handleRemoveSearch = (e) => {
    let _filtered = searchCompanyArray.filter((item) => item.id !== e);
    setSearchCompanyArray(_filtered);
  };

  const handleCheckOption = (e) => {
    // console.log( e.target.dataset.value )
    // console.log( e.target.value )
    searchCompanyArray?.map((el) => {
      if (searchCompanyArray.map((el) => el.id).includes(e.target.value)) {
        let _filtered = searchCompanyArray.filter(
          (item) => item.id !== e.target.value
        );
        setSearchCompanyArray(_filtered);
      } else {
        let _temp = searchCompanyArray.concat({
          id: e.target.value,
          name: e.target.dataset.value,
          logo: e.target.dataset.logo,
          color: e.target.dataset.color,
          refId: e.target.dataset.refid,
          isPublic: e.target.dataset.ispublic === "true",
        });
        setSearchCompanyArray(_temp);
      }
    });

    if (searchCompanyArray.length === 0)
      setSearchCompanyArray([
        {
          id: e.target.value,
          name: e.target.dataset.value,
          logo: e.target.dataset.logo,
          color: e.target.dataset.color,
          refId: e.target.dataset.refid,
          isPublic: e.target.dataset.ispublic === "true",
        },
      ]);
    // console.log( searchCompanyArray )
  };

  useEffect(() => {
    if (searchCompanyArray.length > 0) {
      let _filteredPictureList = [];
      // filteredPictureList.filter(item=> item.tags.company !== )
      _filteredPictureList = pictureList.filter((image) => {
        // Check if the refId of the image is present in the refIdsArray
        return searchCompanyArray.some(
          (refIdObj) => refIdObj.refId === image.tags.company
        );
      });
      // console.log( 'count ' + _filteredPictureList.length )
      setFilteredPictureList(_filteredPictureList);
    } else if (searchCompanyArray.length === 0) {
      setFilteredPictureList(pictureList);
    }
    console.log(searchCompanyArray);
  }, [searchCompanyArray]);

  useEffect(() => {
    if (pictureList.length > 0) {
      setFilteredPictureList(pictureList);
      getInvalidDateObject(pictureList);
    }
  }, [pictureList]);

  useEffect(() => {
    if (sectorsList.length) {
      const companiesArray = [];
      sectorsList.forEach((entry) =>
        entry.companies.forEach((company) => {
          companiesArray.push({
            id: company.id,
            name: company.name,
            refId: company.refId,
            logo600FileName: company.logo600FileName,
            color: company.color,
          });
        })
      );
      setCompaniesList(companiesArray);
      setFoundBySector(sectorsList);
    }
  }, [sectorsList]);

  const handleFilterBy = (by) => {
    setFilterBy(by);
    if (by === "company") {
      setFilterMetricsList([]);
      setFilterMetricsByCategory([]);
      setFilteredPictureList(pictureList);
    } else {
      setSearchCompanyArray([]);
      setFilteredPictureList(pictureList);
    }
  };

  const handlePutImageToModal = (pic, logoImg) => {
    setImageObject(pic);
    // console.log( logoImg )
    setShowModal(true);
    setModalLogo(logoImg);
  };

  const handleCollapseSector = (sector) => {
    setSectorCollapse(sector === sectorCollapse ? "" : sector);
  };

  const handleSearchBySector = () => {
    setShowSectorsList(!showSectorsList);
  };

  const handleMetricByCategory = (e) => {
    setFilterMetricsByCategory(e === filterMetricsByCategory ? "" : e);
  };

  const handleChangeFilterCompanyBySector = (e) => {
    console.log(e.target.value);
    setFilterCompaniesBySector(e.target.value);
    if (!showSectorsList) {
      setShowSectorsList(true);
    }

    function companyNameContainsSymbol(company, symbol) {
      return company.name.toUpperCase().includes(symbol.toUpperCase());
    }

    const filteredArray = sectorsList
      .map((category) => ({
        ...category,
        companies: category.companies.filter((company) =>
          companyNameContainsSymbol(company, e.target.value)
        ),
      }))
      .filter((category) => category.companies.length > 0);

    // let _sortedFound = sortArrayWithPrefixFirst( _found, e.target.value.toUpperCase() )
    // console.log( filteredArray )
    console.log("filteredArray: ", filteredArray);
    console.log("filteredArray.length === 1: ", filteredArray.length === 1);
    if (filteredArray.length === 1) {
      setSectorCollapse(filteredArray[0].name);
    } else {
      setSectorCollapse("");
    }
    setFoundBySector(filteredArray);
  };

  // useEffect(() => {
  //   if (!filterCompaniesBySector && sectorCollapse) {
  //     console.log("resetting sectorCollapse");
  //     setSectorCollapse("");
  //   }
  // }, [filterCompaniesBySector, sectorCollapse]);

  const handleClearAll = () => {
    setSearchCompanyArray([]);
    setSearchCompany("");
    setSectorCollapse("");
    setFilteredPictureList(pictureList);
  };

  const getPictures = () => {
    // setLoadedCompanies( false )
    var data = JSON.stringify({
      filter: {
        thumbnail: false,
      },
    });
    const config = {
      method: "get",
      // url: `${baseURL}/pictures?filter[location]=ca945f64-bc5f-ed11-9561-002248046e4e&filter[company]=true&thumbnail=false`,
      url: `${baseURL}/pictures?filter[thumbnail]=false`,
      // url: `${baseURL}/pictures`,
      headers: {
        ...APIHeaders,
        "Cache-Control": "public, max-age=3600", // Cache for one hour (3600 seconds)
      },
      withCredentials: true,
      credentials: "include",
      // data : data,
      redirect: "follow",
    };
    axios(config)
      .then((response) => {
        console.log(response.data.data);
        // setPictureList( sortedList( response.data.data ) );
        setPictureList(response.data.data);
      })
      .catch((error) => {
        // console.log( error.response.status );
        if (error.response.status === 401) {
          navigate("/user/login", true);
        }
      })
      .finally(() => {
        setLoadingImages(false);
      });
  };

  const getMetrics = () => {
    const config = {
      method: "get",
      // url: `${baseURL}/pictures?filter[location]=ca945f64-bc5f-ed11-9561-002248046e4e&filter[company]=true&thumbnail=false`,
      url: `${baseURL}/media/metrics`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
      // data : data,
      redirect: "follow",
    };
    axios(config)
      .then((response) => {
        console.log(response.data.data);
        // setPictureList( sortedList( response.data.data ) );
        setMetricsList(response.data.data);
      })
      .catch((error) => {
        // console.log( error.response.status );
        if (error.response.status === 401) {
          navigate("/user/login", true);
        }
      })
      .finally(() => {
        // setLoadedCompanies( true );
      });
  };

  const getSectors = () => {
    const config = {
      method: "get",
      // url: `${baseURL}/sectors?all=true`,
      url: `${baseURL}/sectors`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
      redirect: "follow",
    };
    axios(config)
      .then((response) => {
        console.log(response.data.data);
        // setPictureList( sortedList( response.data.data ) );
        setSectorsList(sortedList(response.data.data));
      })
      .catch((error) => {
        // console.log( error.response.status );
        if (error && error.response.status === 401) {
          navigate("/user/login", true);
        }
      })
      .finally(() => {
        // setLoadedCompanies( true );
      });
  };

  const filterPicsByMetrics = useCallback(
    (metrics) => {
      if (metrics.length > 0) {
        let _filtered = pictureList.filter((pic) => {
          return metrics.includes(pic.tags.metric);
        });
        setFilteredPictureList(_filtered);
      } else {
        setFilteredPictureList(pictureList);
      }
    },
    [pictureList]
  );

  const handleRemoveMetric = (metric) => {
    // let _prevMetricsFilter = filterMetricsList;/
    // _prevMetricsFilter = _prevMetricsFilter.filter((item) => item !== metric);
    // setFilterMetricsList(_prevMetricsFilter);

    const filteredMetricList = filterMetricsList.filter(
      (item) => item !== metric
    );

    setFilterMetricsList(filteredMetricList);
    filterPicsByMetrics(filteredMetricList);

    // const filteredProducts = pictureList.filter(
    //   (p) =>
    //     !!p.tags.metric &&
    //     p.tags.metric !== "product" &&
    //     filteredMetricList.includes(p.tags.metric.trim())
    // );

    // console.log("filteredProducts:2 ", filteredProducts);
    // setFilteredPictureList(filteredProducts);
  };

  const InputItem = ({ metric, seeAll, metricCat }) => {
    // const [ checked, setChecked ] = useState( false );
    const [checked, setChecked] = useState(
      filterMetricsList.map((el) => el).includes(metric)
    );

    const handleCheckOptionMetric = (metric) => {
      setChecked(!checked);
      console.log(seeAll, metricCat, metric);
      let _prevMetricsFilter = filterMetricsList;
      if (
        !_prevMetricsFilter.some((item) => item === metric) &&
        metric !== "See All"
      ) {
        _prevMetricsFilter.push(metric);
        // console.log('+')
        setFilterMetricsList(_prevMetricsFilter);
      } else {
        // console.log('-')
        _prevMetricsFilter = _prevMetricsFilter.filter(
          (item) => item !== metric
        );
        setFilterMetricsList(_prevMetricsFilter);
      }

      //   console.log(filterMetricsList);

      // let _filteredList = pictureList.filter(
      //   (pic) => pic.tags.metric !== undefined && pic.tags.metric !== "product"
      // );

      const filteredProducts = pictureList.filter(
        (p) =>
          !!p.tags.metric &&
          p.tags.metric !== "product" &&
          filterMetricsList.includes(p.tags.metric.trim())
      );

      console.log("filteredProducts: ", filteredProducts);
      setFilteredPictureList(filteredProducts);
    };

    useEffect(() => {
      if (metric !== "See All") {
        setChecked(filterMetricsList.map((el) => el).includes(metric));
      }
    }, [filterMetricsList]);

    return (
      <>
        <input
          className="form-check-input"
          type="checkbox"
          checked={checked}
          disabled={
            filterMetricsList.length >= metricsLimit &&
            !filterMetricsList.map((el) => el).includes(metric)
          }
          onChange={() => handleCheckOptionMetric(metric)}
          id={`checkbox-${metricCat}-${metric}`}
          name={`checkbox-${metricCat}-${metric}`}
          value={metric}
        />
        <label
          className="form-check-label"
          htmlFor={`checkbox-${metricCat}-${metric}`}
        >
          {metric}
        </label>
      </>
    );
  };

  const MetricsList = ({ metricCat }) => {
    let _maxLength = 0;
    metricOptions(metricCat.id, metricsList).map(
      (metric) =>
        (_maxLength =
          _maxLength > metric.value.length ? _maxLength : metric.value.length)
    );
    return (
      <ListMetricsByCategory
        style={{
          width: `${_maxLength * 7 + 70}px`,
        }}
        className={
          metricCat.name === filterMetricsByCategory
            ? "position-absolute"
            : "d-none"
        }
      >
        {metricOptions(metricCat.id, metricsList).map((metric) => (
          <div
            className="d-flex align-items-center py-2 w-100"
            key={"metric-option-" + metric.value}
          >
            <InputItem metric={metric.value} metricCat={metricCat.name} />
          </div>
        ))}
      </ListMetricsByCategory>
    );
  };

  // const handleByMetricFiltering = () => {
  //   console.log(filterMetricsList);

  //   let _filteredList = pictureList.filter(
  //     (pic) => pic.tags.metric !== undefined && pic.tags.metric !== "product"
  //   );

  //   const filteredProducts = _filteredList.filter((product) =>
  //     filterMetricsList.includes(product.tags.metric.trim())
  //   );

  //   setFilteredPictureList(filteredProducts);
  // };

  const SortDropdownOption = ({ name }) => {
    return (
      <SortDropdownOptionStyle
        className={sortBy === name && "bg-gray-200"}
        onClick={() => handleChangeSorting(name)}
      >
        {name}{" "}
        {sortBy === name ? (
          <i
            className={
              order === "asc"
                ? "fa fa-caret-up gray-active"
                : "fa fa-caret-down gray-active"
            }
          ></i>
        ) : (
          <i className="fa fa-sort gray-inactive" />
        )}
      </SortDropdownOptionStyle>
    );
  };

  const handleChangeSorting = (by) => {
    if (sortBy === by) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setSortBy(sortBy === "Alphabetically" ? "Date" : "Alphabetically");
    }
  };

  const SearchedMetricsList = () => {
    return (
      filterMetricsList.length > 0 && (
        <SearchedCompanyList>
          {filterMetricsList.map((metric) => (
            <SearchedItem
              key={"metric-" + metric}
              style={{
                backgroundColor:
                  colorVariantsCompany[filterMetricsList.indexOf(metric)],
              }}
            >
              <span>{metric}</span>
              <Link
                to="#"
                className="remove-item"
                onClick={(e) => handleRemoveMetric(metric)}
              />
            </SearchedItem>
          ))}
        </SearchedCompanyList>
      )
    );
  };

  const handleClearByMetricFilter = () => {
    setFilterMetricsList([]);
    setFilteredPictureList(pictureList);
  };

  return (
    <div className="media-library">
      {showModal && <ModalImage imageObject={imageObject} logo={modalLogo} />}
      <FilterByComponent>
        <div className="link-button">
          <Link
            to="#"
            className={filterBy === "company" ? "active" : ""}
            onClick={() => handleFilterBy("company")}
          >
            By Company
          </Link>
          <Link
            to="#"
            className={filterBy === "metric" ? "active" : ""}
            onClick={() => handleFilterBy("metric")}
          >
            By Metric
          </Link>
        </div>

        <div
          className={filterBy === "company" ? "active pt-4 d-flex" : "d-none"}
        >
          <div className="w-50 position-relative">
            <h4 className="fw-400">Search Companies</h4>
            <p>
              Search and add <span>up to 10 companies</span> to view Market
              Insights
            </p>

            <div className="w-100 position-relative">
              <i
                className="fa fa-search position-absolute fs-4 color-secondary"
                style={{
                  paddingTop: "11px",
                  paddingLeft: "11px",
                }}
              ></i>
              <input
                type="text"
                value={searchCompany}
                disabled={
                  searchCompanyArray.length >= companiesLimit || loadingImages
                }
                onChange={handleSearchCompany}
                name="search-company"
                className="form-control"
                style={{
                  paddingLeft: "35px",
                }}
                placeholder="Search"
              />
              {searchCompany && (
                <i
                  onClick={() => setSearchCompany("")}
                  className="fa fa-times-circle clear-field-button"
                ></i>
              )}
            </div>
            {searchCompany && found && (
              <CompaniesBySectorContainer
                className={
                  found.length > 10
                    ? "position-absolute overflow-auto h-350px w-100"
                    : "position-absolute overflow-auto w-100"
                }
                style={{
                  backgroundColor: "#fff",
                  zIndex: "11",
                  border: "1px solid var(--app-component-border-color)",
                }}
              >
                <div
                  className="overlay position-fixed w-100 h-100 top-0"
                  style={{
                    left: "0",
                    zIndex: "-1",
                  }}
                  onClick={() => setSearchCompany("")}
                ></div>
                {found.map((comp, index) => {
                  if (index < 300)
                    return (
                      <div className="form-check mb-2" key={"input-" + comp.id}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={searchCompanyArray
                            .map((el) => el.name)
                            .includes(comp.name)}
                          disabled={
                            searchCompanyArray.length >= companiesLimit &&
                            !searchCompanyArray
                              .map((el) => el.name)
                              .includes(comp.name)
                          }
                          onChange={handleCheckOption}
                          id={`checkbox-search-${comp.id}`}
                          name={comp.name}
                          data-logo={comp.logo600FileName || ""}
                          data-refid={comp.refId}
                          data-color={comp.color}
                          data-value={comp.name}
                          value={comp.id}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`checkbox-search-${comp.id}`}
                        >
                          {comp.name}
                        </label>
                      </div>
                    );
                })}
              </CompaniesBySectorContainer>
            )}
            <div className="pt-3 text-right w-100 d-flex justify-content-end">
              <p className="fs-5 fw-300">
                Don’t see a company?{" "}
                <Link
                  to="#"
                  onClick={() => setShowNewCompanyModal(true)}
                  className="color-purple text-link"
                >
                  Request New Company
                </Link>
              </p>
            </div>
          </div>
          <div className="w-45px"></div>
          <div className="w-50 position-relative">
            <h4 className="fw-400">Search Companies by Sector</h4>
            <p>
              Search companies by sector. <span>Add up to 10</span> to view
              Market Insights
            </p>
            <div className="w-100 position-relative">
              <input
                type="text"
                name="dropdown-sector"
                value={filterCompaniesBySector}
                disabled={loadingImages}
                onClick={handleSearchBySector}
                onChange={(e) => handleChangeFilterCompanyBySector(e)}
                className="form-control"
                placeholder="Sector"
              />
              <i
                className="fa fa-chevron-down clear-field-button"
                onClick={handleSearchBySector}
              />
              {filterCompaniesBySector && (
                <i
                  className="fa fa-times-circle clear-field-button"
                  style={{
                    right: "-42px",
                  }}
                  onClick={() => {
                    setFilterCompaniesBySector("");
                    setFoundBySector(sectorsList);
                  }}
                ></i>
              )}
            </div>
            <div className="d-flex justify-content-end w-100 pt-3">
              <Link
                to="#"
                className="buttn-green-outline"
                onClick={handleClearAll}
              >
                Clear All
              </Link>
            </div>
            {showSectorsList && (
              <CompaniesBySectorContainer className="border-1 w-100 position-absolute">
                <div
                  className="overlay position-fixed w-100 h-100 top-0"
                  style={{
                    left: "0",
                    zIndex: "-1",
                  }}
                  onClick={() => setShowSectorsList(false)}
                ></div>
                <div className="d-flex justify-content-end">
                  <LogosToggle>
                    <div className="form-check-reverse form-switch mx-3">
                      <input
                        className="form-check-input fs-18px"
                        type="checkbox"
                        name="flexSwitchCheckDefault"
                        id="flexSwitchCheckDefault"
                        defaultChecked={showLogos}
                        value={showLogos}
                        onChange={() => setShowLogo(!showLogos)}
                      />
                      <label
                        className="form-check-label fz-12px lh-20"
                        htmlFor="flexSwitchCheckDefault"
                      >
                        View Logos
                      </label>
                    </div>
                  </LogosToggle>
                </div>
                <hr />
                {foundBySector.map((sector) => (
                  <div className="d-flex flex-row flex-wrap align-items-center pb-3">
                    <div
                      className="d-flex justify-content-between align-items-center w-100 cursor-pointer"
                      onClick={() => handleCollapseSector(sector.name)}
                    >
                      <div className="d-flex align-items-center">
                        <SectorIcon sector={sector.name} />
                        <h5 className="fw-400 m-0 mx-1">
                          {sector.name}{" "}
                          {sector.companies.length > 1 ? (
                            `(${sector.companies.length})`
                          ) : (
                            <></>
                          )}
                        </h5>
                      </div>
                      <div className="cursor-pointer p-1">
                        <i
                          className={
                            sectorCollapse === sector.name
                              ? "fa fa-chevron-down"
                              : "fa fa-chevron-right"
                          }
                        ></i>
                      </div>
                    </div>
                    {sectorCollapse === sector.name && (
                      <ListCompaniesBySector className="d-flex flex-wrap">
                        {sector.companies.map((comp) => (
                          <div className="d-flex align-items-center py-2 w-100">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={searchCompanyArray
                                .map((el) => el.name)
                                .includes(comp.name)}
                              disabled={
                                searchCompanyArray.length >= companiesLimit &&
                                !searchCompanyArray
                                  .map((el) => el.name)
                                  .includes(comp.name)
                              }
                              onChange={handleCheckOption}
                              id={`checkbox-sector-${comp.id}`}
                              name={comp.name}
                              data-logo={comp.logo600FileName || ""}
                              data-color={comp.color}
                              data-refid={comp.refId}
                              data-value={comp.name}
                              value={comp.id}
                            />
                            {comp.logo600FileName && showLogos && (
                              <LogoWrapperSmall>
                                <LoadingImage
                                  src={
                                    baseLogoURL +
                                    comp.logo600FileName +
                                    signature
                                  }
                                  alt={comp.name}
                                  variant="small"
                                />
                              </LogoWrapperSmall>
                            )}
                            <label
                              className="form-check-label"
                              htmlFor={`checkbox-sector-${comp.id}`}
                            >
                              {comp.name}
                            </label>
                          </div>
                        ))}
                      </ListCompaniesBySector>
                    )}
                  </div>
                ))}
              </CompaniesBySectorContainer>
            )}
          </div>
        </div>

        <div className={filterBy === "metric" ? "active pt-4" : "d-none"}>
          <div className="w-100 position-relative">
            {filterMetricsByCategory && (
              <div
                className="overlay position-fixed w-100 h-100 top-0 sdsd"
                style={{
                  left: "0",
                  // zIndex: "-1"
                }}
                onClick={() => setFilterMetricsByCategory("")}
              ></div>
            )}
            <h4 className="fw-400">Choose Metrics</h4>
            <p>
              Select <span>up to 10 Metrics</span> in the drop down below for
              Market Insights
            </p>
            <div
              className="d-flex justify-content-between"
              style={{
                marginLeft: "-10px",
                marginRight: "-10px",
              }}
            >
              {metricsList.categories &&
                metricsList.categories.map((metricCat) => (
                  <div
                    className="position-relative w-100 mx-2"
                    key={"input-" + metricCat.name}
                  >
                    <div className="w-100 position-relative">
                      <input
                        type="text"
                        name="metric-by-category"
                        // value={filterCompaniesBySector}
                        onClick={() => handleMetricByCategory(metricCat.name)}
                        // onChange={( e ) => handleChangeFilterCompanyBySector( e )}
                        className="form-control pr-30px"
                        placeholder={metricCat.name}
                      />
                      <i
                        className="fa fa-chevron-down clear-field-button"
                        onClick={() => handleMetricByCategory(metricCat.name)}
                      />
                    </div>
                    <MetricsList
                      key={"list-category-" + metricCat.name}
                      metricCat={metricCat}
                    />
                  </div>
                ))}
            </div>
            <div className="d-flex justify-content-end mt-3">
              {/* <Link
                to="#"
                className="buttn-green mx-3"
                onClick={handleByMetricFiltering}
              >
                Submit
              </Link> */}
              <Link
                to="#"
                className="buttn-green-outline"
                onClick={handleClearByMetricFilter}
              >
                Clear All
              </Link>
            </div>
          </div>
        </div>
      </FilterByComponent>
      <ItemsGridWrapper>
        <div className="w-100 d-flex justify-content-end position-relative py-2 my-3">
          <Link
            to="#"
            className="fs-14px color-secondary text-decoration-none"
            onClick={() => setSortByDropdown(!sortByDropdown)}
          >
            {sortBy ? "Sort" : "Sort by"} {sortBy}
            <i className="px-1 fa fa-chevron-down"></i>
          </Link>
          {sortByDropdown && (
            <SortDropdown>
              <div
                className="overlay position-fixed w-100 h-100 top-0"
                style={{
                  left: "0",
                  zIndex: "-1",
                }}
                onClick={() => setSortByDropdown(false)}
              ></div>
              {sortOption.map((option) => (
                <SortDropdownOption key={"sort-" + option} name={option} />
              ))}
            </SortDropdown>
          )}
        </div>
        {searchCompanyArray.length > 0 && (
          <SearchedCompanyList>
            {searchCompanyArray.map((comp) => (
              <SearchedItem
                key={comp.name + comp.logo}
                style={{
                  backgroundColor:
                    comp.color !== undefined && comp.color !== null
                      ? lightenVeryDarkColor(comp.color, 0.7)
                      : colorVariantsCompany[
                          findIndexByValue(searchCompanyArray, comp.name)
                        ],
                }}
              >
                <img
                  src={baseLogoURL + comp.logo + signature}
                  style={{
                    width: "auto",
                    maxHeight: "30px",
                    maxWidth: "100px",
                    marginRight: "15px",
                  }}
                  alt=""
                />
                <span>{comp.name}</span>
                <Link
                  to="#"
                  className="remove-item"
                  onClick={() => handleRemoveSearch(comp.id)}
                />
              </SearchedItem>
            ))}
          </SearchedCompanyList>
        )}

        <SearchedMetricsList />

        {loadingImages && (
          <div className="d-flex justify-content-center align-items-center center-block h-500px">
            <div className="spinner-border m-5" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {((filterBy === "company" &&
          !loadingImages &&
          !filteredPictureList.map((el) => el.tags.metric === "product")
            .length) ||
          (filterBy === "metric" &&
            !loadingImages &&
            !filteredPictureList.length)) && (
          <div className="w-100 center-block">
            <h3 className="text-center fw-400">No Results</h3>
          </div>
        )}
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {filteredPictureList.length > 0 &&
            !loadingImages &&
            filteredPictureList.map((pic, i) => {
              let logoImg = [];
              logoImg = companiesList.find(
                (comp) => comp.refId === pic.tags.company
              );
              // console.log( (logoImg !== undefined && logoImg.color !== undefined) ?? '#fff')
              //pic.tags.metric !== undefined
              return (
                ((!showImageWOMetric &&
                  pic.tags.metric &&
                  pic.tags.metric !== "product") ||
                  (i < 50 && showImageWOMetric)) &&
                logoImg && (
                  <ItemGrid
                    className="align-items-center"
                    key={pic.name}
                    style={{
                      // backgroundColor: (logoImg !== undefined && logoImg.color !== null) ? logoImg.color : 'rgba(177, 216, 211, 1)',
                      backgroundColor:
                        logoImg !== undefined && logoImg.color !== null
                          ? lightenVeryDarkColor(logoImg.color, 0.7)
                          : findIndexByValue(
                              searchCompanyArray,
                              logoImg.name
                            ) >= 0 &&
                            logoImg !== undefined &&
                            logoImg.name
                          ? colorVariantsCompany[
                              findIndexByValue(searchCompanyArray, logoImg.name)
                            ]
                          : "rgba(177, 216, 211, 1)",
                    }}
                  >
                    <MetricTitle
                      key={i + pic.tags.company}
                      style={{
                        backgroundColor: "rgba(255,255,255, .4)",
                      }}
                    >
                      <div>
                        {logoImg !== undefined &&
                          logoImg.logo600FileName !== null &&
                          logoImg.logo600FileName !== undefined && (
                            <img
                              src={
                                baseLogoURL +
                                logoImg.logo600FileName +
                                signature
                              }
                              style={{
                                width: "auto",
                                "max-height": "50px",
                                "max-width": "120px",
                              }}
                              alt={pic.name}
                            />
                          )}
                      </div>
                      <div>
                        <h4 className="fw-400">
                          {pic.tags.metric || "Metric Name"}
                        </h4>
                        <p>
                          {pic.tags.source} - {pic.tags.date}
                        </p>
                      </div>
                    </MetricTitle>
                    <ImageContainer
                      onClick={() => handlePutImageToModal(pic, logoImg)}
                    >
                      <LoadingImage
                        src={pic.uri}
                        style={{ width: "100%" }}
                        alt=""
                      />
                    </ImageContainer>
                  </ItemGrid>
                )
              );
            })}
          <div className="col-6 d-flex align-items-center">
            <div className="input-group d-flex align-items-center w-100"></div>
          </div>
        </Masonry>
      </ItemsGridWrapper>
    </div>
  );
};

export default MarketInsights;
