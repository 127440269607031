import React from "react";

const CircleTimeSeriesIcon = ({ color, size }) => {
  let _size = 109;
  let fillColor = "#B668AE";
  if (color) {
    fillColor = color;
  }
  if (size) _size = size;

  return (
    <svg
      width={_size}
      height={_size}
      viewBox="0 0 109 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="107.165"
        height="106"
        rx="53"
        fill="white"
        stroke="#D8D2FB"
        stroke-opacity="0.4"
      />
      <path
        d="M52.3958 71.6175L52.4688 57.5179L56.7707 57.5176V71.6175H52.3958ZM64.0625 71.6175V54.6559H68.4374V71.6175H64.0625ZM75.7292 71.6175V47.4179H80.104V71.6175H75.7292ZM33 53.7538V48.9647L48.7499 35.499L60.4166 45.536L80.104 28.5986V33.9017L60.4166 50.839L48.7499 40.802L33 53.7538Z"
        fill="#813579"
      />
      <path
        d="M28.3333 28.333V77.333L80.25 77.1718"
        stroke="#813579"
        stroke-width="2"
      />
    </svg>
  );
};

export default CircleTimeSeriesIcon;
