import styled from "styled-components";

export const CompanyMainCardContainer = styled.div`
  background: rgba(129, 53, 121, 0.06);
  padding: 20px 30px;
  padding-bottom: 0;
  border-radius: 16px;
`;

export const FilterByComponent = styled.div`
  border-radius: 20px;
  border: 1px solid #eaecf0;
  background-color: #fff;
  margin-bottom: 30px;
  box-shadow: 0 1px 8px -0.5px rgba(0, 0, 0, 0.14);
  font-size: 14px;
  overflow: hidden;

  p {
    color: rgba(101, 106, 107, 1);

    span {
      color: rgba(30, 30, 30, 1);
    }
  }

  .link-button {
    padding: 0 0 13px 0;
    display: flex;

    a {
      font-size: 20px;
      text-decoration: none;
      color: rgba(30, 30, 30, 1);
      margin-right: 40px;
      position: relative;
      transition: all 0.3s;

      &:hover {
        color: rgba(30, 30, 30, 0.7);
      }

      &:after {
        display: block;
        content: "";
        width: 100%;
        height: 3px;
        background-color: rgba(71, 143, 131, 0);
        position: relative;
        top: auto;
        bottom: -15px;
        transition: all 0.3s;
      }

      &.active {
        color: #b668ae;
      }

      &.active:after {
        background-color: #b668ae;
      }
    }
  }

  .form-control {
    font-weight: 400;
    border-radius: 10px;
    font-size: 16px;
  }

  .clear-field-button {
    right: -22px;
  }
`;

export const CompanyProfileStyle = styled.div`
  color: #1e1e1e;
  //padding-top: 25px;
  max-width: 1500px;

  h2 {
    font-size: 20px;
    font-weight: 400;
  }

  p {
    font-size: 14px;
    color: rgba(101, 106, 107, 1);

    span {
      color: rgba(30, 30, 30, 1);
    }
  }

  .base-info {
    display: flex;

    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
    }
  }

  .info-wrapper {
    display: flex;
    gap: 90px;
  }
`;

export const LogoColumn = styled.div`
  width: 100%;
  max-width: 180px;
  height: 180px;
  margin-bottom: 30px;
  margin-right: 20px;

  @media screen and (max-width: 1200px) {
    max-width: 100%;
  }
`;

export const WrapperLogo = styled.div`
  position: relative;
  overflow: hidden;
  padding: 10px;
  border: 1px solid #ebebeb;
  border-radius: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  //height: 165px;
  height: 100%;
  margin-bottom: 20px;
  text-align: center;
  background-color: white;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

export const SocialButtons = styled.div`
  display: flex;
  color: #b668ae;
  margin-right: -5px;
  margin-left: -5px;
  transition: all 0.3s;
  margin-top: 22px;

  a {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-left: 5px;
    justify-content: center;
    border: 1px solid #ebebeb;
    background-color: #fff;
    border-radius: 6px;
    color: #b668ae;
    font-size: 16px;
    text-decoration: none;
    transition: all 0.3s;

    &:hover {
      background-color: #b668ae;
      color: #fff;

      svg path {
        fill: #fff;
      }
    }
  }

  svg path {
    fill: #b668ae;
    transition: all 0.3s;
  }
`;

export const CompanyDetailColumn = styled.div`
  //width: 100%;
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  //min-width: 320px;
  //max-width: 340px;
  //margin-left: 12px;
  //margin-right: 12px;
  margin-bottom: 30px;
`;

export const CompanyDescriptionStyle = styled.div`
  // font-size: 14px;
  // padding-top: 12px;
  // color: #656A6B;
  // min-height: 7rem;
  padding-left: 30px;
  padding-bottom: 20px;
  // color: var(--Linear-Grey, #7b809a);
  // font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  h2 {
    color: var(--Linear-Dark-Blue, #344767);
    // font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 28px */
  }
`;

export const QuickStatsStyle = styled.div`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 20px;

  .title {
    font-size: 14px;
    color: #656a6b;
    margin-bottom: 4px;
  }

  .value {
    font-size: 16px;
  }

  h2 {
    color: var(--Linear-Dark-Blue, #344767);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 28px */
  }
`;

export const CompanyMetricsInfo = styled.div`
  flex: 1; /* Ensures each column takes equal width */
  gap: 20px; /* Ensures a 20px gap between each column */
  background-color: #f3f3f3; /* Example background color */
  padding: 10px; /* Example padding */
  box-sizing: border-box;
  display: flex;
  border-radius: 8px;
  //background-color: #f9f9f9;
  //border: 1px solid #EBEBEB;
  //padding: 12px 32px 18px;
  //margin-top: 30px;

  .column {
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
    flex: 1;
    padding: 16px;
    padding-top: 0;
    box-sizing: border-box;
    // width: 20%;
    //border: 1px solid red;

    .header {
      height: 64px;
      display: flex;
      align-items: center;
      line-height: 64px;
      font-size: 18px;
      font-weight: 400;
      border-bottom: 1px solid #ebebeb;

      svg {
        margin-right: 8px;
      }
    }

    .metric-row {
      padding-top: 18px;
      // padding-bottom: 18px;
      line-height: 1.5;
      // border-bottom: 1px solid #EBEBEB;

      &:nth-last-of-type(1) {
        border-bottom: none;
      }

      .title {
        font-size: 14px;
        color: #656a6b;
        margin-bottom: 4px;
      }

      .value {
        font-size: 16px;
      }
    }

    .links-row {
      padding-top: 18px;
      padding-bottom: 18px;
      line-height: 1.5;

      a {
        font-size: 14px;
        color: #b668ae;
        text-decoration: none;
        margin-bottom: 4px;
      }
    }
  }
`;

export const CompetitorsStyle = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;

  h3 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 24px;
  }

  .list {
    display: flex;
    margin-right: -8px;
    margin-left: -8px;
    flex-wrap: wrap;

    .logo-wrapper {
      overflow: hidden;
      padding: 10px 25px;
      border: 1px solid #ebebeb;
      background-color: #f9f9f9;
      border-radius: 8px;
      margin-right: 8px;
      margin-left: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(20% - 16px);
      min-width: 192px;
      height: 70px;
      margin-bottom: 16px;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }
`;
