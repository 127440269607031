import styled from "styled-components";
import { colorVariantsShort } from "../StyledComponent/mediaPages";

const Tag = styled.div`
  height: 32px;
  display: inline-block;
  line-height: 32px;
  width: auto;
  border: ${props => props.grey === true ? '1px solid #EBEBEB' : '1px solid #e3cee1'};
  border-radius: 6px;
  //background: ${props => props.grey === true ? '#F9F9F9' : 'rgba(177, 216, 211, 0.1)'};
  padding: 0 11px;
  margin-right: 10px;
  font-size: 14px;
  margin-top: 5px;
  //color: ${props => props.grey === true ? '#1E1E1E' : '#B668AE'};
  color: #1E1E1E;
  position: ${props => props.grey === true ? 'absolute' : ''};
`;

const CompanyTagsList = ( { list } ) => {
  let idx = 0;

  return <div className="position-relative mt-2">
    {list && list.length ? list.map( ( tag, i ) => {
      idx++;
      if ( idx <= 3 ) {
        return <Tag
          key={'sector-' + tag.id}
          style={{
            backgroundColor: colorVariantsShort[ list.indexOf( tag ) ]
          }}
        >{tag.name}</Tag>;
      } else {
        return <></>
      }

    } ) : <></>}
    {list && list.length > 3 ? <Tag grey>+{list.length - 3}</Tag> : <></>}
  </div>;
}

export default CompanyTagsList;
