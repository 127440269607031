import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { geoAlbersUsa, geoCentroid, geoPath } from "d3-geo";
import { feature } from "topojson-client";
import {
  APIHeaders,
  convertRegion,
  TO_ABBREVIATED,
  TO_NAME,
} from "../../components/shared/helpers";
import axios from "axios";
import { States } from "./states-10m";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  filteredCompaniesAtom,
  locationsListAtom,
  locationsLoadedListAtom,
  resultViewMarketTrackerAtom,
  searchCompanyArrayAtom,
  selectedStateAtom,
  stepOfCreationCompsAtom,
  trackerIDAtom,
} from "../../atoms/profileAtom";
import GoogleMaps from "../Maps/GoogleMaps";
import { colorPatternDefault } from "../../components/shared/Colors";
import styled from "styled-components";
import CompanyDetails from "../Maps/CompanyDetails";

const MapContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  //padding-bottom: 22px;
  overflow-y: auto;
  height: 100%;
`;

const CompanyDetailsStyles = styled.div`
  box-shadow: 0 0 8px -0.5px rgba(0, 0, 0, 0.14);
  background-color: #fff;
  margin: 8px;
  border-radius: 20px;
`;

function scaleNumber(input) {
  const clampedInput = Math.min(Math.max(input, 1), 100);

  const scaledInput = clampedInput / 100;

  const scaledValue = scaledInput * (20 - 10) + 10;
  // console.log( scaledValue )
  return Math.round(scaledValue);
}

function getNumberBasedOnInput(input) {
  if (input >= 1 && input <= 9) {
    return 3;
  } else if (input >= 10 && input <= 99) {
    return 6;
  } else if (input >= 100 && input <= 999) {
    return 9;
  } else {
    return 3;
  }
}

const MarketTracker = () => {
  const [state, setState] = useRecoilState(selectedStateAtom);
  const [locationList, setLocationList] = useRecoilState(locationsListAtom);
  const [loadedList, setLoadedList] = useRecoilState(locationsLoadedListAtom);
  const [filteredCompanies, setFilteredCompanies] = useRecoilState(
    filteredCompaniesAtom
  );
  const [stateCount, setStateCount] = useState([]);
  const [statesWithMultipleLocations, setStatesWithMultipleLocations] =
    useState([]);
  const [labelStates, setLabelStates] = useState([]);
  const searchCompanyArray = useRecoilValue(searchCompanyArrayAtom);
  const [stepOfCreationComps, setStepOfCreationComps] = useRecoilState(
    stepOfCreationCompsAtom
  );
  const resultViewMarketTracker = useRecoilValue(resultViewMarketTrackerAtom);
  const [geographies, setGeographies] = useState([]);
  const [colorsCompanies, setColorCompanies] = useState([]);
  const [hoverState, setHoverState] = useState("");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const trackerID = useRecoilValue(trackerIDAtom);

  useEffect(() => {
    let _stateCount = [];
    for (const obj of filteredCompanies) {
      // console.log(obj)
      const state = obj.state;
      const companyName = obj.company_id;

      if (!_stateCount[state]) {
        _stateCount[state] = {};
      }

      if (!_stateCount[state][companyName]) {
        _stateCount[state][companyName] = 0;
      }

      _stateCount[state][companyName]++;
    }

    // console.log( _stateCount, Object.keys( _stateCount ).length );
    setStateCount(_stateCount);

    const _statesWithMultipleLocations = [];

    for (const state in _stateCount) {
      for (const company in _stateCount[state]) {
        if (_stateCount[state][company] > 0) {
          _statesWithMultipleLocations.push(state);
          break;
        }
      }
    }
    setStatesWithMultipleLocations(_statesWithMultipleLocations);
  }, [filteredCompanies]);

  useEffect(() => {
    let _colors = [];
    filteredCompanies.map((city, i) => {
      let _color;
      if (city.company_color === undefined || city.company_color === null) {
        searchCompanyArray.map((com, index) => {
          if (
            +com.id === +city.company_id &&
            !_colors.some((el) => el.id === city.company_id)
          ) {
            _color = colorPatternDefault[index];
            _colors.push({ id: city.company_id, color: _color });
          }
        });
      } else {
        _color = city.company_color;
        if (!_colors.some((el) => el.id === city.company_id)) {
          _colors.push({ id: city.company_id, color: _color });
        }
      }
    });
    setColorCompanies(_colors);
  }, [filteredCompanies]);
  // console.log(stateCount);

  const navContainerStyle = {
    width:
      localStorage.getItem("appSidebarMinify") === "true"
        ? "calc(100vw - 120px)"
        : "calc(100vw - 240px)",
    paddingTop: "15px",
  };

  const checkIsNoLocation = (state) => {
    return state && stateCount[state];
  };

  const projection = geoAlbersUsa()
    .scale(980)
    .translate([840 / 2, 225]);

  const handleOnClickState = (e) => {
    if (e && e.detail === 2) {
      setState(e.target.attributes.name.value);
      setStepOfCreationComps(stepOfCreationComps + 1);
    }
    if (e && e.detail === 1) {
      if (e.target.attributes.name.value === state) {
        setState("");
      } else {
        setState(e.target.attributes.name.value);
      }
    }
  };

  const getLocationsList = () => {
    // let params = '&all=true';
    let params = "&includeMetrics=true";
    searchCompanyArray.map((el) => {
      return (params = params + "&companyIds[]=" + el.id);
    });

    const config = {
      method: "get",
      url: `${baseURL}/locations?${params}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios(config)
      .then((response) => {
        // console.log( response.data.data )
        setLocationList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadedList(true);
      });
  };

  useEffect(() => {
    setLoadedList(false);
  }, [searchCompanyArray]);

  useEffect(() => {
    if (!loadedList) {
      getLocationsList();
    }
  }, [trackerID, searchCompanyArray]);

  useEffect(() => {
    let _filteredCompanies = [];
    locationList.map((company) => {
      searchCompanyArray.map((comp) => {
        if (+company.company.id === +comp.id) {
          let _company = {};
          // console.log(company)
          _company["state"] = company.state;
          _company["city"] = company.city;
          _company["coordinates"] = [+company.longitude, +company.latitude];
          _company["status"] = company.status;
          _company["id"] = +company.id;
          _company["company_id"] = +company.company.id;
          _company["company_color"] = company.company.color;
          _company["company_name"] = company.company.name;
          _company["company_logo"] = company.company.logoFileName;
          _company["openingDate"] = company.openingDate;
          _company["fullAddress"] = company.fullAddress;
          _company["status"] = company.status;
          _company["name"] = company.name;
          _company["avgNumReviewsPerLocation"] =
            company.company.avgNumReviewsPerLocation;
          _company["avgRatingPerLocation"] =
            company.company.avgRatingPerLocation;
          _company["isOpeningDateComplete"] =
            company.isOpeningDateComplete ?? false;
          if (+company.longitude !== 0 && +company.latitude !== 0) {
            _filteredCompanies = [..._filteredCompanies, _company];
          }
        }
      });
    });
    setFilteredCompanies(_filteredCompanies);
  }, [locationList, searchCompanyArray]);

  useEffect(() => {
    if (filteredCompanies.length > 0) {
      setLoadedList(true);
    }
  }, [filteredCompanies]);

  useEffect(() => {
    setGeographies(feature(States, States.objects.states).features);
  }, []);

  useMemo(() => {
    if (geographies) {
      let _labels = [];
      geographies.map((el) => {
        let txt = {};
        if (
          el.properties.name !==
            "Commonwealth of the Northern Mariana Islands" &&
          el.properties.name !== "United States Virgin Islands" &&
          el.properties.name !== "Puerto Rico" &&
          el.properties.name !== "Guam" &&
          el.properties.name !== "American Samoa"
        ) {
          txt["coordinates"] = [geoCentroid(el)[0], geoCentroid(el)[1]];
          txt["name"] = el.properties.name;
          txt["x"] = projection(txt.coordinates)[0];
          txt["y"] = projection(txt.coordinates)[1];
          _labels = [..._labels, txt];
        }
      });
      setLabelStates(_labels);
    }
  }, [geographies]);

  const ddd = [
    {
      AK: { 45015: 1 },
    },
    {
      AL: { 45015: 9, 548789: 2 },
    },
    {
      AR: { 45015: 2, 548789: 1 },
    },
    {
      AZ: { 45015: 3, 548789: 4 },
    },
  ];

  if (!loadedList) {
    return (
      <div className="d-flex justify-content-center align-items-center text-center loader-map">
        <div className="spinner-border m-5" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <MapContainer className={!resultViewMarketTracker ? "mx-3" : ""}>
      {stepOfCreationComps === 3 && (
        <div className="d-flex align-items-start">
          {searchCompanyArray && filteredCompanies ? (
            <CompanyDetailsStyles>
              <CompanyDetails
                locations={filteredCompanies}
                companiesIDs={searchCompanyArray}
                state={state}
              />
            </CompanyDetailsStyles>
          ) : (
            <div className="d-flex justify-content-center align-items-center text-center">
              <div className="spinner-border m-5" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <div className="svg-map">
            <svg
              width="100%"
              viewBox="0 0 800 450"
              style={
                !trackerID
                  ? { height: "calc(100vh - 288px)" }
                  : { height: "calc(100vh - 358px)" }
              }
            >
              {labelStates && labelStates.length > 0
                ? labelStates.map((label, i) => {
                    return (
                      <text
                        className={
                          state === label.name || hoverState === label.name
                            ? "label-state active"
                            : "label-state"
                        }
                        id={`text-${i}`}
                        key={`text-${i}`}
                        x={label.x}
                        y={label.y}
                        name={label.name}
                      >
                        {label.name}
                      </text>
                    );
                  })
                : null}
              <g className="countries">
                {geographies.map((d, i) => (
                  <path
                    key={`path-${i}-`}
                    d={geoPath().projection(projection)(d)}
                    className={
                      state === d.properties.name
                        ? "state active"
                        : checkIsNoLocation(
                            convertRegion(d.properties.name, TO_ABBREVIATED)
                          )
                        ? "state"
                        : "inactive state"
                    }
                    name={d.properties.name}
                    title={d.properties.name}
                    // title={checkIsNoLocation(convertRegion(d.properties.name, TO_ABBREVIATED))}
                    fill={
                      checkIsNoLocation(
                        convertRegion(d.properties.name, TO_ABBREVIATED)
                      )
                        ? "rgba(255,255,255,0)"
                        : "#f5f3f3"
                    }
                    stroke="#828282"
                    strokeWidth={0.5}
                    onMouseEnter={() => setHoverState(d.properties.name)}
                    onMouseOut={() => setHoverState("")}
                    onClick={
                      checkIsNoLocation(
                        convertRegion(d.properties.name, TO_ABBREVIATED)
                      )
                        ? handleOnClickState
                        : null
                    }

                    // onClick={null}
                  ></path>
                ))}
              </g>
              {filteredCompanies && filteredCompanies.length > 0 ? (
                <g className="markers">
                  {filteredCompanies.map((city, i) => {
                    let _color;
                    if (
                      city.company_color === undefined ||
                      city.company_color === null
                    ) {
                      searchCompanyArray.map((com, index) => {
                        if (+com.id === +city.company_id) {
                          _color = colorPatternDefault[index];
                        }
                      });
                    } else {
                      _color = city.company_color;
                    }
                    let _cx,
                      _cy = null;
                    try {
                      _cx =
                        city.coordinates.length &&
                        projection(city.coordinates)[0];
                      _cy =
                        city.coordinates.length &&
                        projection(city.coordinates)[1];
                    } catch (e) {
                      // console.log(e);
                    }
                    if (
                      city.coordinates &&
                      city.coordinates.length > 1 &&
                      _cx !== null &&
                      _cy !== null &&
                      !statesWithMultipleLocations.some(
                        (obj) => obj === city.state
                      )
                    ) {
                      return (
                        <circle
                          key={`marker-${i}`}
                          cx={_cx}
                          cy={_cy}
                          r={5}
                          fill={_color}
                          stroke="#FFFFFF"
                          className={
                            "marker " + city.state + " " + city.company_id
                          }
                          // onClick={() => handleMarkerClick( i )}
                        />
                      );
                    }
                  })}
                </g>
              ) : null}
              {stateCount && Object.keys(stateCount).length > 0
                ? Object.entries(stateCount).map(([st, val]) => {
                    // console.log(st, val);
                    if (statesWithMultipleLocations.some((obj) => obj === st)) {
                      let _state = convertRegion(st, TO_NAME);
                      let _counts = Object.keys(val).length;
                      // console.log( val, _state, _counts, st );
                      // console.log(statesWithMultipleLocations)

                      return Object.entries(val).map(([key, value], index) => {
                        let coord = labelStates.filter(
                          (label) => label.name === _state
                        );
                        let _cx;
                        if (_counts === 2) {
                          if (index === 0) {
                            _cx = coord[0]?.x - scaleNumber(value);
                          } else {
                            _cx = coord[0]?.x + scaleNumber(value);
                          }
                        } else if (_counts === 3) {
                          if (index === 0) {
                            _cx = coord[0]?.x - scaleNumber(value) - 22;
                          } else if (index === 1) {
                            _cx = coord[0]?.x - scaleNumber(value);
                          } else {
                            _cx = coord[0]?.x + scaleNumber(value);
                          }
                        } else if (_counts === 4) {
                          if (index === 0) {
                            _cx = coord[0]?.x - scaleNumber(value) - 42;
                          } else if (index === 1) {
                            _cx = coord[0]?.x - scaleNumber(value) - 22;
                          } else if (index === 2) {
                            _cx = coord[0]?.x - scaleNumber(value);
                          } else {
                            _cx = coord[0]?.x + scaleNumber(value);
                          }
                        } else {
                          _cx = coord[0]?.x;
                        } //@TODO need to implement some better way for calculation position
                        let _cy = coord[0]?.y;
                        let _color;
                        _color = colorsCompanies.filter(
                          (color) => +color.id === +key
                        )[0].color;
                        // console.log( _state, _cy, _cx, _color );
                        // console.log(`${index}: ${key} = ${value}`);
                        return (
                          _cx && (
                            <>
                              <circle
                                // className={(state === label.name || hoverState === label.name) ? "label-state active" : "label-state"}
                                id={`bubble-company-${index}`}
                                key={`bubble-company-${index}`}
                                cx={_cx}
                                cy={_cy + 13}
                                fill="white"
                                stroke={_color}
                                strokeWidth={scaleNumber(value)}
                                r={scaleNumber(value) - scaleNumber(value) / 2}
                              />
                              <text
                                x={_cx - getNumberBasedOnInput(value)}
                                y={_cy + 16}
                                fontSize="11"
                                fontWeight="300"
                                fill="white"
                                stroke="white"
                                color="white"
                              >
                                {value}
                              </text>
                            </>
                          )
                        );
                      });
                    }
                  })
                : null}
            </svg>
          </div>
        </div>
      )}
      {stepOfCreationComps === 4 && <GoogleMaps />}

      <div className="d-flex justify-content-end py-3">
        {((!resultViewMarketTracker && stepOfCreationComps === 3) ||
          stepOfCreationComps === 4) && (
          <Link
            to=""
            className="fs-16px btn w-200px btn-outline-green2 text-uppercase"
            onClick={() => setStepOfCreationComps(stepOfCreationComps - 1)}
          >
            {stepOfCreationComps === 4 ? "Back to US view " : "Back"}
          </Link>
        )}

        {stepOfCreationComps === 3 && (
          <>
            <div className="w-20px"></div>
            <Link
              to=""
              className={
                stepOfCreationComps === 4
                  ? "fs-16px btn w-150px text-light btn-primary text-uppercase disabled"
                  : "fs-16px btn w-150px text-light btn-primary text-uppercase"
              }
              onClick={() => setStepOfCreationComps(stepOfCreationComps + 1)}
            >
              Next
            </Link>
          </>
        )}
      </div>
    </MapContainer>
  );
};

export default MarketTracker;
