const CustomizedLabel = (compMetric, position) => {
  console.log(compMetric);
  if (!compMetric[0]) return {};
  let _name;
  let _position = 'center';
  let _angle;
  let dx = 0;
  compMetric.map((metric) => {
    if (position === "left") {
      _name = metric.metric_name;
      _angle = -90;
      dx = -35;
    } else if (position === "right") {
      _name = metric.metric_name;
      _position = "center";
      _angle = 90;
      dx = 20;
    }
  });
  return { value: _name, angle: _angle, position: _position, dx };
};

export default CustomizedLabel;
