import React, { useEffect, useContext, useState, useCallback } from "react";
import { AppSettings } from "../../config/app-settings";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { APIHeaders, kitcut } from "../../components/shared/helpers";
import TableIcon from "../../components/shared/TableIcon";
import ScatterPlotIcon from "../../components/shared/ScatterPlotIcon";
import TimeSeriesIcon from "../../components/shared/TimeSeriesIcon";
import SweetAlert from "sweetalert-react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  chosenMetricsAtom,
  CSMetricsAttributesAtom,
  inProgressNewCustomCompAtom,
  newCustomCompNameAtom,
  newCustomCompTypeAtom,
  roleAtom,
  searchCompanyArrayAtom,
  selectedSectorsAtom,
  stepOfCreationCompsAtom,
  timeframeSelectedLabelAtom,
} from "../../atoms/profileAtom";
import axios from "axios";
import ListIcon from "../../components/shared/ListIcon";
import {
  AbsoluteCenter,
  IconAndLabel,
  IconSingle,
} from "../market-tracker/Dashboard";
import Taglist from "./Taglist";
import Select from "react-select";
import { stylesSelectWide } from "../Companies";
import PerformanceMapIcon from "../../components/shared/PerfomanceMapIcon";
import TimeLineIcon from "../../components/shared/TimeLineIcon";
import MediaIcon from "../../components/shared/MediaIcon";
import CustomCompIcon from "../../components/shared/CustomCompIcon";
import empty from "./comps-empty-icon.png";
import OverflowMenu from "../../components/shared/OverflowMenu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import CarouselModal from "../../components/modals/MediaModal";

const API_URL = process.env.REACT_APP_API_URL;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 26px;
`;

export const CompsItem = styled.div`
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  min-width: 320px;
  width: 100%;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: 0.3s;
  overflow: hidden;

  // @media screen and (max-width: 2560px) {
  //   width: calc(20% - 20px);
  // }

  // @media screen and (max-width: 1800px) {
  //   width: calc(20% - 20px);
  // }

  // @media screen and (max-width: 17700px) {
  //   width: calc(25% - 20px);
  // }

  // @media screen and (max-width: 1550px) {
  //   width: calc(33% - 20px);
  // }

  // @media screen and (max-width: 1460px) {
  //   min-width: 280px;
  //   width: calc(33% - 20px);
  // }

  // @media screen and (max-width: 1300px) {
  //   width: calc(50% - 20px);
  // }

  // @media screen and (max-width: 1024px) {
  //   min-width: 280px;
  //   width: calc(50% - 20px);
  // }

  &:hover {
    box-shadow: 0 0 10px rgba(130, 130, 130, 0.4);
  }

  .tile-content {
    margin-top: 10px;
    border-radius: 20px;
    background-color: #fff;
    padding: 10px;
  }

  .header {
    width: 100%;
    padding: 12px;
    //max-height: 72px;
    border-radius: 20px;
    cursor: pointer;
    background: #fff;
    border-bottom: 1px solid #e2e2e2;

    h4 {
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      max-height: 1.5rem;
      margin: 0;
      white-space: nowrap; /* Prevent text from wrapping */
      overflow: hidden; /* Hide the overflow text */
      text-overflow: ellipsis; /* Show ellipsis (...) */
      max-width: 200px; /* Set the max width of the title */
      cursor: pointer; /* Indicate that the element is interactive */
      position: relative; /* Needed for absolute positioning of tooltip */
    }

    h4::after {
      content: attr(
        title
      ); /* Use the title attribute for the tooltip content */
      position: absolute;
      left: 0;
      bottom: -1.5em; /* Adjust the position as needed */
      color: #fff;
      padding: 4px 8px;
      border-radius: 4px;
      white-space: nowrap;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s ease-in-out;
      z-index: 1;
    }

    h4:hover::after {
      opacity: 1;
      visibility: visible;
    }

    .type-comps,
    .date {
      font-size: 14px;
      line-height: 20px;
      color: #333333;
    }

    .type-comps {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 34px;
      width: 34px;
      margin-right: 16px;
      border-radius: 70px;
      background: var(--Secondary-Primary-White, #fff);
      color: #b668ae;
    }
  }

  .icons {
    display: flex;
    padding: 8px;
    flex-wrap: wrap;
    justify-content: start;
    overflow: hidden;

    img {
      width: 100%;
      //margin: 5px 10px;
      //max-width: 100px;
      //max-height: 40px;
    }
  }
`;

const DeleteComps = styled.div`
  position: absolute;
  top: auto;
  bottom: 6px;
  left: auto;
  right: 10px;
  cursor: pointer;
  font-size: 16px;
  color: #828282;
  transition: all 0.3s;
  z-index: 20;

  i {
    &:hover {
      color: red;
    }
  }
`;

const UserInfo = styled.div`
  font-size: 14px;
  font-weight: 300;
`;

const FooterTile = styled.div`
  padding: 2px 2px 0px 8px;
  margin-right: 30px;
`;

const BadgeMore = styled.div`
  position: relative;
  top: 10px;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #333;
  font-weight: 400;
  font-size: 12px;
`;

const TopHeaderPanel = styled.div`
  z-index: 150;
  margin-left: -30px;
  margin-right: -26px;
  position: absolute;
  z-index: 999999;
  top: -88px;
`;

const FilterChipContainer = styled.div`
  display: flex;
  margin-right: auto;
`;

const FilterChip = styled.div`
  height: 20px;
  display: flex;
  padding: 0px 8px 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: rgba(129, 53, 121, 0.1);
  color: #813579;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  margin-right: 12px;
`;

const OverflowMenuContainer = styled.div`
  margin-left: auto;
`;

const TitleTypeComp = ({ type }) => {
  let _typeListHuman;
  let _iconType;

  if (type === "CustomComps::Table") {
    _typeListHuman = "Table";
    _iconType = <TableIcon />;
  } else if (type === "CustomComps::ScatterPlot") {
    _typeListHuman = "Scatter Plot";
    _iconType = <ScatterPlotIcon />;
  } else if (type === "CustomComps::TimeSeries") {
    _typeListHuman = "Time Series";
    _iconType = <TimeSeriesIcon size={20} />;
  } else if (type === "CustomComps::CompanyList") {
    _typeListHuman = "Company Fundraising History";
    _iconType = <ListIcon />;
  } else if (type === "CustomComps::SectorList") {
    _typeListHuman = "Sector Investment List";
    _iconType = <ListIcon />;
  } else if (type === "CustomComps::StoreList") {
    _typeListHuman = "Store List";
    _iconType = <ListIcon />;
  } else if (type === "CustomComps::InvestorList") {
    _typeListHuman = "Firm Retail Investments";
    _iconType = <ListIcon />;
  }
  if (type === "CustomComps::MarketTrackerMap") {
    _typeListHuman = "Market Map";
    _iconType = <PerformanceMapIcon width="25" height="20" />;
  } else if (type === "CustomComps::MarketTimeline") {
    _typeListHuman = "Entry Timeline";
    _iconType = <TimeLineIcon size="25" />;
  } else if (type === "Media") {
    _typeListHuman = "Media";
    _iconType = <MediaIcon size="25" />;
  }

  return <span className="type-comps">{_iconType}</span>;
};

const linkToComp = (id, type, popular) => {
  const route = popular ? "popular-custom-comps" : "custom-comps";
  let _type = `/${route}/list`; //chart
  if (type === "CustomComps::Table") {
    _type = `/${route}/table`;
  } else if (type === "CustomComps::ScatterPlot") {
    _type = `/${route}/scatter-plot`;
  } else if (type === "CustomComps::TimeSeries") {
    _type = `/${route}/chart`;
  } else if (type === "CustomComps::StoreList") {
    _type = `/${route}/store-list`;
  } else if (type === "CustomComps::MarketTrackerMap") {
    _type = `/${route}/map`;
  } else if (type === "CustomComps::MarketTimeline") {
    _type = `/${route}/timeline`;
  }

  return `${_type}/${id}`;
};

export const sortingList = (list) => {
  let _data = list;
  _data.sort(function (a, b) {
    if (a.id < b.id) {
      return 1;
    }
    if (a.id > b.id) {
      return -1;
    }
    return 0;
  });
  return _data;
};

export const checkIsTagged = (el, compsTagsFilter) => {
  if (compsTagsFilter.length > 0 && el.taggings.length > 0) {
    return el.taggings.some((element1) =>
      compsTagsFilter.find((element2) => element2.label === element1.label)
    );
  } else return compsTagsFilter.length <= 0;
};

const MyCompsDashboard = ({ popular }) => {
  const context = useContext(AppSettings);
  const [confirmDeleteShow, setConfirmDeleteShow] = useState(false);
  const [idComps, setIdComps] = useState("");
  const [customCompsList, setCustomCompsList] = useState([]);
  const [isLoadedList, setIsLoadedList] = useState(false);
  const [compName, setCompName] = useRecoilState(newCustomCompNameAtom);
  const [compType, setCompType] = useRecoilState(newCustomCompTypeAtom);
  const [stepOfCreationComps, setStepOfCreationComps] = useRecoilState(
    stepOfCreationCompsAtom
  );
  const [searchCompanyArray, setSearchCompanyArray] = useRecoilState(
    searchCompanyArrayAtom
  );
  const [chosenMetrics, setChosenMetrics] = useRecoilState(chosenMetricsAtom);
  const [CSMetricsAttributes, setCSMetricsAttributes] = useRecoilState(
    CSMetricsAttributesAtom
  );
  const [selectedSectors, setSelectedSectors] =
    useRecoilState(selectedSectorsAtom);
  const [timeframeSelectedLabel, setTimeframeSelectedLabel] = useRecoilState(
    timeframeSelectedLabelAtom
  );
  const [inProgressNewCustomComp, setInProgressNewCustomComp] = useRecoilState(
    inProgressNewCustomCompAtom
  );
  const [customCompsListCount, setCustomCompsListCount] = useState(0);
  const [companiesDetails, setCompaniesDetails] = useState([]);
  const [compsTagsList, setCompsTagsList] = useState([]);
  const [compsTagsFilter, setCompsTagsFilter] = useState([]);
  console.log("compsTagsFilter: ", compsTagsFilter);
  const [companiesDetailsLoaded, setCompaniesDetailsLoaded] = useState(false);

  const [selectedMediaCard, setSelectedMediaCard] = useState(null);

  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const role = useRecoilValue(roleAtom);
  const isAdmin = role === "admin";

  const getBackgroundByType = useCallback((type) => {
    const dict = {
      "CustomComps::TimeSeries":
        "linear-gradient(90deg, rgba(0, 18, 110, 0.20) 0%, rgba(0, 18, 110, 0.05) 100%)",
      "CustomComps::Table":
        "linear-gradient(90deg, rgba(110, 0, 106, 0.20) 0%, rgba(110, 0, 106, 0.05) 100%)",
      "CustomComps::ScatterPlot":
        "linear-gradient(90deg, rgba(15, 110, 0, 0.20) 0%, rgba(15, 110, 0, 0.05) 100%)",
      Media:
        "linear-gradient(90deg, rgba(15, 110, 0, 0.2) 0%, rgba(15, 110, 0, 0.052) 100%)",
    };
    console.log("dict[type]: ", dict[type]);
    return dict[type];
  }, []);

  const confirmDeleteCustomComps = (id) => {
    setConfirmDeleteShow(true);
    setIdComps(id);
  };

  const IconStar = () => {
    return <i className="fa fa-star fs-16px mt-1" title="media"></i>;
  };

  useEffect(() => {
    context.handleSetAppHeaderNone(false);
    context.handleSetAppSidebarNone(false);
    context.handleSetAppContentClass("mh-100vh-88");
    context.handleSetAppTitle(
      popular ? "Popular Custom Comps" : "My Custom Comps"
    );
    context.handleSetAppIcon(popular ? <IconStar /> : <CustomCompIcon />);
  }, [popular]);

  const getCustomCompsList = () => {
    setIsLoadedList(false);
    const config = {
      method: "get",
      url: `${baseURL}/custom_comps`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios(config)
      .then((resp) => {
        // setCustomCompsList( resp.data.data.reverse() sortingList(resp.data.data, 'id'));
        if (popular) {
          let _popList = resp.data.data.filter(
            (comp) => comp.default_comp === true
          );
          setCustomCompsList(sortingList(_popList));
          setCustomCompsListCount(_popList.length);
          console.log(_popList);
        } else {
          let _regularList = resp.data.data.filter(
            (comp) => comp.default_comp === false
          );
          setCustomCompsList(sortingList(_regularList));
          setCustomCompsListCount(_regularList.length);
        }
        setIsLoadedList(true);
        setCompsTagsFilter([]);
        // console.log(resp.data.data)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/user/login", { replace: true });
        }
      });
  };

  useEffect(() => {
    getCustomCompsList();
  }, [popular]);

  useEffect(() => {
    if (customCompsList.length > 0) {
      let _tagsList = [];
      customCompsList.map((comp) => {
        if (comp.taggings.length > 0 && !_tagsList.includes(comp.taggings)) {
          comp.taggings.map((tag) => {
            let _val = tag.label;
            if (!_tagsList.some((obj) => obj.label === tag.label)) {
              let _tag = { value: _val, label: _val };
              _tagsList.push(_tag);
            }
          });
        }
      });

      setCompsTagsList(_tagsList);
    }
  }, [customCompsList]);

  // console.log( compsTagsList )

  const deleteCustomComps = () => {
    // console.log( idComps )
    setConfirmDeleteShow(false);
    const config = {
      method: "delete",
      url: `${baseURL}/custom_comps/${idComps}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios(config)
      .then((resp) => {
        console.log(resp);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        getCustomCompsList();
      });
  };

  const getCompaniesDetails = (ids) => {
    setCompaniesDetailsLoaded(false);
    let params = "";
    ids.map((el) => {
      return (params = params + "&ids[]=" + el);
    });

    const config = {
      method: "get",
      url: `${baseURL}/companies?${params}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios(config)
      .then((resp) => {
        setCompaniesDetails(resp.data.data);
      })
      .finally(() => {
        setCompaniesDetailsLoaded(true);
      });
  };

  useEffect(() => {
    if (customCompsList.length) {
      let _resourceIDs = [];
      customCompsList.forEach((comp) => {
        comp.resource_ids.map((resource) => {
          if (!_resourceIDs.includes(resource)) {
            _resourceIDs.push(resource);
          }
        });
      });

      getCompaniesDetails(_resourceIDs);
    }
  }, [customCompsListCount]);

  const userId = localStorage.getItem("id") ?? "";
  const [lists, setLists] = useState([]);

  useEffect(() => {
    const fetchLists = async () => {
      try {
        const response = await fetch(`${API_URL}/lists/${userId}`);
        const data = await response.json();
        setLists(data);
      } catch (error) {
        console.error("Error fetching lists:", error);
      }
    };
    fetchLists();
  }, [userId]);

  const handleNewComp = (e) => {
    e.preventDefault();
    setCompName("");
    setCompType("");
    setStepOfCreationComps(1);
    setSearchCompanyArray([]);
    setChosenMetrics([]);
    setCSMetricsAttributes([]);
    setSelectedSectors([]);
    setTimeframeSelectedLabel("");
    setCompsTagsList([]);
    navigate("/new-comp-set", { replace: false });
  };

  const handleChangeTagsFilter = (e) => {
    // console.log( e, compsTagsList );
    setCompsTagsFilter(e);
  };

  if (!isLoadedList)
    return (
      <div className="d-flex justify-content-center align-items-center text-center h-500px">
        <div className="spinner-border m-5" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );

  return (
    <div className="row">
      <TopHeaderPanel className="top-header-panel">
        <div className="d-flex align-items-center justify-content-end">
          <div>
            <Link
              to="#"
              onClick={(e) => handleNewComp(e)}
              className="my-lg-2 btn bg-primary text-white fs-16px"
            >
              <i className="mx-2 fa fa-plus"></i> New Custom Comp
            </Link>
          </div>
        </div>
      </TopHeaderPanel>
      <div className="d-flex align-items-center pt-1 mb-3">
        <FilterChipContainer>
          {!!compsTagsFilter.length &&
            compsTagsFilter.map((tag) => (
              <FilterChip key={tag.value}>
                {tag.label}
                <i
                  className="fa-solid fa-xmark cursor-pointer"
                  onClick={() => {
                    setCompsTagsFilter(
                      compsTagsFilter.filter((el) => el.label !== tag.label)
                    );
                  }}
                ></i>
              </FilterChip>
            ))}
        </FilterChipContainer>
        <div className="d-flex align-items-center justify-content-end ml-auto">
          {compsTagsList.length > 0 ? (
            <div className="d-flex align-items-center">
              <div className="fs-16px w-65px">Filter by</div>
              <div className="w-25px"></div>
              <div className="position-relative">
                <Select
                  isMulti
                  // value={""}
                  options={compsTagsList}
                  // value={filterSectorText}
                  onChange={handleChangeTagsFilter}
                  styles={stylesSelectWide}
                  placeholder="Select Tags"
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Grid>
        <>
          {lists.map((el, i) => {
            el.type = "Media";
            el.name = el.title;
            return (
              <CompsItem key={el.id + "-item-" + i + el.id} className="">
                <div
                  onClick={() => setSelectedMediaCard(el)}
                  className="text-decoration-none"
                >
                  <div
                    className="header"
                    style={{
                      background: `${getBackgroundByType(el.type)}`,
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <TitleTypeComp type="Media" />
                      <div className="d-flex flex-column justify-content-between">
                        <h4 title={el.name} clasName="title">
                          {kitcut(el.name, 26)}
                        </h4>
                        <span className="date">
                          {moment(el.created_at).format("MMM DD, yyyy")}
                        </span>
                      </div>
                      <OverflowMenuContainer>
                        <OverflowMenu className="ml-3">
                          <MenuItem disabled>
                            <ListItemIcon>
                              <CreateIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Edit</ListItemText>
                          </MenuItem>
                          {!popular && (
                            <MenuItem
                              onClick={() => confirmDeleteCustomComps(el.id)}
                            >
                              <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Delete</ListItemText>
                            </MenuItem>
                          )}
                        </OverflowMenu>
                      </OverflowMenuContainer>
                    </div>
                  </div>
                </div>
                {/* <FooterTile>
                  {!!el.taggings.length ? <Taglist list={el.taggings} /> : null}
                </FooterTile> */}
                <div className="tile-content d-flex flex-column justify-content-between h-100">
                  <div className="icons">
                    {companiesDetailsLoaded || companiesDetails.length ? (
                      el.mediaUrls.map((url, i) => {
                        let moreThan6Images = el.mediaUrls.length > 6;
                        return i < 4 ? (
                          i === 3 && moreThan6Images ? (
                            <IconSingle>
                              {" "}
                              + {el.mediaUrls.length - 6}{" "}
                            </IconSingle>
                          ) : (
                            <IconSingle>
                              <img src={url} alt={url} />
                            </IconSingle>
                          )
                        ) : (
                          <></>
                        );
                      })
                    ) : (
                      <div className="d-flex justify-content-center align-items-center text-center h-200px p-3 w-100">
                        <div className="spinner-border m-5" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div className="position-relative">
              {!popular && (
                <DeleteComps
                  onClick={() => confirmDeleteCustomComps(el.id)}
                >
                  <i className="fa fa-trash"></i>
                </DeleteComps>
              )}
            </div> */}
                </div>
              </CompsItem>
            );
          })}
          {customCompsList &&
            customCompsList.map((el, i) => {
              console.log("el: ", el);
              let moreThan6Comp = false;
              let moreThan6CompCount = 0;
              if (el.resource_ids.length > 6) {
                moreThan6Comp = true;
                moreThan6CompCount = el.resource_ids.length - 6;
              }
              // console.log(el)

              if (
                compsTagsFilter.length === 0 ||
                (compsTagsFilter.length > 0 &&
                  checkIsTagged(el, compsTagsFilter))
              )
                return (
                  <CompsItem key={el.id + "-item-" + i + el.id} className="">
                    <Link
                      to={linkToComp(el.id, el.type, popular)}
                      className="text-decoration-none"
                    >
                      <div
                        className="header"
                        style={{
                          background: `${getBackgroundByType(el.type)}`,
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <TitleTypeComp type={el.type} />
                          <div className="d-flex flex-column justify-content-between">
                            <h4 title={el.name} clasName="title">
                              {kitcut(el.name, 26)}
                            </h4>
                            <span className="date">
                              {moment(el.created_at).format("MMM DD, yyyy")}
                            </span>
                          </div>
                          <OverflowMenuContainer>
                            <OverflowMenu className="ml-3">
                              <MenuItem disabled>
                                <ListItemIcon>
                                  <CreateIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Edit</ListItemText>
                              </MenuItem>
                              {!popular && (
                                <MenuItem
                                  onClick={() =>
                                    confirmDeleteCustomComps(el.id)
                                  }
                                >
                                  <ListItemIcon>
                                    <DeleteIcon fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText>Delete</ListItemText>
                                </MenuItem>
                              )}
                            </OverflowMenu>
                          </OverflowMenuContainer>
                        </div>
                      </div>
                    </Link>
                    <FooterTile>
                      {/*<UserInfo>{isAdmin && el.user_name ? <><i className="fa fa-user"></i> <span>{el.user_name}</span></> : <></>}</UserInfo>*/}
                      {!!el.taggings.length ? (
                        <Taglist list={el.taggings} />
                      ) : null}
                    </FooterTile>
                    <div className="tile-content d-flex flex-column justify-content-between h-100">
                      <div className="icons">
                        {companiesDetailsLoaded || companiesDetails.length ? (
                          el.resource_ids.map((comp, i) => {
                            return i < 4 ? (
                              i === 3 && moreThan6Comp ? (
                                <IconSingle>
                                  {" "}
                                  + {moreThan6CompCount}{" "}
                                </IconSingle>
                              ) : (
                                <IconAndLabel
                                  companiesDetails={companiesDetails}
                                  comp={comp}
                                  key={comp + i}
                                />
                              )
                            ) : (
                              <></>
                            );
                          })
                        ) : (
                          <div className="d-flex justify-content-center align-items-center text-center h-200px p-3 w-100">
                            <div className="spinner-border m-5" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* <div className="position-relative">
                      {!popular && (
                        <DeleteComps
                          onClick={() => confirmDeleteCustomComps(el.id)}
                        >
                          <i className="fa fa-trash"></i>
                        </DeleteComps>
                      )}
                    </div> */}
                    </div>
                  </CompsItem>
                );
            })}
        </>
      </Grid>
      {!customCompsList.length && (
        <AbsoluteCenter>
          <div className="d-flex flex-column w-100 justify-content-center mh-100vh-88 align-items-center text-center text-muted fs-5">
            <img src={empty} alt="empty" width="200" />
            <p className="text-center text-muted fs-2">
              No Customs Comps generated. Click "New Custom Comp" to create one.
            </p>
            <p className="text-center text-muted fs-2"></p>
          </div>
        </AbsoluteCenter>
      )}
      <SweetAlert
        show={confirmDeleteShow}
        title="Are you sure you want to delete this Custom Comp?"
        type="error"
        confirmButtonText="Delete"
        showCancelButton={true}
        onCancel={() => setConfirmDeleteShow(false)}
        confirmButtonColor="rgb(255, 91, 87)"
        onConfirm={() => deleteCustomComps()}
      />
      {selectedMediaCard && (
        <CarouselModal
          name={selectedMediaCard.title}
          images={selectedMediaCard.mediaUrls}
          isOpen={true}
          onClose={() => setSelectedMediaCard(null)}
        />
      )}
    </div>
  );
};

export default MyCompsDashboard;
