import React from "react";
import styled, { css } from "styled-components";
import SectorIcon from "./SectorIcon";

// Styled components
const Card = styled.div`
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  height: ${({ size }) => (size === "small" ? "79px" : "189px")};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  &:hover {
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.15);
  }
`;

const SectorTile = styled.div`
  display: flex;
  flex-direction: ${({ size }) =>
    size === "small" ? "row-reverse" : "column"};
  justify-content: ${({ size }) =>
    size === "small" ? "space-between" : "flex-start"};
  border-radius: 20px;
  padding: 16px;
  background-size: cover;
  height: ${({ size }) => (size === "small" ? "79px" : "157px")};
  position: relative;
  transition: all 0.2s;
  color: white;
  width: 100%;

  .icon {
    margin-left: auto;
    background: white;
    min-width: 47px;
    min-height: 47px;
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    margin-left: 12px;
  }

  // @media screen and (max-width: 1500px) {
  //   width: 100%;
  // }

  &:hover {
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.15);
  }

  h4 {
    font-weight: bold;
    font-size: ${({ size }) => (size === "small" ? "18px" : "16px")};
    margin-left: ${({ size }) => (size === "small" ? "auto" : "0")};
    line-height: normal;
    margin-bottom: 0;
  }
`;

export const SectorCard = ({
  sector,
  countCompaniesInSector,
  onClick,
  size = "default", // default size is 'default'
}) => {
  return (
    <Card className="col-xxl-4 col-xl-4 cursor-pointer" size={size}>
      <SectorTile
        key={"company-by-sector-" + sector.id}
        style={{
          backgroundImage: `url("/assets/images/${sector.name}.png"), url("/assets/images/Wellness.png")`,
        }}
        onClick={onClick}
        size={size} // pass the size prop here
      >
        <div className="icon">
          <SectorIcon sector={sector.name} size="L" />
        </div>

        <div className="d-flex align-items-center title">
          <h4
            className="cursor-pointer"
            // onClick={() => handleClickSector(sector)}
          >
            {sector.name}{" "}
            {countCompaniesInSector > 1 ? `(${countCompaniesInSector})` : ""}
          </h4>
        </div>
      </SectorTile>
    </Card>
  );
};
