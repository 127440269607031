import React, { Link, useParams } from "react-router-dom";
import { APIHeaders } from "../../components/shared/helpers";
import axios from "axios";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  filteredCompaniesAtom,
  locationsListAtom,
  searchCompanyArrayAtom,
  selectedStateAtom,
  singleCompanyIDAtom,
} from "../../atoms/profileAtom";
import GoogleMaps from "./GoogleMaps";

const MapsByCompanyId = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [searchCompanyArray, setSearchCompanyArray] = useRecoilState(
    searchCompanyArrayAtom
  );
  const [filteredCompanies, setFilteredCompanies] = useRecoilState(
    filteredCompaniesAtom
  );
  const [locationList, setLocationList] = useRecoilState(locationsListAtom);
  const [loadedCompanyDetails, setLoadedCompanyDetails] = useState(false);
  const [singleCompanyID, setSingleCompanyID] =
    useRecoilState(singleCompanyIDAtom);
  const [stateFilter, setStateFilter] = useRecoilState(selectedStateAtom);
  const { id } = useParams();
  const companyId = id || singleCompanyID;

  useEffect(() => {
    setSearchCompanyArray([]);
    setLoadedCompanyDetails(false);
    setStateFilter("");
  }, []);

  useEffect(() => {
    // console.log('id: ' + id, 'companyId: '+ companyId);
    if (id !== companyId) {
      // setCompanyID( id );
      setLoadedCompanyDetails(false);
      setSearchCompanyArray([]);
      setLocationList([]);
      setFilteredCompanies([]);
    }
  }, [id]);

  useEffect(() => {
    if (!loadedCompanyDetails && companyId) {
      getCompaniesDetails();
    }
  }, [companyId, loadedCompanyDetails]);

  const getCompaniesDetails = () => {
    let params = `&ids[]=${companyId}`;

    const config = {
      method: "get",
      url: `${baseURL}/companies?${params}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios(config).then((resp) => {
      setSearchCompanyArray([{ id: companyId, name: resp.data.data[0].name }]);
      setLoadedCompanyDetails(true);
    });
  };

  if (!loadedCompanyDetails)
    return (
      <div className="d-flex justify-content-center align-items-center text-center loader-map">
        <div className="spinner-border m-5" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );

  return (
    <div>
      {companyId && loadedCompanyDetails && searchCompanyArray.length && (
        <GoogleMaps
          width={
            localStorage.getItem("appSidebarMinify") === "true"
              ? "calc(100vw - 240px)"
              : "calc(100vw - 395px)"
          }
          height="calc(100vh - 380px)"
        />
      )}
    </div>
  );
};

export default MapsByCompanyId;
