import React from "react";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10001;
`;

const ModalContent = styled.div`
  background: white;
  padding: 24px;
  width: 300px;
  max-width: 90%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

const ModalTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 16px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  //   padding: 8px 16px;
  //   border: none;
  //   border-radius: 4px;
  //   cursor: pointer;
  //   font-size: 16px;
  //   background-color: ${(props) => (props.primary ? "#6200ea" : "#ddd")};
  //   color: ${(props) => (props.primary ? "#fff" : "#000")};
  //   width: 45%;
`;

const Modal = ({ isOpen, onClose, onSave, newListName, setNewListName }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalTitle>Create a new library</ModalTitle>
        <Input
          type="text"
          placeholder="Add name"
          value={newListName}
          onChange={(e) => setNewListName(e.target.value)}
        />
        <ButtonContainer>
          <Button
            className="fs-16px btn w-100px btn-outline-primary text-uppercase"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className="fs-16px w-100px btn btn-primary btn-outline-light"
            onClick={onSave}
            disabled={!newListName}
          >
            Create
          </Button>
        </ButtonContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;
