import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { APIHeaders, APIHeadersRAW } from "./helpers";
import axios from "axios";
import { ReactNotifications } from "react-notifications-component";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  roleAtom,
  tagsOptionsAtom,
} from "../../atoms/profileAtom";
import styled from "styled-components";

const EditTitleButton = styled.i`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  transition: all .3s;
  opacity: .8;
  
  &:hover {
    opacity: 1;
  }
`;

const TitleCustomComp = (
  {
    icon,
    compName,
    title,
    id,
    showLogo,
    isShowLogo,
    preview,
    noLocations,
    isPopular,
    ownerID,
    marketTracker,
  }
) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  let _noLocation = noLocations ?? true;
  const role = useRecoilValue( roleAtom );
  const [ headingText, setHeadingText ] = useState( compName );
  const [ changedNameComp, setChangedNameComp ] = useState( false );
  const [ savingName, setSavingName ] = useState( false );
  const isAdmin = role === 'admin';
  const inputRef = useRef( null );
  const [ inputWidth, setInputWidth ] = useState( 'auto' );

  const handleSaveName = () => {
    setSavingName( true );
    let metricData = {};
    metricData[ 'custom_comp' ] = {
      "name": headingText
    }

    const config = {
      method: 'put',
      url: `${baseURL}/custom_comps/${id}`,
      headers: APIHeadersRAW,
      withCredentials: true,
      credentials: "include",
      data: metricData,
    };
    axios( config ).finally( () => {
      setSavingName( false );
      setChangedNameComp( false );
    } )
  }

  useEffect( () => {
    if ( inputRef.current ) {
      setInputWidth( `${inputRef.current.scrollWidth + 50}px` );
    }
  }, [ inputRef ] );

  const handleInputChange = ( event ) => {
    if (event.target.value.length > 0) {
      setHeadingText( event.target.value );
      setChangedNameComp( true );
    } else if(event.target.value.length === 0) {
      setHeadingText('' );
      setChangedNameComp( false );
    }
    if (event.target.value.length > headingText.length && (window.innerWidth - 500 > inputRef.current.scrollWidth)) {
      setInputWidth( `${inputRef.current.scrollWidth + 10}px` )
    } else if(event.target.value.length === 0) {
      setInputWidth( `${inputRef.current.scrollWidth + 10}px` )
    } else if (window.innerWidth - 500 < inputRef.current.scrollWidth) {
      setInputWidth( `${window.innerWidth - 500}px` )
    } else {
      setInputWidth( `${inputRef.current.scrollWidth - 10}px` )
    }
  };

  return <>

    <div className="d-flex align-items-center justify-content-between my-1">
      <div className="d-flex align-items-center overflow-hidden">
        {id && <div className="position-relative overflow-auto">
          {isPopular && !isAdmin ? <></> : <EditTitleButton
            className={isPopular && !isAdmin ? "fa fa-edit fs-14px disabled " : "fa fa-edit fs-14px cursor-pointer"}
            onClick={() => {inputRef.current.focus();}}
          />}
          <input
            ref={inputRef}
            style={{ width: inputWidth, "paddingLeft": isPopular && !isAdmin ? "0" : "35px", "paddingRight": "10px" }}
            className="invisible-input fs-24px"
            type="text"
            disabled={isPopular && !isAdmin}
            onChange={( e ) => handleInputChange( e )}
            value={headingText}
          />
        </div>}
        <div className="w-15px"></div>
        {changedNameComp && <Link
          to="#"
          style={{ "padding": "6px 14px" }}
          className={savingName ? 'btn btn-primary fs-14px disabled' : 'btn btn-primary fs-14px'}
          onClick={handleSaveName}
        >{!savingName ? 'Save' : <div className="spinner-border spinner-border-sm" role="status">
          <span className="sr-only">Loading...</span>
        </div>}</Link>}
      </div>
      {showLogo &&
        _noLocation &&
        <div className="form-check-reverse form-switch mx-4">
          <input
            className="form-check-input fs-18px"
            type="checkbox"
            id="flexSwitchCheckDefault"
            checked={isShowLogo}
            onChange={showLogo}
          />
          <label
            className="form-check-label fz-12px lh-20"
            htmlFor="flexSwitchCheckDefault">Show Logos?</label>
        </div>}
    </div>
    <ReactNotifications />
  </>
}

export default TitleCustomComp;
