import {
	atom
} from 'recoil';

export const sessionAtom = atom( {
	key: 'session',
	default: '',
} );

export const roleAtom = atom( {
	key: 'role',
	default: '',
} );

export const userIDAtom = atom( {
	key: 'userID',
	default: '',
} );

export const organizationListAtom = atom( {
	key: 'organizationList',
	default: [],
} );

export const organizationListSelectorAtom = atom( {
	key: 'organizationListSelector',
	default: [],
} );

export const usersListAtom = atom( {
	key: 'usersList',
	default: [],
} );

export const updateUserListAtom = atom( {
	key: 'updateUserListAtom',
	default: false,
} );

export const updateCompanyListAtom = atom( {
	key: 'updateCompanyListAtom',
	default: false,
} );

export const showCreateOrgModalAtom = atom( {
	key: 'showCreateOrgModal',
	default: false,
} );

export const showEditOrgModalAtom = atom( {
	key: 'showEditOrgModal',
	default: false,
} );

export const showMediaModalAtom = atom( {
	key: 'showMediaModal',
	default: false,
} );

export const showCreateUserModalAtom = atom( {
	key: 'showCreateUserModal',
	default: false,
} );

export const showEditUserModalAtom = atom( {
	key: 'showEditUserModal',
	default: false,
} );

export const stepOfCreationCompsAtom = atom( {
	key: 'stepOfCreationComps',
	default: 1,
} );

export const newCustomCompNameAtom = atom( {
	key: 'newCustomCompName',
	default: '',
} );

export const newCustomCompTypeAtom = atom( {
	key: 'newCustomCompType',
	default: '',
} );

export const nextStepTitleAtom = atom( {
	key: 'nextStepTitle',
	default: 'Next',
} );

export const searchCompanyArrayAtom = atom( {
	key: 'searchCompanyArray',
	default: [],
} );

export const companiesListAtom = atom( {
	key: 'companiesList',
	default: [],
} );

export const companiesListInvestorAtom = atom( {
	key: 'companiesListInvestor',
	default: [],
} );

export const locationsListAtom = atom( {
	key: 'locationsList',
	default: [],
} );

export const locationsLoadedListAtom = atom( {
	key: 'locationsLoadedList',
	default: false,
} );

export const filteredCompaniesAtom = atom( {
	key: 'filteredCompanies',
	default: [],
} );

export const collapsedLegendStatisticAtom = atom( {
	key: 'collapsedLegendStatisticAtom',
	default: false,
} );

export const companiesLoadedListAtom = atom( {
	key: 'companiesLoadedList',
	default: false,
} );

export const companiesLimitAtom = atom( {
	key: 'companiesLimit',
	default: 3,
} );

export const sectorsListAtom = atom( {
	key: 'sectorsList',
	default: [],
} );

export const sectorsInvestListAtom = atom( {
	key: 'sectorsInvestList',
	default: [],
} );

export const showSelectCompaniesBySectorAtom = atom( {
	key: 'showSelectCompaniesBySector',
	default: false,
} );

export const selectedSectorAtom = atom( {
	key: 'selectedSectorAtom',
	default: 0,
} );

export const selectedInvestSectorAtom = atom( {
	key: 'selectedInvestSectorAtom',
	default: 0,
} );

export const companiesBySectorListAtom = atom( {
	key: 'companiesBySectorList',
	default: [],
} );

export const companiesByInvestListAtom = atom( {
	key: 'companiesByInvestList',
	default: [],
} );

export const selectedStateAtom = atom( {
	key: 'selectedState',
	default: '',
} );

export const trackerIDAtom = atom( {
	key: 'trackerID',
	default: '',
} );

export const showCitiesIDCompanyAtom = atom( {
	key: 'showCitiesIDCompany',
	default: '',
} );

export const minimizeLocationLegendAtom = atom( {
	key: 'minimizeLocationLegend',
	default: false,
} );

export const selectedCitiesAtom = atom( {
	key: 'selectedCitiesAtom',
	default: [],
} );

export const stepMarketTrackerAtom = atom( {
	key: 'stepMarketTracker',
	default: 1,
} );

export const resultViewMarketTrackerAtom = atom( {
	key: 'resultViewMarketTracker',
	default: false,
} );

export const inProgressNewCustomCompAtom = atom( {
	key: 'inProgressNewCustomComp',
	default: false,
} );

export const linkToMenuItemAtom = atom( {
	key: 'linkToMenuItem',
	default: '',
} );

export const inProgressNewCustomCompDialogAtom = atom( {
	key: 'inProgressNewCustomCompDialog',
	default: false,
} );

export const timeframeStartDateAtom = atom( {
	key: 'timeframeStartDate',
	default: '',
} );

export const timeframeEndDateAtom = atom( {
	key: 'timeframeEndDate',
	default: '',
} );

export const inProgressNewCustomCompTypeAtom = atom( {
	key: 'inProgressNewCustomCompType',
	default: '',
} );

export const chosenMetricsAtom = atom( {
	key: 'chosenMetrics',
	default: [],
} );

export const tagsOptionsAtom = atom( {
  key: 'tagsOptions',
  default: [],
} );

export const chosenTagsAtom = atom( {
  key: 'chosenTags',
  default: [],
} );

export const metricListAtom = atom( {
	key: 'metricListAtom',
	default: [],
} );

export const timeframeSelectedAtom = atom( {
	key: 'timeframeSelected',
	default: 120,
} );

export const timeframeSelectedLabelAtom = atom( {
	key: 'timeframeSelectedLabel',
  default: { value: 120, label: 'All Years' },
} );

export const selectedSectorsAtom = atom( {
	key: 'selectedSectors',
	default: [],
} );

export const metricLimitAtom = atom( {
	key: 'metricLimitAtom',
	default: 2,
} );

export const sectorsLimitAtom = atom( {
	key: 'sectorsLimit',
	default: 3,
} );

export const CSMetricsAttributesAtom = atom( {
	key: 'CSMetricsAttributes',
	default: [],
} );

export const customCompAtom = atom( {
	key: 'customComp',
	default: [],
} );

export const needUpdateCompAtom = atom( {
	key: 'needUpdateComp',
	default: false,
} );

export const customCompIDAtom = atom( {
	key: 'customCompID',
	default: '',
} );

export const companyListAtom = atom( {
	key: 'companyList',
	default: [],
} );

export const metricSameStartAtom = atom( {
	key: 'metricSameStart',
	default: false,
} );

export const tipsModalTitle = atom( {
	key: 'title',
	default: '',
} );

export const showModalTipsAtom = atom( {
	key: 'showModalTips',
	default: false,
} );

export const modalTipsLinkAtom = atom( {
	key: 'modalTipsLink',
	default: '',
} );

export const modalContentTipsAtom = atom( {
	key: 'modalContent',
	default: '',
} );

export const questionsListAtom = atom( {
	key: 'questionsList',
	default: [],
} );

export const addCompaniesListAtom = atom( {
	key: 'addCompaniesList',
	default: [],
} );

export const newMessagesAtom = atom( {
	key: 'newMessages',
	default: null,
} );

export const messagesLoadedAtom = atom( {
	key: 'messagesLoaded',
	default: false,
} );

export const questionsListLoadedAtom = atom( {
	key: 'questionsListLoaded',
	default: false,
} );

export const showEditMessageModalAtom = atom( {
	key: 'showEditMessageModal',
	default: false,
} );

export const showCompanyAddMessageModalAtom = atom( {
	key: 'showCompanyEditMessageModal',
	default: false,
} );

export const showEditMessageModalIDAtom = atom( {
	key: 'showEditMessageModalID',
	default: '',
} );

export const showCompEditMessageModalIDAtom = atom( {
	key: 'showCompEditMessageModalID',
	default: '',
} );

export const showNewCompanyModalAtom = atom( {
	key: 'showNewCompanyModal',
	default: false,
} );

export const showSingleCompanyAtom = atom( {
	key: 'showSingleCompany',
	default: false,
} );

export const singleCompanyIDAtom = atom( {
	key: 'singleCompanyID',
	default: '',
} );

export const collapseStateCategoryAtom = atom( {
	key: 'collapseStateCategory',
	default: [],
} );

export const newModalTitleAtom = atom( {
	key: 'newModalTitle',
	default: 'company',
} );

export const limitForTableViewAtom = atom( {
	key: 'limitForTableView',
	default: 15,
} );

export const searchFieldGoogleMapAtom = atom( {
	key: 'searchFieldGoogleMap',
	default: '',
} );


