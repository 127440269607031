import React from "react";

const CircleTableIcon = ({ color, size }) => {
  let _size = 109;
  let fillColor = "#B668AE";
  if (color) {
    fillColor = color;
  }
  if (size) _size = size;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={_size}
      height={_size}
      viewBox="0 0 109 108"
      fill="none"
    >
      <rect
        x="1"
        y="1"
        width="107.165"
        height="106"
        rx="53"
        fill="white"
        stroke="#D8D2FB"
        stroke-opacity="0.4"
      />
      <rect
        x="28.5"
        y="28.5"
        width="52"
        height="52"
        rx="2.5"
        fill="white"
        stroke="#813579"
      />
      <path d="M80.4586 63.9012H29.0524V61.418H80.3595" fill="#813579" />
      <path
        d="M80.2522 47.1316L29.0524 46.9246V44.4414L80.6651 44.6483"
        fill="#813579"
      />
      <path d="M41.1638 80.1017V29.7422H43.6412V80.5156" fill="#813579" />
      <path
        d="M32.6741 70.0098L40.0941 70.0098L40.0941 71.0693L32.6741 71.0693"
        fill="#813579"
      />
      <path
        d="M32.6741 53.0684L40.0941 53.0684L40.0941 54.1284L32.6741 54.1284"
        fill="#813579"
      />
      <path
        d="M32.6741 37.1611L40.0941 37.1611L40.0941 38.2211L32.6741 38.2211"
        fill="#813579"
      />
      <path d="M54.4138 78.8396V28L56.6506 28.3725V78.881" fill="#813579" />
      <path
        d="M66.5941 80.5818V29.7422L68.8309 30.1147V80.6232"
        fill="#813579"
      />
    </svg>
  );
};

export default CircleTableIcon;
