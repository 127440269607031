import React, { useState } from "react";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10001;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  width: 90%;
  max-width: 800px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 800px;
  height: 758px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  color: #333;
  background: none;
  border: none;
  cursor: pointer;
`;

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 1;
`;

const CarouselImage = styled.img`
  max-width: 100%;
  max-height: 500px;
  border-radius: 8px;
  margin-top: 10px;
`;

const ArrowButton = styled.button`
  font-size: 36px;
  cursor: pointer;
  color: #333;
  background: none;
  border: none;
  margin: 0 10px;

  &:hover {
    color: #555;
  }
`;

const ThumbnailsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
  overflow-x: auto;
  padding: 5px 0;
  width: 100%;
`;

const ThumbnailImage = styled.img`
  width: 80px;
  height: 60px;
  object-fit: cover;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
  border: ${(props) =>
    props.isActive ? "2px solid #333" : "2px solid transparent"};

  &:hover {
    border: 2px solid #555;
  }
`;

const Caption = styled.p`
  font-size: 14px;
  color: #666;
  margin-top: 10px;
`;

const CarouselModal = ({ isOpen, onClose, images, name }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  if (!isOpen) return null;

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </CloseButton>
        <div>
          <h2>{name}</h2>
          {/* <p>
            <strong>Facebook</strong> - Jan 15, 2024 - <a href="#">Website</a>
          </p>
          <p>Description: Lorem ipsum dolor sit amet consectetur.</p> */}
        </div>
        <CarouselContainer>
          <ArrowButton onClick={handlePrev}>
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
          </ArrowButton>
          <CarouselImage
            src={images[currentIndex]}
            alt={`Image ${currentIndex + 1}`}
          />
          <ArrowButton onClick={handleNext}>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </ArrowButton>
        </CarouselContainer>
        <Caption>
          Image {currentIndex + 1} of {images.length}
        </Caption>
        <ThumbnailsContainer>
          {images.map((image, index) => (
            <ThumbnailImage
              key={index}
              src={image}
              alt={`Thumbnail ${index + 1}`}
              isActive={index === currentIndex}
              onClick={() => handleThumbnailClick(index)}
            />
          ))}
        </ThumbnailsContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default CarouselModal;
