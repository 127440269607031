import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ZAxis,
} from "recharts";
import {
  abbrNum,
  APIHeaders,
  APIHeadersRAW,
} from "../../components/shared/helpers";
import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  chosenTagsAtom,
  CSMetricsAttributesAtom,
  customCompAtom,
  customCompIDAtom,
  inProgressNewCustomCompAtom,
  metricSameStartAtom,
  needUpdateCompAtom,
  newCustomCompNameAtom,
  newCustomCompTypeAtom,
  roleAtom,
  searchCompanyArrayAtom,
  selectedSectorsAtom,
  stepOfCreationCompsAtom,
  timeframeEndDateAtom,
  timeframeStartDateAtom,
} from "../../atoms/profileAtom";
import LegendComponent from "../../components/chart/LegendComponent";
import { colorPatternDefault } from "../../components/shared/Colors";
import styled from "styled-components";
import CommonErrorComponent from "../../components/shared/CommonErrorComponent";
import TitleCustomComp from "../../components/shared/TitleCustomComp";
import TitleTagCustomComp from "../../components/shared/TitleTagCustomComp";
import { WrapperCustomComp } from "../market-tracker/MarketTrackerTimeline";

const baseLogoURL = process.env.REACT_APP_LOGOS_BASE_URL;
const signature = process.env.REACT_APP_LOGOS_SIGNATURE;

const reMapScatterPlot = (data) => {
  let _data = {};

  if (typeof data === "object") {
    Object.keys(data).forEach((key) => {
      let _temp = {};
      for (const [ke, value] of Object.entries(data[key])) {
        let _obj = [];
        if (
          (ke === "cogs" ||
            ke === "labor" ||
            ke === "same_store_sales" ||
            ke === "fw_ebitda" ||
            ke === "ebitda_capex" ||
            ke === "ebitda" ||
            ke === "opex" ||
            ke === "occupancy" ||
            ke === "ga") &&
          value
        ) {
          _temp[ke] = (value * 100).toFixed(1);
        } else if (ke === "avg_google_reviews_location" && value) {
          let _val = +value;
          _temp[ke] = _val.toFixed(0);
        } else {
          _temp[ke] = value;
        }
        _temp = Object.assign({}, _temp);
        _temp["company_id"] = key;
        _obj[key] = [_temp];
        Object.assign(_data, _obj);
      }
    });
  }

  // console.log(JSON.stringify(_data));
  // console.log(_data)
  return _data;
};

const ScatterPlotTooltipStyle = styled.div`
  padding: 0 10px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid var(--bs-secondary);
`;

const WrapperLogo = styled.div`
  width: 150px;
  display: flex;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: auto;
    padding: 10px;
  }
`;

const ScatterPlot = ({ preview }) => {
  const { id } = useParams();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [customComp, setCustomComp] = useRecoilState(customCompAtom);
  const [compID, setCompID] = useRecoilState(customCompIDAtom);
  const [needUpdateComp, setNeedUpdateComp] =
    useRecoilState(needUpdateCompAtom);
  const [remappedData, setRemappedData] = useState({});
  const [isLoadedComp, setIsLoadedComp] = useState(false);
  const [compData, setCompData] = useState({});
  const [compMetrics, setCompMetrics] = useState({});
  const [companiesListIDs, setCompaniesListIDs] = useState({});
  const [invalidData, setInvalidData] = useState(true);
  const [companiesList, setCompaniesList] = useState([]);
  const [errorMessageAPI, setErrorMessageAPI] = useState("");
  const chosenTags = useRecoilValue(chosenTagsAtom);
  const [inProgress, setInProgress] = useState(false);
  const navigate = useNavigate();
  const [compName, setCompName] = useRecoilState(newCustomCompNameAtom);
  const [compType, setCompType] = useRecoilState(newCustomCompTypeAtom);
  const [metricSameStart, setMetricSameStart] =
    useRecoilState(metricSameStartAtom);
  const [timeframeStartDate, setTimeframeStartDate] = useRecoilState(
    timeframeStartDateAtom
  );
  const [timeframeEndDate, setTimeframeEndDate] =
    useRecoilState(timeframeEndDateAtom);
  const [searchCompanyArray, setSearchCompanyArray] = useRecoilState(
    searchCompanyArrayAtom
  );
  const [selectedSectors, setSelectedSectors] =
    useRecoilState(selectedSectorsAtom);
  const [CSMetricsAttributes, setCSMetricsAttributes] = useRecoilState(
    CSMetricsAttributesAtom
  );
  const [stepOfCreationComps, setStepOfCreationComps] = useRecoilState(
    stepOfCreationCompsAtom
  );
  const [inProgressNewCustomComp, setInProgressNewCustomComp] = useRecoilState(
    inProgressNewCustomCompAtom
  );
  const role = useRecoilValue(roleAtom);
  const isAdmin = role === "admin";
  const zSet = { z: 300 };
  // console.log(customComp);

  const ScatterPlotTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      let _companyName;
      let _companyLogo;
      let _companyColor;
      companiesList.map((company, i) => {
        if (+company.id === +payload[0].payload.company_id) {
          _companyColor =
            company.color !== null ? company.color : colorPatternDefault[i];
          _companyName = company.name;
          _companyLogo =
            company.logoFileName !== null
              ? baseLogoURL + company.logoFileName + signature
              : "";
        }
      });

      return (
        <ScatterPlotTooltipStyle>
          {_companyLogo ? (
            <WrapperLogo>
              <img src={_companyLogo} alt={_companyName} />
              <div className="my-2">{_companyName}</div>
            </WrapperLogo>
          ) : (
            <>
              <i className="fa fa-store fs-16px px-1 py-3"></i>
              {_companyName}
            </>
          )}

          {payload.map((metric) => {
            let _value = "";
            if (
              metric.name === "AUV ($M)" ||
              metric.name === "CAPEX / Unit" ||
              metric.name === "Market Cap" ||
              metric.name === "Enterprise Value" ||
              metric.name === "Valuation"
            ) {
              _value = "$" + abbrNum(+metric.value, 1);
            } else if (metric.name === "Total Funding") {
              _value = "$" + abbrNum(metric.value, 0);
            } else if (
              metric.name === "IG Followers" ||
              metric.name === "FB Followers" ||
              metric.name === "TikTok Followers"
            ) {
              _value = abbrNum(metric.value, 0);
            } else if (metric.name === "Revenue") {
              _value = "$" + abbrNum(metric.value, 0);
            } else if (
              metric.name === "Sales / CAPEX" ||
              metric.name === "EV/LTM EBITDA" ||
              metric.name === "EV/LTM Sales"
            ) {
              let __val = +metric.value;
              _value = __val.toFixed(1) + "x";
            } else if (metric.name === "EBITDA / CAPEX") {
              let __val = +metric.value;
              _value = __val.toFixed(0) + "%";
            } else {
              _value = metric.value;
            }

            return (
              <p
                className="label"
                key={metric.name}
              >{`${metric.name}: ${_value}`}</p>
            );
          })}
        </ScatterPlotTooltipStyle>
      );
    }

    return null;
  };

  useEffect(() => {
    if (!customComp.length > 0 && !preview) {
      getCustomComp();
    }
  }, [needUpdateComp]);

  useEffect(() => {
    if (id !== compID) {
      setCompID(id);
      setIsLoadedComp(false);
    }
  }, [id]);

  useEffect(() => {
    if (preview && customComp) {
      setIsLoadedComp(true);
      // setInvalidData( false );
    }
  }, [customComp]);

  useEffect(() => {
    setCompData(customComp.comp_data);
    setCompMetrics(customComp.custom_comp_metrics);
  }, [customComp]);

  useEffect(() => {
    if (compData !== undefined) {
      setRemappedData(reMapScatterPlot(compData));
    }
  }, [compData]);

  const handleSaveCustomComps = (e, action) => {
    e.preventDefault();
    let _existingTags = [];
    let _newTags = [];
    chosenTags.map((tag) => {
      if (tag.id !== undefined) {
        _existingTags.push({ tag_id: tag.id });
      } else {
        _newTags.push({ label: tag.label });
      }
    });

    setInProgress(true);
    let _url = `${baseURL}/custom_comps/new`;
    if (action === "save") {
      _url = `${baseURL}/custom_comps`;
    }

    let _compType = "";
    if (compType === "Table") {
      _compType = "CustomComps::Table";
    } else if (compType === "Time Series") {
      _compType = "CustomComps::TimeSeries";
    } else if (compType === "Scatter Plot") {
      _compType = "CustomComps::ScatterPlot";
    } else if (compType === "Company Fundraising History") {
      _compType = "CustomComps::CompanyList";
    } else if (compType === "Sector Investment List") {
      _compType = "CustomComps::SectorList";
    } else if (compType === "Store List") {
      _compType = "CustomComps::StoreList";
    } else if (compType === "Firm Retail Investments") {
      _compType = "CustomComps::InvestorList";
    }

    let metricData = {};
    metricData["custom_comp"] = {
      name: compName,
      type: _compType,
      start_date: !metricSameStart ? timeframeStartDate : "",
      end_date: !metricSameStart ? timeframeEndDate : "",
      taggings_attributes: _existingTags,
      tags: _newTags,
      resource_ids:
        compType !== "Sector Investment List"
          ? searchCompanyArray.map((el) => el.id)
          : selectedSectors.map((el) => el.id),
      custom_comp_metrics_attributes:
        compType !== "Sector Investment List"
          ? CSMetricsAttributes.map((el) => {
              return {
                metric_id: el.metric_id,
                ts_chart_type: el.ts_chart_type,
                same_start: metricSameStart,
              };
            })
          : [],
      // } ) : [{metric_id: 1}],
    };
    // same_start: metricSameStart

    const config = {
      method: "post",
      url: _url,
      headers: APIHeadersRAW,
      withCredentials: true,
      credentials: "include",
      data: metricData,
    };
    axios(config)
      .then((resp) => {
        console.log(resp);
        if (resp.data) {
          setCustomComp(resp.data.data);
          setStepOfCreationComps(4);
        }
        if (action === "save") {
          // toasterNotify( 'Custom Comp was created', '', 'success' );
          navigate("/custom-comps", { replace: false });
          setInProgressNewCustomComp(false);
        }
        setInProgress(false);
      })
      .catch((error) => {
        console.log(error);
        setInProgress(false);
      });
  };

  const getCustomComp = () => {
    setIsLoadedComp(false);
    const data = new FormData();
    const config = {
      method: "get",
      url: `${baseURL}/custom_comps/${id}?include_data=true`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
      data: data,
    };
    axios(config)
      .then((resp) => {
        setCustomComp(resp.data.data);
        setCompData(resp.data.data.comp_data);
        setCompMetrics(resp.data.data.custom_comp_metrics);
        setCompaniesListIDs(resp.data.data.resource_ids);
        setIsLoadedComp(true);
        // setInvalidData( false );
        // console.log(JSON.stringify(resp.data.data.comp_data))
      })
      .catch((error) => {
        setErrorMessageAPI(error.response.data.error.title);
        setIsLoadedComp(true);
        setInvalidData(true);
      });
  };
  useEffect(() => {
    if (companiesListIDs && companiesListIDs.length) {
      getCompanyListByID();
    }
  }, [companiesListIDs]);

  useEffect(() => {
    setCompData(customComp.comp_data);
    setCompMetrics(customComp.custom_comp_metrics);
    setCompaniesListIDs(customComp.resource_ids);
  }, [customComp]);

  const getCompanyListByID = () => {
    let params = "";
    companiesListIDs.map((el) => {
      return (params = params + "&ids[]=" + el);
    });

    const config = {
      method: "get",
      url: `${baseURL}/companies?${params}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios(config).then((resp) => {
      setCompaniesList(resp.data.data);
      setInvalidData(false);
    });
  };

  // console.log( remappedData );

  if (!isLoadedComp || id !== compID) {
    return (
      <div className="d-flex justify-content-center align-items-center text-center min-vh-100">
        <div className="spinner-border m-5" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  const formatXAxis = (tickItem) => {
    let _tick = tickItem > 10000 ? abbrNum(tickItem, 1) : tickItem;

    return _tick.toString();
  };

  return (
    <div className="row">
      <WrapperCustomComp>
        <div className="col-12 position-relative">
          {!preview && (
            <TitleTagCustomComp
              compName={customComp.name}
              preview={preview}
              isPopular={customComp.default_comp}
              taggings={customComp.taggings}
              ownerID={customComp.user_id}
              id={id}
            />
          )}
          {!preview && (
            <TitleCustomComp
              compName={customComp.name}
              preview={preview}
              isPopular={customComp.default_comp}
              taggings={customComp.taggings}
              ownerID={customComp.user_id}
              id={id}
            />
          )}

          {id === compID && <LegendComponent data={companiesList} />}

          {invalidData && (
            <CommonErrorComponent error={errorMessageAPI} admin={isAdmin} />
          )}

          <ResponsiveContainer width="100%" height={500}>
            {!invalidData && compMetrics && compMetrics.length ? (
              <ScatterChart
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 10,
                }}
              >
                <CartesianGrid vertical={false} strokeDasharray="2000" />
                {compMetrics && compMetrics.length > 0 ? (
                  compMetrics.map((metric, i) => {
                    if (i === 0) {
                      return (
                        <XAxis
                          type="number"
                          key={"scatter-" + metric.metric_slug}
                          dataKey={metric.metric_slug}
                          name={metric.metric_name}
                          label={{
                            value: metric.metric_name,
                            offset: "-5",
                            position: "insideBottom",
                          }}
                          tickFormatter={formatXAxis}
                        />
                      );
                    } else {
                      return (
                        <YAxis
                          type="number"
                          key={"scatter-" + metric.metric_slug}
                          dataKey={metric.metric_slug}
                          name={metric.metric_name}
                          label={{
                            value: metric.metric_name,
                            angle: -90,
                            offset: "-5",
                            position: "left",
                          }}
                          tickFormatter={formatXAxis}
                        />
                      );
                    }
                  })
                ) : (
                  <></>
                )}
                <ZAxis
                  dataKey={zSet}
                  type="number"
                  range={[180, 300]}
                  name=""
                />
                <Tooltip
                  content={<ScatterPlotTooltip companiesList={companiesList} />}
                  cursor={{ strokeDasharray: "3 3" }}
                  dataKey={"company_id"}
                />
                {companiesList.length > 0 &&
                  companiesList.map((dat, i) => {
                    let _color =
                      dat.color !== null ? dat.color : colorPatternDefault[i];

                    return (
                      <Scatter
                        key={i}
                        name={dat.id}
                        data={remappedData[dat.id]}
                        fill={_color}
                        radius="10"
                      />
                    );
                  })}
              </ScatterChart>
            ) : (
              <></>
            )}
          </ResponsiveContainer>
        </div>
        {preview && (
          <div className="d-flex justify-content-end my-3">
            <Link
              to={`/new-comp-set/${stepOfCreationComps - 1}`}
              className="fs-16px w-100px h-40px buttn-green-outline mx-3 text-center "
            >
              BACK
            </Link>
            <Link
              to="#"
              className="fs-16px w-100px h-40px btn bg-green2 btn-outline-light"
              onClick={(e) => handleSaveCustomComps(e, "save")}
            >
              SAVE
            </Link>
          </div>
        )}
      </WrapperCustomComp>
    </div>
  );
};

export default ScatterPlot;
