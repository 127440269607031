import React, { useEffect, useContext, useState, useCallback } from "react";
import { AppSettings } from "../../config/app-settings";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { APIHeaders, kitcut } from "../../components/shared/helpers";
import SweetAlert from "sweetalert-react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  chosenMetricsAtom,
  CSMetricsAttributesAtom,
  inProgressNewCustomCompAtom,
  inProgressNewCustomCompTypeAtom,
  newCustomCompNameAtom,
  newCustomCompTypeAtom,
  roleAtom,
  searchCompanyArrayAtom,
  searchFieldGoogleMapAtom,
  stepOfCreationCompsAtom,
} from "../../atoms/profileAtom";
import axios from "axios";
import PerformanceMapIcon from "../../components/shared/PerfomanceMapIcon";
import TimeLineIcon from "../../components/shared/TimeLineIcon";
import Taglist from "../custom-comps/Taglist";
import Select from "react-select";
import { stylesSelectWide } from "../Companies";
import {
  checkIsTagged,
  sortingList,
  CompsItem,
} from "../custom-comps/Dashboard";
import CustomCompIcon from "../../components/shared/CustomCompIcon";
import MarkerTrackerIcon from "../../components/shared/MarkerTrackerIcon";
import OverflowMenu from "../../components/shared/OverflowMenu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";

const baseLogoURL = process.env.REACT_APP_LOGOS_BASE_URL;
const signature = process.env.REACT_APP_LOGOS_SIGNATURE;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 26px;
`;

const FilterChipContainer = styled.div`
  display: flex;
  margin-right: auto;
`;

const FilterChip = styled.div`
  height: 20px;
  display: flex;
  padding: 0px 8px 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: rgba(129, 53, 121, 0.1);
  color: #813579;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  margin-right: 12px;
`;

const OverflowMenuContainer = styled.div`
  margin-left: auto;
`;

const CompsItemd = styled.div`
  min-width: 250px;
  width: calc(10% - 20px);
  //height: 302px;
  padding-bottom: 10px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 18px;
  background: #ffffff;
  border: 1px solid #828282;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 0 10px rgba(130, 130, 130, 0);
  transition: 0.3s;
  overflow: hidden;

  @media screen and (max-width: 2560px) {
    width: calc(20% - 20px);
  }

  @media screen and (max-width: 2200px) {
    min-width: 260px;
    width: calc(20% - 20px);
  }

  @media screen and (max-width: 1600px) {
    width: calc(25% - 20px);
    min-width: 250px;
  }

  @media screen and (max-width: 1440px) {
    width: calc(33% - 20px);
  }

  //@media screen and (max-width: 1300px) {
  //  width: calc(33% - 20px);
  //}

  @media screen and (max-width: 1200px) {
    min-width: 230px;
    width: calc(50% - 20px);
  }

  &:hover {
    box-shadow: 0 0 10px rgba(130, 130, 130, 0.4);
  }

  .header {
    width: 100%;
    padding: 12px 8px 5px 8px;
    max-height: 72px;
    cursor: pointer;
    border-bottom: 1px solid #e2e2e2;
    //background: #F0F9FE;

    h4 {
      font-size: 20px;
      line-height: 24px;
      margin: 0 0 8px 0;
      color: #165481;
      max-height: 1.5rem;
    }

    .type-comps,
    .date {
      font-size: 14px;
      line-height: 20px;
      color: #333333;
    }

    .type-comps {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 34px;
      width: 34px;
      margin-right: 16px;
      border-radius: 70px;
      background: var(--Secondary-Primary-White, #fff);
      color: #b668ae;
    }
  }

  .icons {
    display: flex;
    padding: 8px;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;

    img {
      width: auto;
      //margin: 5px 10px;
      //max-width: 100px;
      //max-height: 40px;
    }
  }
`;

const DeleteComps = styled.div`
  position: absolute;
  top: auto;
  bottom: -8px;
  left: auto;
  right: 10px;
  cursor: pointer;
  font-size: 16px;
  color: #828282;
  transition: all 0.3s;
  z-index: 9;

  i {
    &:hover {
      color: red;
    }
  }
`;

const FooterTile = styled.div`
  padding: 2px 2px 0px 8px;
  margin-right: 30px;
`;

const BadgeMore = styled.div`
  position: absolute;
  top: auto;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #333;
  font-weight: 400;
  font-size: 12px;
`;

export const IconSingle = styled.div`
  border-radius: 25px;
  background: #fff;

  /* Widget Drop Shadow */
  box-shadow: 0px 1px 5px 0.5px rgba(0, 0, 0, 0.14);

  width: 50px;
  height: 50px;
  margin-right: 10px;
  border: 1px solid #e2e2e2;
  box-shadow: 0 0 5px 0.5px rgba(0, 0, 0, 0.14);
  // border-radius: 5px;
  margin-bottom: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const AbsoluteCenter = styled.div`
  position: relative;
  height: calc(100vh - 300px);
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 0%);
`;

const TopHeaderPanel = styled.div`
  z-index: 150;
  margin-left: -30px;
  margin-right: -26px;
  position: absolute;
  z-index: 999999;
  top: -88px;
`;

const UserInfo = styled.div`
  font-size: 14px;
  font-weight: 300;
`;

const TitleTypeComp = ({ type }) => {
  let _typeListHuman;
  let _iconType;

  if (type === "CustomComps::MarketTrackerMap") {
    _typeListHuman = "Market Map";
    _iconType = <PerformanceMapIcon width="25" height="20" />;
  } else if (type === "CustomComps::MarketTimeline") {
    _typeListHuman = "Entry Timeline";
    _iconType = <TimeLineIcon size="25" />;
  }

  return (
    <span className="type-comps">
      {_iconType}
    </span>
  );
};

const linkToComp = (id, type) => {
  let _type = "";
  if (type === "CustomComps::MarketTimeline") {
    _type = "timeline";
  } else if (type === "CustomComps::MarketTrackerMap") {
    _type = "map";
  }

  return `${_type}/${id}`;
};

export const IconAndLabel = ({ comp, companiesDetails }) => {
  console.log("companiesDetails: ", companiesDetails);
  console.log("comp: ", comp);
  let _srcLogo = "";
  let _name = "";
  companiesDetails.map((company) => {
    if (+company.id === +comp) {
      _name = company.name;
      _srcLogo =
        company.logoFileName !== null
          ? baseLogoURL + company.logoFileName + signature
          : "";
    }
  });

  return (
    <IconSingle>
      {_srcLogo ? (
        <img src={_srcLogo} alt={_name} />
      ) : (
        <div className="d-flex flex-column align-items-center">
          <div className="fs-12px p-1 text-center">{_name}</div>
        </div>
      )}
    </IconSingle>
  );
};

const MarketTrackerDashboard = () => {
  const context = useContext(AppSettings);
  const [confirmDeleteShow, setConfirmDeleteShow] = useState(false);
  const [idComps, setIdComps] = useState("");
  const [customCompsList, setCustomCompsList] = useState([]);
  const [isLoadedList, setIsLoadedList] = useState(false);
  const [compName, setCompName] = useRecoilState(newCustomCompNameAtom);
  const [compType, setCompType] = useRecoilState(newCustomCompTypeAtom);
  const [stepOfCreationComps, setStepOfCreationComps] = useRecoilState(
    stepOfCreationCompsAtom
  );
  const [searchCompanyArray, setSearchCompanyArray] = useRecoilState(
    searchCompanyArrayAtom
  );
  const [chosenMetrics, setChosenMetrics] = useRecoilState(chosenMetricsAtom);
  const [CSMetricsAttributes, setCSMetricsAttributes] = useRecoilState(
    CSMetricsAttributesAtom
  );
  const [searchField, setSearchField] = useRecoilState(
    searchFieldGoogleMapAtom
  );
  const [companiesDetails, setCompaniesDetails] = useState([]);
  const [compsTagsFilter, setCompsTagsFilter] = useState([]);
  const [compsTagsList, setCompsTagsList] = useState([]);
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const role = useRecoilValue(roleAtom);
  const isAdmin = role === "admin";

  const confirmDeleteCustomComps = (id) => {
    setConfirmDeleteShow(true);
    setIdComps(id);
  };

  const getCompaniesDetails = (ids) => {
    let params = "";
    ids.map((el) => {
      return (params = params + "&ids[]=" + el);
    });

    const config = {
      method: "get",
      url: `${baseURL}/companies?${params}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios(config).then((resp) => {
      setCompaniesDetails(resp.data.data);
    });
  };

  useEffect(() => {
    if (customCompsList.length) {
      let _resourceIDs = [];
      customCompsList.forEach((comp) => {
        comp.resource_ids.map((resource) => {
          if (!_resourceIDs.includes(resource)) {
            _resourceIDs.push(resource);
          }
        });
      });

      getCompaniesDetails(_resourceIDs);
    }
  }, [customCompsList]);

  useEffect(() => {
    if (customCompsList.length > 0) {
      let _tagsList = [];
      customCompsList.map((comp) => {
        if (comp.taggings.length > 0 && !_tagsList.includes(comp.taggings)) {
          comp.taggings.map((tag) => {
            let _val = tag.label;
            if (!_tagsList.some((obj) => obj.label === tag.label)) {
              let _tag = { value: _val, label: _val };
              _tagsList.push(_tag);
            }
          });
        }
      });

      setCompsTagsList(_tagsList);
    }
  }, [customCompsList]);

  const getCustomCompsList = () => {
    setIsLoadedList(false);
    const data = new FormData();
    const config = {
      method: "get",
      url: `${baseURL}/market_trackers`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
      data: data,
    };
    axios(config)
      .then((resp) => {
        setCustomCompsList(resp.data.data.reverse());
        setCustomCompsList(sortingList(resp.data.data));
        setIsLoadedList(true);
        setCompsTagsFilter([]);
        // console.log(resp.data.data)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/user/login", { replace: true });
        }
      });
  };

  useEffect(() => {
    getCustomCompsList();
  }, []);

  const deleteCustomComps = () => {
    // console.log( idComps )
    setConfirmDeleteShow(false);
    const config = {
      method: "delete",
      url: `${baseURL}/custom_comps/${idComps}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios(config)
      .then((resp) => {
        // console.log( resp );
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        getCustomCompsList();
      });
  };

  const handleNewComp = (e) => {
    e.preventDefault();
    setCompName("");
    setCompType("");
    setStepOfCreationComps(1);
    setSearchCompanyArray([]);
    setChosenMetrics([]);
    setCSMetricsAttributes([]);
    setCompsTagsFilter([]);
    setCompsTagsList([]);
    setSearchField("");
    navigate("/market-tracker/new-market-tracker", { replace: false });
  };

  const handleChangeTagsFilter = (e) => {
    setCompsTagsFilter(e);
  };

  const getBackgroundByType = useCallback((type) => {
    const dict = {
      "CustomComps::TimeSeries":
        "linear-gradient(90deg, rgba(0, 18, 110, 0.20) 0%, rgba(0, 18, 110, 0.05) 100%)",
      "CustomComps::Table":
        "linear-gradient(90deg, rgba(110, 0, 106, 0.20) 0%, rgba(110, 0, 106, 0.05) 100%)",
      "CustomComps::ScatterPlot":
        "linear-gradient(90deg, rgba(15, 110, 0, 0.20) 0%, rgba(15, 110, 0, 0.05) 100%)",
    };
    console.log("dict[type]: ", dict[type]);
    return dict[type];
  }, []);

  useEffect(() => {
    context.handleSetAppHeaderNone(false);
    context.handleSetAppSidebarNone(false);
    context.handleSetAppContentClass("mh-100vh-88");
    context.handleSetAppTitle("My Market Trackers");
    context.handleSetAppIcon(<MarkerTrackerIcon />);
  }, []);

  if (!isLoadedList)
    return (
      <div className="d-flex justify-content-center align-items-center text-center h-500px">
        <div className="spinner-border m-5" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );

  return (
    <div className="row">
      <TopHeaderPanel className="top-header-panel">
        <div className="d-flex align-items-center justify-content-end">
          <div>
            <Link
              to="#"
              onClick={(e) => handleNewComp(e)}
              className="my-lg-2 btn btn-primary text-white fs-16px"
            >
              <i className="mx-2 fa fa-circle-plus"></i> New Map or Timeline
            </Link>
          </div>
        </div>
        {/* <div className="d-flex align-items-center justify-content-end pt-1">
          <div className="d-flex align-items-center">
            {compsTagsList.length > 0 ? (
              <div className="d-flex align-items-center">
                <div className="fs-16px w-65px">Filter by</div>
                <div className="w-25px"></div>
                <div className="position-relative">
                  <Select
                    isMulti
                    options={compsTagsList}
                    onChange={handleChangeTagsFilter}
                    styles={stylesSelectWide}
                    placeholder="Select Tags"
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div> */}
      </TopHeaderPanel>
      <div className="d-flex align-items-center pt-1 mb-3">
        <FilterChipContainer>
          {!!compsTagsFilter.length &&
            compsTagsFilter.map((tag) => (
              <FilterChip key={tag.value}>
                {tag.label}
                <i
                  className="fa-solid fa-xmark cursor-pointer"
                  onClick={() => {
                    setCompsTagsFilter(
                      compsTagsFilter.filter((el) => el.label !== tag.label)
                    );
                  }}
                ></i>
              </FilterChip>
            ))}
        </FilterChipContainer>
        <div className="d-flex align-items-center justify-content-end ml-auto">
          {compsTagsList.length > 0 ? (
            <div className="d-flex align-items-center">
              <div className="fs-16px w-65px">Filter by</div>
              <div className="w-25px"></div>
              <div className="position-relative">
                <Select
                  isMulti
                  // value={""}
                  options={compsTagsList}
                  // value={filterSectorText}
                  onChange={handleChangeTagsFilter}
                  styles={stylesSelectWide}
                  placeholder="Select Tags"
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Grid>
        {customCompsList &&
          customCompsList.map((el, i) => {
            let moreThan6Comp = false;
            let moreThan6CompCount = 0;
            if (el.resource_ids.length > 6) {
              moreThan6Comp = true;
              moreThan6CompCount = el.resource_ids.length - 6;
            }

            if (
              compsTagsFilter.length === 0 ||
              (compsTagsFilter.length > 0 && checkIsTagged(el, compsTagsFilter))
            )
              return (
                <CompsItem key={el.id + "-item-" + i + el.id}>
                  <Link
                    to={linkToComp(el.id, el.type)}
                    className="text-decoration-none"
                  >
                    <div
                      className="header"
                      style={{
                        background: `${getBackgroundByType(el.type)}`,
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <TitleTypeComp type={el.type} />
                        <div className="d-flex flex-column justify-content-between">
                          <h4 title={el.name} clasName="title">
                            {kitcut(el.name, 26)}
                          </h4>
                          <span className="date">
                            {moment(el.created_at).format("MMM DD, yyyy")}
                          </span>
                        </div>
                        <OverflowMenuContainer>
                          <OverflowMenu className="ml-3">
                            <MenuItem disabled>
                              <ListItemIcon>
                                <CreateIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Edit</ListItemText>
                            </MenuItem>

                            <MenuItem
                              onClick={() => confirmDeleteCustomComps(el.id)}
                            >
                              <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Delete</ListItemText>
                            </MenuItem>
                          </OverflowMenu>
                        </OverflowMenuContainer>
                      </div>
                    </div>
                  </Link>
                  <div className="tile-content d-flex flex-column justify-content-between h-100">
                    <div className="icons">
                      {el.resource_ids.map((comp, i) => {
                        return i < 6 ? (
                          <IconAndLabel
                            companiesDetails={companiesDetails}
                            comp={comp}
                            key={comp + i}
                          />
                        ) : (
                          <></>
                        );
                      })}
                    </div>
                    <div className="position-relative">
                      <DeleteComps
                        onClick={() => confirmDeleteCustomComps(el.id)}
                      >
                        <i className="fa fa-trash"></i>
                      </DeleteComps>
                      {moreThan6Comp && (
                        <BadgeMore>+{moreThan6CompCount} more</BadgeMore>
                      )}
                      <FooterTile>
                        {/*<UserInfo>{isAdmin && el.user_name ? <><i className="fa fa-user"></i> <span>{el.user_name}</span></> : <></>}</UserInfo>*/}
                        {el.taggings.length > 0 ? (
                          <Taglist list={el.taggings} />
                        ) : (
                          <></>
                        )}
                      </FooterTile>
                    </div>
                  </div>
                </CompsItem>
              );
          })}
      </Grid>
      {!customCompsList.length && (
        <AbsoluteCenter>
          <div className="d-flex w-100 justify-content-center align-items-center">
            <p className="text-center text-muted">
              No Market Trackers generated. Click New Map or Timeline to create
              one.
            </p>
          </div>
        </AbsoluteCenter>
      )}
      <SweetAlert
        show={confirmDeleteShow}
        title="Are you sure you want to delete this Market Tracker?"
        type="error"
        confirmButtonText="Delete"
        showCancelButton={true}
        onCancel={() => setConfirmDeleteShow(false)}
        confirmButtonColor="rgb(255, 91, 87)"
        onConfirm={() => deleteCustomComps()}
      />
    </div>
  );
};

export default MarketTrackerDashboard;
