import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  chosenTagsAtom,
  CSMetricsAttributesAtom,
  customCompAtom,
  customCompIDAtom,
  inProgressNewCustomCompAtom,
  metricSameStartAtom,
  needUpdateCompAtom,
  newCustomCompNameAtom,
  newCustomCompTypeAtom,
  roleAtom,
  searchCompanyArrayAtom,
  selectedSectorsAtom,
  stepOfCreationCompsAtom,
  timeframeEndDateAtom,
  timeframeStartDateAtom,
} from "../../atoms/profileAtom";
import {
  abbrNum,
  APIHeaders,
  APIHeadersRAW,
  kitcut,
  numberWithCommas,
} from "../../components/shared/helpers";
import axios from "axios";
import styled from "styled-components";
import CommonErrorComponent from "../../components/shared/CommonErrorComponent";
import TitleCustomComp from "../../components/shared/TitleCustomComp";
import { AppSettings } from "../../config/app-settings";
import CustomCompIcon from "../../components/shared/CustomCompIcon";
import TitleTagCustomComp from "../../components/shared/TitleTagCustomComp";
import { CompanyColor } from "../Maps/CompanyEntry";
import { colorPatternDefault } from "../../components/shared/Colors";
import ListIcon from "../../components/shared/ListIcon";
import { WrapperCustomComp } from "../market-tracker/MarketTrackerTimeline";
import { sortByField } from "../custom-comps/StoreListTable";
const baseLogoURL = process.env.REACT_APP_LOGOS_BASE_URL;
const signature = process.env.REACT_APP_LOGOS_SIGNATURE;

export const TableChartTimelineWrapper = styled.div`
  overflow-x: auto;

  table {
    thead {
      font-size: 16px;
      text-align: center;
      font-weight: 500;
      color: #828282;

      th,
      td {
        vertical-align: middle;
        background-color: #f7f8fa;
        border-bottom: 2px solid #c2c5cc;
      }
      th {
        position: sticky;
        top: -1px;
        z-index: 1;

        &:before {
          content: "";
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: 1px;
          //background-color: rgb(206, 212, 218);
        }

        &.th-header {
          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: auto;
            right: 0;
            width: 1px;
            height: 100%;
            background-color: rgb(206, 212, 218);
          }
        }
      }
    }

    tbody {
      font-size: 15px;
      color: #333333;
      td {
        padding: 0;
        &:after {
          content: "";
          position: absolute;
          top: 10px;
          width: 1px;
          height: calc(100% - 20px);
          background-color: #c2c5cc;
          left: 50%;
        }
      }

      th {
        color: black;
        font-weight: normal;
        max-width: 400px;
        min-width: 250px !important;
        //position: sticky!important;
        z-index: 1;
        //left: -1px;
        top: -1px;
        //background-color: #fff;
        //right: 1px;
        //margin-right: 1px;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: auto;
          right: 0;
          width: 1px;
          height: 100%;
          background-color: rgb(206, 212, 218);
        }
      }
      th,
      td {
        vertical-align: middle;
        text-align: left;
        min-width: 350px;
        columns: 2;
        column-gap: 0;
        position: relative;
      }
      p {
        font-size: 14px;
        //margin-top: 0.5rem;
        //margin-bottom: 0.5rem;
        margin: 0;
        padding: 0.5rem 0.8rem;
      }
    }
  }
`;
export const TableChartWrapper = styled.div`
  overflow-x: auto;
  table {
    thead {
      font-size: 16px;
      text-align: center;
      font-weight: 500;
      color: #828282;

      th,
      td {
        vertical-align: middle;
      }
      th {
        z-index: 6;
        position: sticky;
        left: -1px;
        border-bottom: 2px solid rgb(194, 197, 204);
      }
    }
    tbody {
      font-size: 15px;
      color: #333333;

      th {
        color: black;
        font-weight: normal;
        width: 350px;
        z-index: 1;
        position: sticky;
        left: 1px;
        background-color: #fff;
        box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
      }
      th,
      td {
        vertical-align: middle;
      }
      p {
        font-size: 14px;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
`;

const LogoTimelineWrapper = styled.div`
  //max-width: 100px;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: start;
  //margin: 0 auto;
  margin-left: 10px;
  //color: #828282;
  font-size: 16px;
  text-align: center;

  img {
    max-width: 36px;
    max-height: 36px;
    margin-right: 16px;
  }
`;

const LogoWrapper = styled.div`
  max-width: 100px;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  //margin-left: 10px;
  color: #828282;
  font-size: 16px;
  text-align: center;

  img {
    max-width: 36px;
    max-height: 36px;
    margin-right: 16px;
  }
`;

export const LogoNameTimeline = ({ comp, showLogo, idx }) => {
  let _logo;
  let _color;
  if (comp.color === undefined || comp.color === null) {
    _color = colorPatternDefault[idx];
  } else {
    _color = comp.color;
  }
  if (comp.logoFileName !== null) {
    _logo = (
      <LogoTimelineWrapper>
        <Link
          to={"/companies/" + comp.id}
          className="text-decoration-none color-neutral-black"
        >
          <img
            src={baseLogoURL + comp.logoFileName + signature}
            alt={comp.name}
            title={comp.name}
          />
          {comp.name}
        </Link>
      </LogoTimelineWrapper>
    );
  } else {
    _logo = (
      <LogoTimelineWrapper className="w-100">
        <Link
          to={"/companies/" + comp.id}
          className="text-decoration-none color-neutral-black"
        >
          {comp.name}
        </Link>
      </LogoTimelineWrapper>
    );
  }

  return (
    <th
      scope="row"
      className="text-center"
      style={{
        height: showLogo ? "60px" : "auto",
        paddingLeft: "20px",
        columns: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <CompanyColor color={_color} />
        {showLogo ? _logo : comp.name}
      </div>
    </th>
  );
};

export const LogoName = ({ comp, showLogo }) => {
  let _logo;
  if (comp.logoFileName !== null) {
    _logo = (
      <LogoWrapper>
        <Link to={"/companies/" + comp.id}>
          <img
            src={baseLogoURL + comp.logoFileName + signature}
            alt={comp.name}
            title={comp.name}
          />
        </Link>
      </LogoWrapper>
    );
  } else {
    _logo = (
      <LogoWrapper
        className="w-100 color-neutral-black fs-14px"
        title={comp.name}
      >
        {kitcut(comp.name, 22)}
      </LogoWrapper>
    );
  }

  return (
    <th
      scope="row"
      className="text-center"
      style={{ height: showLogo ? "60px" : "auto" }}
    >
      {showLogo ? _logo : comp.name}
    </th>
  );
};

const TableChart = ({ preview, tableType }) => {
  let { id } = useParams();
  const context = useContext(AppSettings);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [customComp, setCustomComp] = useRecoilState(customCompAtom);
  const [needUpdateComp, setNeedUpdateComp] =
    useRecoilState(needUpdateCompAtom);
  const [compID, setCompID] = useRecoilState(customCompIDAtom);
  const [isLoadedComp, setIsLoadedComp] = useState(false);
  const [compData, setCompData] = useState({});
  const [compMetrics, setCompMetrics] = useState({});
  const [invalidData, setInvalidData] = useState(true);
  const [showLogo, setShowLogo] = useState(true);
  const [companiesList, setCompaniesList] = useState([]);
  const [resourceIDs, setResourceIDs] = useState([]);
  const [errorMessageAPI, setErrorMessageAPI] = useState("");
  const role = useRecoilValue(roleAtom);
  const isAdmin = role === "admin";
  const [sortBy, setSortBy] = useState("");
  const [order, setOrder] = useState("asc");
  const chosenTags = useRecoilValue(chosenTagsAtom);
  const [inProgress, setInProgress] = useState(false);
  const navigate = useNavigate();
  const [compName, setCompName] = useRecoilState(newCustomCompNameAtom);
  const [compType, setCompType] = useRecoilState(newCustomCompTypeAtom);
  const [metricSameStart, setMetricSameStart] =
    useRecoilState(metricSameStartAtom);
  const [timeframeStartDate, setTimeframeStartDate] = useRecoilState(
    timeframeStartDateAtom
  );
  const [timeframeEndDate, setTimeframeEndDate] =
    useRecoilState(timeframeEndDateAtom);
  const [searchCompanyArray, setSearchCompanyArray] = useRecoilState(
    searchCompanyArrayAtom
  );
  const [selectedSectors, setSelectedSectors] =
    useRecoilState(selectedSectorsAtom);
  const [CSMetricsAttributes, setCSMetricsAttributes] = useRecoilState(
    CSMetricsAttributesAtom
  );
  const [stepOfCreationComps, setStepOfCreationComps] = useRecoilState(
    stepOfCreationCompsAtom
  );
  const [inProgressNewCustomComp, setInProgressNewCustomComp] = useRecoilState(
    inProgressNewCustomCompAtom
  );

  useEffect(() => {
    setIsLoadedComp(false);
    context.handleSetAppTitle("Table");
    context.handleSetAppIcon(<ListIcon />);
  }, []);

  const handleOrder = (by) => {
    if (sortBy === by) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setSortBy(by);
    }
  };

  const getCustomComp = () => {
    setIsLoadedComp(false);
    const data = new FormData();
    const config = {
      method: "get",
      url: `${baseURL}/custom_comps/${id}?include_data=true`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
      data: data,
    };
    axios(config)
      .then((resp) => {
        setCustomComp(resp.data.data);
        // console.log(resp.data.data)
      })
      .catch((error) => {
        setErrorMessageAPI(error.response.data.error.title);
        setIsLoadedComp(true);
        setInvalidData(true);
      });
  };

  const getCompanyList = () => {
    let params = "";
    resourceIDs.map((el) => {
      return (params = params + "&ids[]=" + el);
    });
    const config = {
      method: "get",
      url: `${baseURL}/companies?${params}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios(config).then((resp) => {
      setCompaniesList(resp.data.data);
    });
  };

  const handleSaveCustomComps = (e, action) => {
    e.preventDefault();
    let _existingTags = [];
    let _newTags = [];
    chosenTags.map((tag) => {
      if (tag.id !== undefined) {
        _existingTags.push({ tag_id: tag.id });
      } else {
        _newTags.push({ label: tag.label });
      }
    });

    setInProgress(true);
    let _url = `${baseURL}/custom_comps/new`;
    if (action === "save") {
      _url = `${baseURL}/custom_comps`;
    }

    let _compType = "";
    if (compType === "Table") {
      _compType = "CustomComps::Table";
    } else if (compType === "Time Series") {
      _compType = "CustomComps::TimeSeries";
    } else if (compType === "Scatter Plot") {
      _compType = "CustomComps::ScatterPlot";
    } else if (compType === "Company Fundraising History") {
      _compType = "CustomComps::CompanyList";
    } else if (compType === "Sector Investment List") {
      _compType = "CustomComps::SectorList";
    } else if (compType === "Store List") {
      _compType = "CustomComps::StoreList";
    } else if (compType === "Firm Retail Investments") {
      _compType = "CustomComps::InvestorList";
    }

    let metricData = {};
    metricData["custom_comp"] = {
      name: compName,
      type: _compType,
      start_date: !metricSameStart ? timeframeStartDate : "",
      end_date: !metricSameStart ? timeframeEndDate : "",
      taggings_attributes: _existingTags,
      tags: _newTags,
      resource_ids:
        compType !== "Sector Investment List"
          ? searchCompanyArray.map((el) => el.id)
          : selectedSectors.map((el) => el.id),
      custom_comp_metrics_attributes:
        compType !== "Sector Investment List"
          ? CSMetricsAttributes.map((el) => {
              return {
                metric_id: el.metric_id,
                ts_chart_type: el.ts_chart_type,
                same_start: metricSameStart,
              };
            })
          : [],
      // } ) : [{metric_id: 1}],
    };
    // same_start: metricSameStart

    const config = {
      method: "post",
      url: _url,
      headers: APIHeadersRAW,
      withCredentials: true,
      credentials: "include",
      data: metricData,
    };
    axios(config)
      .then((resp) => {
        console.log(resp);
        if (resp.data) {
          setCustomComp(resp.data.data);
          setStepOfCreationComps(4);
        }
        if (action === "save") {
          // toasterNotify( 'Custom Comp was created', '', 'success' );
          navigate("/custom-comps", { replace: false });
          setInProgressNewCustomComp(false);
        }
        setInProgress(false);
      })
      .catch((error) => {
        console.log(error);
        setInProgress(false);
      });
  };

  function sortByField(arr, field, ascending = true) {
    const sortedKeys = Object.keys(arr).sort((a, b) => {
      let fieldA = arr[a][field] || "";
      let fieldB = arr[b][field] || "";
      if (ascending) {
        return fieldA - fieldB;
      } else {
        return fieldB - fieldA;
      }
    });

    return sortedKeys.map((key) => parseInt(key));
  }

  useEffect(() => {
    if (id !== compID && !preview) {
      setCompID(id);
      setCustomComp([]);
      setIsLoadedComp(false);
    }
  }, [id]);

  useEffect(() => {
    setCompData(customComp.comp_data);
    setCompMetrics(customComp.custom_comp_metrics);
    setResourceIDs(customComp.resource_ids);
    setIsLoadedComp(true);
    setInvalidData(false);
  }, [customComp]);

  useEffect(() => {
    if (resourceIDs) {
      getCompanyList();
    }
  }, [resourceIDs]);
  // console.log( companiesList, compMetrics, compData )

  useEffect(() => {
    if (!customComp.length > 0 && !preview) {
      getCustomComp();
    }
  }, [needUpdateComp]);

  useEffect(() => {
    if (sortBy) {
      let _arr = sortByField(compData, sortBy, order === "asc");
      setResourceIDs(_arr);
    }
  }, [sortBy, order]);

  const example = {
    45014: {
      total_stores_open: 2,
      total_states: "7",
      glassdoor_rating: "3.3",
      indeed_rating: "3.5",
      ig_followers_per_company: 62036,
      fb_followers_per_company: 17000,
      total_facebook_posts: "411",
      avg_google_rating_location: "4.67",
      total_funding: null,
    },
    45038: {
      total_stores_open: 0,
      total_states: "1",
      glassdoor_rating: "2.8",
      indeed_rating: "3",
      ig_followers_per_company: 17855,
      fb_followers_per_company: 6800,
      total_facebook_posts: "264",
      avg_google_rating_location: null,
      total_funding: null,
    },
    45078: {
      total_stores_open: 1,
      total_states: "3",
      ig_followers_per_company: 10029,
      total_facebook_posts: "0",
      avg_google_rating_location: "5.00",
      total_funding: null,
    },
    104638: {
      total_stores_open: 0,
      total_states: "1",
      glassdoor_rating: "2.1",
      indeed_rating: "3.5",
      ig_followers_per_company: 57807,
      fb_followers_per_company: 10000,
      total_facebook_posts: "129",
      avg_google_rating_location: null,
      total_funding: null,
    },
    104693: {
      total_stores_open: 0,
      total_states: "1",
      glassdoor_rating: "4.1",
      indeed_rating: "4.2",
      ig_followers_per_company: 73208,
      fb_followers_per_company: 20000,
      total_facebook_posts: "267",
      avg_google_rating_location: null,
      total_funding: null,
    },
    135262: {
      total_stores_open: 3,
      total_states: "23",
      glassdoor_rating: "3.6",
      indeed_rating: "2.8",
      ig_followers_per_company: 1205220,
      fb_followers_per_company: 57000,
      total_facebook_posts: "219",
      avg_google_rating_location: "4.00",
      total_funding: null,
    },
    248951: {
      total_stores_open: 0,
      total_states: "4",
      glassdoor_rating: "2.6",
      indeed_rating: "2.8",
      ig_followers_per_company: 18811,
      fb_followers_per_company: 3300,
      total_facebook_posts: "338",
      avg_google_rating_location: null,
      total_funding: null,
    },
    248953: {
      total_stores_open: 0,
      total_states: "1",
      glassdoor_rating: "3.3",
      indeed_rating: "3.3",
      ig_followers_per_company: 5925,
      fb_followers_per_company: 11000,
      total_facebook_posts: "235",
      avg_google_rating_location: null,
      total_funding: null,
    },
    439335: {
      total_stores_open: 0,
      total_states: "1",
      ig_followers_per_company: 37018,
      fb_followers_per_company: 2400,
      total_facebook_posts: "221",
      avg_google_rating_location: null,
      total_funding: null,
    },
    1004491: {
      total_stores_open: 1,
      total_states: "1",
      total_facebook_posts: "0",
      avg_google_rating_location: "4.00",
      total_funding: null,
    },
  };

  if (!isLoadedComp) {
    return (
      <div className="d-flex justify-content-center align-items-center text-center min-vh-100">
        <div className="spinner-border m-5" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <>
      {customComp && compMetrics && compMetrics.length && (
        <TableChartWrapper className={preview ? "" : ""}>
          <TitleTagCustomComp
            compName={customComp.name}
            preview={preview}
            isPopular={customComp.default_comp}
            id={id}
            taggings={customComp.taggings}
            ownerID={customComp.user_id}
            showLogo={() => setShowLogo(!showLogo)}
            isShowLogo={showLogo}
          />
          <WrapperCustomComp>
            <TitleCustomComp
              compName={customComp.name}
              preview={preview}
              isPopular={customComp.default_comp}
              id={id}
              taggings={customComp.taggings}
              ownerID={customComp.user_id}
              showLogo={() => setShowLogo(!showLogo)}
              isShowLogo={showLogo}
            />
            <div
              style={{
                overflowX: "auto",
                maxHeight: "calc(-250px + 100vh)",
              }}
            >
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      style={{
                        position: "sticky",
                        backgroundColor: "#f7f8fa",
                        top: "-1px",
                        zIndex: 7,
                        minWidth: "300px",
                      }}
                    >
                      Company
                    </th>
                    {compMetrics.map((metric, i) => {
                      return (
                        <th
                          scope="col"
                          key={`${metric.metric_id}-${i}`}
                          style={{
                            position: "sticky",
                            backgroundColor: "#f7f8fa",
                            top: "-1px",
                            minWidth: "130px",
                            // display: "flex",
                            // alignItems: "center"
                          }}
                          className="cursor-pointer"
                          onClick={() => handleOrder(metric.metric_slug)}
                        >
                          <div className="d-flex align-items-center">
                            <span className="mx-2">{metric.metric_name}</span>{" "}
                            {sortBy === metric.metric_slug ? (
                              <i
                                className={
                                  order === "asc"
                                    ? "fa fa-caret-up gray-active"
                                    : "fa fa-caret-down gray-active"
                                }
                              ></i>
                            ) : (
                              <i className="fa fa-sort gray-inactive" />
                            )}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {resourceIDs.map((ids) => {
                    return companiesList.map((comp, index) => {
                      if (comp.id === +ids) {
                        // console.log( comp )
                        return (
                          <tr key={`tr-${index}`} className="text-center">
                            <LogoNameTimeline
                              comp={comp}
                              showLogo={showLogo}
                              idx={index}
                            />
                            {compMetrics.map((metric) => {
                              let _value;
                              if (compData[ids][metric.metric_slug] === null) {
                                _value = "-";
                              } else {
                                _value = !isNaN(
                                  +compData[ids][metric.metric_slug]
                                )
                                  ? +compData[ids][metric.metric_slug]
                                  : "-";
                              }
                              if (
                                _value &&
                                _value !== "-" &&
                                (metric.metric_slug === "auv" ||
                                  metric.metric_slug === "capex_unit" ||
                                  metric.metric_slug === "market_cap" ||
                                  metric.metric_slug === "valuation" ||
                                  metric.metric_slug === "enterprice_value")
                              ) {
                                _value = "$" + abbrNum(_value, 1);
                              } else if (
                                _value &&
                                _value !== "-" &&
                                (metric.metric_slug === "revenue" ||
                                  metric.metric_slug === "total_funding")
                              ) {
                                _value = "$" + abbrNum(_value, 0);
                              } else if (
                                _value &&
                                _value !== "-" &&
                                (metric.metric_slug === "ev_ltm_ebitda" ||
                                  metric.metric_slug === "ev_ltm_sales" ||
                                  metric.metric_slug === "sales_capex")
                              ) {
                                _value = `${+_value.toFixed(1)}x`;
                              } else if (
                                _value &&
                                _value !== "-" &&
                                metric.metric_slug ===
                                  "avg_google_reviews_location"
                              ) {
                                _value = numberWithCommas(_value.toFixed(0));
                              } else if (
                                _value &&
                                _value !== "-" &&
                                metric.metric_slug === "ebitda"
                              ) {
                                _value = (_value * 100).toFixed(1) + "%";
                              } else if (
                                _value &&
                                _value !== "-" &&
                                metric.metric_slug === "ebitda_capex"
                              ) {
                                _value = (_value * 100).toFixed(0) + "%";
                              } else if (
                                _value &&
                                _value !== "-" &&
                                (metric.metric_slug ===
                                  "ig_followers_per_company" ||
                                  metric.metric_slug ===
                                    "tt_followers_per_company" ||
                                  metric.metric_slug ===
                                    "fb_followers_per_company")
                              ) {
                                _value = abbrNum(_value, 0);
                              } else if (
                                (_value !== "-" &&
                                  _value > 0.001 &&
                                  _value < 0.999 &&
                                  metric.metric_slug !==
                                    "avg_google_rating_location" &&
                                  metric.metric_slug !==
                                    "avg_google_reviews_location") ||
                                (metric.metric_slug === "same_store_sales" &&
                                  _value !== "-")
                              ) {
                                _value = (_value * 100).toFixed(1) + "%"; //probably %% value
                              } else {
                                _value = !isNaN(_value) ? _value : "-";
                              }

                              return <td key={metric.metric_slug}>{_value}</td>;
                            })}
                          </tr>
                        );
                      }
                    });
                  })}
                </tbody>
              </table>
            </div>
            {preview && (
              <div className="d-flex justify-content-end my-3">
                <Link
                  to={`/new-comp-set/${stepOfCreationComps - 1}`}
                  className="fs-16px btn w-100px btn-primary text-uppercase"
                >
                  BACK
                </Link>
                <Link
                  to="#"
                  className="fs-16px w-100px h-40px btn btn-primary"
                  onClick={(e) => handleSaveCustomComps(e, "save")}
                >
                  SAVE
                </Link>
              </div>
            )}
          </WrapperCustomComp>
        </TableChartWrapper>
      )}
      {invalidData && (
        <CommonErrorComponent error={errorMessageAPI} admin={isAdmin} />
      )}
    </>
  );
};

export default TableChart;
