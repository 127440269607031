import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const OverflowMenu = ({ children, className = "" }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div className={className}>
      <IconButton
        aria-label="more"
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {React.Children.map(children, (child) => {
          if (!child) return null;
          const childOnClick = child.props.onClick;

          return React.cloneElement(child, {
            onClick: (e) => {
              if (childOnClick) childOnClick(e);
              handleClose(e);
            },
          });
        })}
      </Menu>
    </div>
  );
};

export default OverflowMenu;
