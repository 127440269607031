import { useRecoilState, useRecoilValue } from "recoil";
import { messagesLoadedAtom, questionsListAtom, questionsListLoadedAtom, roleAtom } from "../../atoms/profileAtom";
import React, { useEffect, useContext, useState } from "react";
import { AppSettings } from "../../config/app-settings";
import styled from "styled-components";
import { APIHeaders, APIHeadersRAW, toasterNotify } from "../../components/shared/helpers";
import axios from "axios";
import { ReactNotifications } from "react-notifications-component";
import SuccessIcon from "../../components/shared/Icons/SuccessIcon";
import { Link, useNavigate } from "react-router-dom";
import CustomCompIcon from "../../components/shared/CustomCompIcon";
import AskUsIcon from "../../components/shared/AskUsIcon";

const AskPageStyle = styled.div`
  //max-width: 1300px;
  .wrapper {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
  }
  
  h1 {
    font-size: 36px;
    line-height: 40px;
    font-weight: 400;
  }
  
  h2 {
    font-weight: 400;
    margin-bottom: 40px;
  }

  h4 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 300;
    padding-bottom: 10px;
  }

  .form-check-input:checked {
    background-color: #B668AE;
  }

  .form-check {
    margin-bottom: 8px;
  }

  .form-check-label {
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    color: #1E1E1E;
  }

  .textarea {
    padding: 10px 14px;
    border: 1px solid #656A6B;
    border-radius: 8px;
    width: 100%;
    //min-height: 245px;
    margin-bottom: 10px;
  }

  .btn {
    background: linear-gradient(173.26deg, #66B39C 0%, #478F83 100%);
    opacity: .9;
    border-radius: 8px;
    padding: 10px 30px;
    font-weight: 300;
    font-size: 16px;
    color: #fff;
    border: none;
    transition: all 0.3s;

    &:disabled {
      opacity: .6;
    }

    &:hover {
      opacity: 1;
    }
  }
`;

const AskUsAnything = () => {
  const context = useContext( AppSettings );
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [ questionsList, setQuestionsList ] = useRecoilState( questionsListAtom );
  const [ questionsListLoaded, setQuestionsListLoaded ] = useRecoilState( questionsListLoadedAtom );
  const [ loadedMessages, setLoadedMessages ] = useRecoilState( messagesLoadedAtom );
  const [ showSuccessMessage, setShowSuccessMessage ] = useState(false);
  const [ questionList, setQuestionList ] = useState( [] );
  const [ selectedCheckboxes, setSelectedCheckboxes ] = useState( [] );
  const [ questionListLoaded, setQuestionListLoaded ] = useState( false );
  const [ sendingQuestionStatus, setSendingQuestionStatus ] = useState( false );
  const [ userQuestion, setUserQuestion ] = useState( '' );
  const navigate = useNavigate();

  useEffect( () => {
    loadQuestions();
  }, [] )

  useEffect(
    () => {
      context.handleSetAppHeaderNone( false );
      context.handleSetAppSidebarNone( false );
      context.handleSetAppContentClass( 'mh-100vh-88' );
      context.handleSetAppTitle( 'Ask Us Anything' );
      context.handleSetAppIcon( <AskUsIcon /> );
    },
    []
  );

  const loadQuestions = () => {
    setQuestionListLoaded( false );
    const config = {
      method: 'get',
      url: `${baseURL}/questions`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( ( resp ) => {
        setQuestionList( resp.data.data );
      } )
      .catch((error)=>{
        if ( error.response.status === 401 ) {
          navigate( '/user/login', {replace: true} )
        }
      })
      .finally( () => {
      setQuestionListLoaded( true );
    } )
  }

  const Question = ( { question } ) => {

    return <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        value={question.is}
        id={'themeCheckbox-' + question.id}
        checked={selectedCheckboxes.includes( question.id )}
        onChange={() => handleCheckboxChange( question.id )}
      />
      <label className="form-check-label" htmlFor={'themeCheckbox-' + question.id}>
        {question.title}
      </label>
    </div>
  }

  const handleCheckboxChange = ( itemId ) => {
    setSelectedCheckboxes( ( prevSelectedCheckboxes ) => {
      if ( prevSelectedCheckboxes.includes( itemId ) ) {
        return prevSelectedCheckboxes.filter( ( id ) => id !== itemId );
      } else {
        return [ ...prevSelectedCheckboxes, itemId ];
      }
    } );
  };

  const handleChangeQuestion = ( e ) => {
    setUserQuestion( e.target.value );
  }

  const handleSendQuestion = () => {
    setSendingQuestionStatus( true );
    let questionData = {};
    questionData[ 'request_params' ] = {
      "request_type": "ask_us",
      "questions_ids": selectedCheckboxes,
      "details": userQuestion
    }
    const config = {
      method: 'post',
      url: `${baseURL}/ask_us_requests`,
      headers: APIHeadersRAW,
      withCredentials: true,
      credentials: "include",
      data: questionData,
    };
    axios( config ).then( resp => {
      console.log( resp );
      setSelectedCheckboxes( [] );
      setUserQuestion( '' );
      setQuestionsListLoaded(false);
      setLoadedMessages(false);
      setShowSuccessMessage(true);
      // toasterNotify( 'Sent', 'Message was successfully sent', 'success' );
    } ).catch( error => {
      console.log( error )
    } ).finally( () => {
      setSendingQuestionStatus( false );
    } )

  }

  if ( !questionListLoaded ) return <div className="d-flex justify-content-center align-items-center text-center h-500px">
    <div className="spinner-border m-5" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>;

  return !showSuccessMessage ? <AskPageStyle className="row ask-us-page">
    <div className="col-12 col-lg-6 mb-4">
      <div className="wrapper">
        <h2>How can we help you? <br />Ask us literally anything</h2>
        <h4>I need help with...</h4>
        {questionList.map( question => <Question question={question} key={question.id} /> )}
      </div>
    </div>
    <div className="col-12 col-lg-6 mb-4">
      <div className="wrapper">
        <h4>Tell us more – don’t hold back!</h4>
        <textarea
          className="textarea"
          rows="12"
          value={userQuestion}
          onChange={handleChangeQuestion}
          placeholder="Ask us anything..."
        />
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleSendQuestion}
            disabled={userQuestion.length === 0 || sendingQuestionStatus}
            value={userQuestion.length > 0 ? 'Loading' : 'Submit'}
          >{sendingQuestionStatus ? <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Sending...</> : 'Submit'}
          </button>
        </div>
      </div>
    </div>
    <ReactNotifications />
  </AskPageStyle> : <div className="d-flex justify-content-center align-items-center text-center mh-100vh-300">
    <div className="d-flex flex-column align-items-center">
      <SuccessIcon />
      <p className="w-250px fs-20px fw-400 py-4 ">Thank you! We’ll reach out to find a time to discuss</p>
      <Link
        to="#"
        className="btn btn-primary w-100px fs-16px"
        onClick={()=>{setShowSuccessMessage(false)}}
      >Ok</Link>
    </div>
  </div>
}

export default AskUsAnything;
