import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  chosenTagsAtom,
  CSMetricsAttributesAtom,
  customCompAtom,
  inProgressNewCustomCompAtom,
  metricSameStartAtom,
  needUpdateCompAtom,
  newCustomCompNameAtom,
  newCustomCompTypeAtom,
  searchCompanyArrayAtom,
  selectedSectorsAtom,
  stepOfCreationCompsAtom,
  timeframeEndDateAtom,
  timeframeStartDateAtom,
} from "../../atoms/profileAtom";
import { Link, useNavigate, useParams } from "react-router-dom";
import { APIHeaders, APIHeadersRAW } from "../../components/shared/helpers";
import axios from "axios";
import { TableChartWrapper } from "../Chart/TableChart";
import TitleCustomComp from "../../components/shared/TitleCustomComp";
import TitleTagCustomComp from "../../components/shared/TitleTagCustomComp";
import { WrapperCustomComp } from "../market-tracker/MarketTrackerTimeline";
import { AppSettings } from "../../config/app-settings";
import ListIcon from "../../components/shared/ListIcon";

const baseLogoURL = process.env.REACT_APP_LOGOS_BASE_URL;
const signature = process.env.REACT_APP_LOGOS_SIGNATURE;

const StoreListTable = ({ preview }) => {
  const context = useContext(AppSettings);
  const [customComp, setCustomComp] = useRecoilState(customCompAtom);
  const [needUpdateComp, setNeedUpdateComp] =
    useRecoilState(needUpdateCompAtom);
  const [compData, setCompData] = useState([]);
  console.log("compData in StoreListTable: ", compData);
  const [logoLink, setLogoLink] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [isLoadedComp, setIsLoadedComp] = useState(false);
  let { id } = useParams();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const chosenTags = useRecoilValue(chosenTagsAtom);
  const [inProgress, setInProgress] = useState(false);
  const navigate = useNavigate();
  const [compName, setCompName] = useRecoilState(newCustomCompNameAtom);
  const [compType, setCompType] = useRecoilState(newCustomCompTypeAtom);
  const [metricSameStart, setMetricSameStart] =
    useRecoilState(metricSameStartAtom);
  const [timeframeStartDate, setTimeframeStartDate] = useRecoilState(
    timeframeStartDateAtom
  );
  const [timeframeEndDate, setTimeframeEndDate] =
    useRecoilState(timeframeEndDateAtom);
  const [searchCompanyArray, setSearchCompanyArray] = useRecoilState(
    searchCompanyArrayAtom
  );
  const [selectedSectors, setSelectedSectors] =
    useRecoilState(selectedSectorsAtom);
  const [CSMetricsAttributes, setCSMetricsAttributes] = useRecoilState(
    CSMetricsAttributesAtom
  );
  const [stepOfCreationComps, setStepOfCreationComps] = useRecoilState(
    stepOfCreationCompsAtom
  );
  const [inProgressNewCustomComp, setInProgressNewCustomComp] = useRecoilState(
    inProgressNewCustomCompAtom
  );
  const fieldsList = [
    { slug: "name", name: "Store Name" },
    { slug: "city", name: "City" },
    { slug: "fullAddress", name: "Address" },
    { slug: "avgRating", name: "Google Rating" },
    { slug: "numReviews", name: "Google Reviews" },
    { slug: "status", name: "Open/Closed" },
    { slug: "openingDate", name: "Opening Date" },
  ];

  const getCustomComp = () => {
    setIsLoadedComp(false);
    const data = new FormData();
    const config = {
      method: "get",
      url: `${baseURL}/custom_comps/${id}?include_data=true`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
      data: data,
    };
    axios(config)
      .then((resp) => {
        setCustomComp(resp.data.data);
        // console.log(resp.data.data)
      })
      .finally(() => {
        setIsLoadedComp(true);
      });
  };

  const handleSaveCustomComps = (e, action) => {
    e.preventDefault();
    let _existingTags = [];
    let _newTags = [];
    chosenTags.map((tag) => {
      if (tag.id !== undefined) {
        _existingTags.push({ tag_id: tag.id });
      } else {
        _newTags.push({ label: tag.label });
      }
    });

    setInProgress(true);
    let _url = `${baseURL}/custom_comps/new`;
    if (action === "save") {
      _url = `${baseURL}/custom_comps`;
    }

    let _compType = "";
    if (compType === "Table") {
      _compType = "CustomComps::Table";
    } else if (compType === "Time Series") {
      _compType = "CustomComps::TimeSeries";
    } else if (compType === "Scatter Plot") {
      _compType = "CustomComps::ScatterPlot";
    } else if (compType === "Company Fundraising History") {
      _compType = "CustomComps::CompanyList";
    } else if (compType === "Sector Investment List") {
      _compType = "CustomComps::SectorList";
    } else if (compType === "Store List") {
      _compType = "CustomComps::StoreList";
    } else if (compType === "Firm Retail Investments") {
      _compType = "CustomComps::InvestorList";
    }

    let metricData = {};
    metricData["custom_comp"] = {
      name: compName,
      type: _compType,
      start_date: !metricSameStart ? timeframeStartDate : "",
      end_date: !metricSameStart ? timeframeEndDate : "",
      taggings_attributes: _existingTags,
      tags: _newTags,
      resource_ids:
        compType !== "Sector Investment List"
          ? searchCompanyArray.map((el) => el.id)
          : selectedSectors.map((el) => el.id),
      custom_comp_metrics_attributes:
        compType !== "Sector Investment List"
          ? CSMetricsAttributes.map((el) => {
              return {
                metric_id: el.metric_id,
                ts_chart_type: el.ts_chart_type,
                same_start: metricSameStart,
              };
            })
          : [],
      // } ) : [{metric_id: 1}],
    };
    // same_start: metricSameStart

    const config = {
      method: "post",
      url: _url,
      headers: APIHeadersRAW,
      withCredentials: true,
      credentials: "include",
      data: metricData,
    };
    axios(config)
      .then((resp) => {
        console.log(resp);
        if (resp.data) {
          setCustomComp(resp.data.data);
          setStepOfCreationComps(4);
        }
        if (action === "save") {
          // toasterNotify( 'Custom Comp was created', '', 'success' );
          navigate("/custom-comps", { replace: false });
          setInProgressNewCustomComp(false);
        }
        setInProgress(false);
      })
      .catch((error) => {
        console.log(error);
        setInProgress(false);
      });
  };

  useEffect(() => {
    context.handleSetAppHeaderNone(false);
    context.handleSetAppSidebarNone(false);
    // context.handleSetAppContentClass( 'mh-100vh-88' );
    context.handleSetAppTitle("Store List");
    context.handleSetAppIcon(<ListIcon />);
  }, []);

  useEffect(() => {
    if (customComp.comp_data !== undefined && customComp.comp_data.length > 0) {
      setCompData(customComp.comp_data);
      let _link = "";
      customComp.comp_data.length &&
        customComp.comp_data.find((el) => {
          if (el.company.logoFileName !== null) {
            _link = el.company.logoFileName;
          }
        });
      if (_link) {
        _link = baseLogoURL + _link + signature;
      }
      setLogoLink(_link);
    }
  }, [customComp]);

  useEffect(() => {
    if (!preview && !customComp.length) {
      getCustomComp();
    }
  }, [needUpdateComp]);

  function sortByField(arr, field, ascending = true) {
    return arr.slice().sort((a, b) => {
      let fieldA = a[field] || "";
      let fieldB = b[field] || "";
      let comparison = fieldA > fieldB ? 1 : fieldA < fieldB ? -1 : 0;
      return ascending ? comparison : -comparison;
    });
  }

  useEffect(() => {
    if (sortBy) {
      let _arr = sortByField(compData, sortBy, order === "asc");
      setCompData(_arr);
    }
  }, [sortBy, order]);

  if (!isLoadedComp && !preview)
    return (
      <div className="d-flex justify-content-center align-items-center text-center min-vh-100">
        <div className="spinner-border m-5" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );

  const GoogleRating = ({ rating }) => {
    if (rating > 0)
      return (
        <div>
          <meter
            value={rating}
            min="0"
            max="5"
            low="2"
            optimum="4.2"
            high="4"
          ></meter>
          <span className="mx-1">{rating}</span>
        </div>
      );

    return <>-</>;
  };

  const handleOrder = (by) => {
    if (sortBy === by) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setSortBy(by);
    }
  };

  return (
    <>
      {
        <TableChartWrapper className={preview ? "px-3 py-2" : ""}>
          <TitleTagCustomComp
            compName={customComp.name}
            preview={preview}
            isPopular={customComp.default_comp}
            taggings={customComp.taggings}
            ownerID={customComp.user_id}
            id={id}
          />

          <WrapperCustomComp>
            {logoLink && (
              <div className="d-flex mt-2">
                <div
                  className="d-flex align-items-center"
                  style={{
                    overflow: "hidden",
                    maxWidth: "180px",
                  }}
                >
                  <img
                    className="px-2"
                    src={logoLink}
                    alt={customComp.name}
                    width="100%"
                  />
                </div>
                <TitleCustomComp
                  compName={customComp.name}
                  preview={preview}
                  isPopular={customComp.default_comp}
                  taggings={customComp.taggings}
                  ownerID={customComp.user_id}
                  id={id}
                />
              </div>
            )}
            <div
              style={{
                maxHeight: "calc(100vh - 250px)",
                overflowX: "auto",
              }}
            >
              {compData.length > 0 && (
                <table className="table table-hover">
                  <thead>
                    <tr>
                      {fieldsList.map((entry) => (
                        <th
                          style={{
                            position: "sticky",
                            backgroundColor: "#f7f8fa",
                            top: "-1px",
                          }}
                          scope="col"
                          className="cursor-pointer"
                          onClick={() => handleOrder(entry.slug)}
                        >
                          <div className="d-flex align-items-center">
                            <span className="mx-2">{entry.name}</span>{" "}
                            {sortBy === entry.slug ? (
                              <i
                                className={
                                  order === "asc"
                                    ? "fa fa-caret-up gray-active"
                                    : "fa fa-caret-down gray-active"
                                }
                              ></i>
                            ) : (
                              <i className="fa fa-sort gray-inactive" />
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody
                    style={
                      {
                        // maxHeight: "calc(100vh - 250px)",
                        // overflowX: "auto"
                      }
                    }
                  >
                    {compData.map((entry, index) => {
                      // console.log(entry);
                      let _address = entry.googleMapUrl ? (
                        <a href={entry.googleMapUrl} target="_blank">
                          {entry.fullAddress}
                        </a>
                      ) : (
                        entry.fullAddress
                      );
                      return (
                        <tr key={`tr-${index}`} className="text-center">
                          <td>{entry.name}</td>
                          <td>{entry.city || "-"}</td>
                          <td>{_address || "-"}</td>
                          <td>
                            <GoogleRating rating={entry.avgRating} />
                          </td>
                          <td>{entry.numReviews || "-"}</td>
                          <td>
                            {entry.status
                              ? entry.status.replace(/_/g, " ")
                              : "-"}
                          </td>
                          <td>
                            {!!entry.openingDate &&
                              moment(entry.openingDate).format("MM-DD-yyyy")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
            {preview && (
              <div className="d-flex justify-content-end my-3">
                <Link
                  to="/new-comp-set/2"
                  className="fs-16px w-100px h-40px buttn-green-outline mx-3 text-center "
                >
                  BACK
                </Link>
                <Link
                  to="#"
                  className="fs-16px w-100px h-40px btn bg-green2 btn-outline-light"
                  onClick={(e) => handleSaveCustomComps(e, "save")}
                >
                  SAVE
                </Link>
              </div>
            )}
          </WrapperCustomComp>
        </TableChartWrapper>
      }
    </>
  );
};

export default StoreListTable;
