import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  companiesByInvestListAtom,
  companiesLimitAtom,
  companiesListInvestorAtom,
  searchCompanyArrayAtom,
  selectedInvestSectorAtom,
  showSelectCompaniesBySectorAtom
} from "../../atoms/profileAtom";
import { APIHeaders } from "../shared/helpers";
import axios from "axios";

const SelectCompanyByInvestor = () => {
	const [ showSelectCompaniesBySector, setShowSelectCompaniesBySector ] = useRecoilState( showSelectCompaniesBySectorAtom );
	const  selectedSector = useRecoilValue( selectedInvestSectorAtom );
	const companiesListInvestor = useRecoilValue( companiesListInvestorAtom );
	const companiesLimit = useRecoilValue( companiesLimitAtom );
	const [ searchCompanyArray, setSearchCompanyArray ] = useRecoilState( searchCompanyArrayAtom );
	const [ sectorsList, setSectorList ] = useRecoilState( companiesByInvestListAtom );
	const [ sectorName, setSectorName ] = useState( '' );
  const [ sortedFilteredCompanyList, setSortedFilteredCompanyList ] = useState([]);
	const baseURL = process.env.REACT_APP_BASE_URL;

	const getSectorList = () => {
		const config = {
			method: 'get',
			url: `${baseURL}/investor_types`,
			headers: APIHeaders,
			withCredentials: true,
			credentials: "include",
		};
		axios( config )
			.then( ( response ) => {
				// console.log( response.data.data )
				setSectorList( response.data.data );
			} )
			.catch( ( error ) => {
				console.log( error.response.status );
			} ).finally( () => {
		} )
	}

	const handleCheckOption = ( e ) => {

		searchCompanyArray?.map( el => {
			if ( searchCompanyArray.map( el => el.id ).includes( e.target.value ) ) {
				let _filtered = searchCompanyArray.filter( item => item.id !== e.target.value );
				setSearchCompanyArray( _filtered )
			} else {
				let _temp = searchCompanyArray.concat( { id: e.target.value, name: e.target.name } );
				setSearchCompanyArray( _temp );
			}
		} );

		if ( searchCompanyArray.length === 0 ) setSearchCompanyArray( [ { id: e.target.value, name: e.target.name } ] )
	}

	useEffect( () => {
		if ( !sectorsList.length ) {
			getSectorList();
		}
		companiesListInvestor.map( ( comp ) => {
			if ( comp.investorTypes !== null ) {
        comp.investorTypes.map(types=> {
          if ( types !== null && types.id === +selectedSector) {
            setSectorName( types.name );
          }
        })
			}
		} )

	}, [ selectedSector ] )

  useEffect( () => {
    let _sortedFilteredCompanyList = [];
    sectorsList.map( ( sect ) => sect )
      .filter( ( sect ) => sect.name === sectorName ).map( comp => {
      comp.investors.map( el => {
        _sortedFilteredCompanyList.push( el )
      } );
    } )
    _sortedFilteredCompanyList.sort( function ( a, b ) {
      if ( a.name.toLowerCase() > b.name.toLowerCase() ) {
        return 1;
      }
      if ( a.name.toLowerCase() < b.name.toLowerCase() ) {
        return -1;
      }
      return 0;
    } )

    setSortedFilteredCompanyList(_sortedFilteredCompanyList)

  }, [ sectorName ] )


	return (
		<Modal show={showSelectCompaniesBySector} onHide={() => setShowSelectCompaniesBySector( false )} centered>
			<Modal.Header closeButton>
				<Modal.Title>{sectorName}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="fs-14px" style={{columns: '2'}}>
					{sortedFilteredCompanyList.map( ( el, i ) => {
							return <div className="form-check mb-2 w-250px" key={"select-" + el.name + i}>
								<input
									className="form-check-input"
									type="checkbox"
									checked={searchCompanyArray.map( comp => comp.name ).includes( el.name )}
									disabled={searchCompanyArray.length >= companiesLimit && !searchCompanyArray.map( comp => comp.name ).includes( el.name )}
									onChange={handleCheckOption}
									id={`select-checkbox-${el.id}`}
									name={el.name}
									value={el.id} />
								<label
									className="form-check-label mx-2"
									htmlFor={`select-checkbox-${el.id}`}>{el.name}
								</label>
							</div>
						  })}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
          type="submit"
          className="fs-16px btn w-100px text-light bg-green2 text-uppercase"
          onClick={() => setShowSelectCompaniesBySector( false )}
        >Select</button>
			</Modal.Footer>
		</Modal>
	)
}

export default SelectCompanyByInvestor;
