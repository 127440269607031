import { useRecoilState, useRecoilValue } from "recoil";
import {
  chosenMetricsAtom,
  companiesLimitAtom,
  CSMetricsAttributesAtom,
  inProgressNewCustomCompAtom,
  inProgressNewCustomCompDialogAtom,
  inProgressNewCustomCompTypeAtom,
  linkToMenuItemAtom,
  newCustomCompNameAtom,
  newCustomCompTypeAtom,
  searchCompanyArrayAtom,
  selectedSectorsAtom,
  stepOfCreationCompsAtom,
  timeframeSelectedLabelAtom,
} from "../../atoms/profileAtom";
import SweetAlert from "sweetalert-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const CustomConfirm = () => {
  const [showModal, setShowModal] = useRecoilState(
    inProgressNewCustomCompDialogAtom
  );
  const [inProgressNewCustomComp, setInProgressNewCustomComp] = useRecoilState(
    inProgressNewCustomCompAtom
  );
  const [linkToMenuItem, setLinkToMenuItem] =
    useRecoilState(linkToMenuItemAtom);
  const [inProgressNewCustomCompType, setInProgressNewCustomCompType] =
    useRecoilState(inProgressNewCustomCompTypeAtom);
  const [compName, setCompName] = useRecoilState(newCustomCompNameAtom);
  const [compType, setCompType] = useRecoilState(newCustomCompTypeAtom);
  const [stepOfCreationComps, setStepOfCreationComps] = useRecoilState(
    stepOfCreationCompsAtom
  );
  const [searchCompanyArray, setSearchCompanyArray] = useRecoilState(
    searchCompanyArrayAtom
  );
  const [chosenMetrics, setChosenMetrics] = useRecoilState(chosenMetricsAtom);
  const [CSMetricsAttributes, setCSMetricsAttributes] = useRecoilState(
    CSMetricsAttributesAtom
  );
  const [selectedSectors, setSelectedSectors] =
    useRecoilState(selectedSectorsAtom);
  const [timeframeSelectedLabel, setTimeframeSelectedLabel] = useRecoilState(
    timeframeSelectedLabelAtom
  );
  const [companiesLimit, setCompaniesLimit] =
    useRecoilState(companiesLimitAtom);
  const navigate = useNavigate();

  const goToLink = () => {
    setShowModal(false);
    setInProgressNewCustomComp(false);
    // setCompName( '' );
    setCompType("");
    setStepOfCreationComps(1);
    setSearchCompanyArray([]);
    setChosenMetrics([]);
    setCSMetricsAttributes([]);
    setSelectedSectors([]);
    setTimeframeSelectedLabel("");
    setCompaniesLimit(3);
    navigate(linkToMenuItem, { replace: false });
  };

  const handleCancelAction = () => {
    setShowModal(false);
  };

  return (
    <SweetAlert
      show={showModal}
      title={`Are you sure you want to go to this page without saving ${inProgressNewCustomCompType}?`}
      type="error"
      confirmButtonText="Do Not Save"
      showCancelButton={true}
      onCancel={() => handleCancelAction()}
      confirmButtonColor="rgb(255, 91, 87)"
      onConfirm={() => goToLink()}
    />
  );
};

export default CustomConfirm;
