import { useParams, Outlet } from "react-router-dom";
import NewCompSetStep1 from "./NewCompSetStep1";
import NewCompSetStep2 from "./NewCompSetStep2";
import NewCompSetStep3 from "./NewCompSetStep3";
import CombineChart from "../Chart/CombineChart";

function NewCompSetStepOutlet() {
  const { step } = useParams();

  switch (step) {
    case "1":
      return <NewCompSetStep1 />;
    case "2":
      return <NewCompSetStep2 />;
    case "3":
      return <NewCompSetStep3 />;
    case "4":
      return <CombineChart preview={true} />;
    default:
      return <Outlet />;
  }
}

export default NewCompSetStepOutlet;
