import React from "react";

const MediaIcon = ({ color, size }) => {
  let _size = 50;
  let fillColor = "#c897c3";
  if (color) {
    fillColor = color;
  }
  if (size) _size = size;

  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3889 13.8866V10.608L15.7406 8.08346L12.4061 11.2645L15.1579 13.8866H18.3889ZM7.35168 13.8866H14.3668L9.55782 9.3042L7.35168 11.407V13.8866ZM9.70284 6.33498C9.70287 6.09101 9.77878 5.85254 9.92098 5.6497C10.0632 5.44687 10.2653 5.28878 10.5017 5.19544C10.7382 5.10209 10.9983 5.07767 11.2493 5.12527C11.5003 5.17287 11.7309 5.29035 11.9118 5.46286C12.0928 5.63537 12.216 5.85515 12.266 6.09442C12.3159 6.3337 12.2903 6.58171 12.1924 6.8071C12.0944 7.0325 11.9286 7.22516 11.7158 7.36071C11.5031 7.49626 11.2529 7.56863 10.997 7.56865C10.6539 7.56834 10.3249 7.43826 10.0823 7.20697C9.83963 6.97568 9.70317 6.66207 9.70284 6.33498ZM10.2622 6.33498C10.2622 6.47348 10.3053 6.60887 10.386 6.72403C10.4667 6.83919 10.5814 6.92896 10.7157 6.98197C10.8499 7.03498 10.9976 7.04886 11.1401 7.02185C11.2826 6.99485 11.4135 6.92816 11.5162 6.83024C11.619 6.73232 11.689 6.60755 11.7173 6.47171C11.7457 6.33588 11.7312 6.19508 11.6756 6.06711C11.62 5.93914 11.5259 5.82976 11.4051 5.7528C11.2843 5.67583 11.1423 5.63473 10.997 5.63471C10.8022 5.63492 10.6155 5.70876 10.4777 5.84004C10.34 5.97131 10.2625 6.1493 10.2622 6.33498Z"
        fill="#94428B"
      />
      <path
        d="M5.95831 15.164V2.03906H19.7082V15.164H5.95831ZM7.33326 13.8515H18.3333V3.35154H7.33326V13.8515ZM2.75 18.2265V5.3708H4.12498V16.914H16.2179V18.2265H2.75Z"
        fill="#94428B"
      />
    </svg>
  );
};

export default MediaIcon;
