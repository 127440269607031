import React, { useEffect, useState } from 'react';
import { APIHeaders } from "../../components/shared/helpers";
import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";
import { sectorsLimitAtom, selectedSectorsAtom } from "../../atoms/profileAtom";
import styled from "styled-components";
import { PublicTagStyle } from "../../components/modals/SelectCompanyBySector";

const SubSectorListWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 140px);
  padding-bottom: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  // overflow: hidden;
`;

const CompaniesTooltip = styled.div`
  position: fixed;
  top: ${props => props.position.y + 'px'};
  left: ${props => props.position.x + 'px'};
  padding: 10px 0;
  border: 1px solid #D6D6D6;
  border-radius: 5px;
  z-index: 30;
  width: ${props => props.count < 6 ? "160px" : '360px'};
  background-color: #fff;
  display: ${props => props.show ? 'block' : 'none'};

  h5 {
    padding-left: 10px;
  }

  .companies {
    columns: ${props => props.count < 6 ? "1" : "2"}
  }

  .title {
    width: 150px;
    padding: 2px 10px;
  }
`;

const SubSectorListInner = styled.div`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  //height: calc(100vh - 300px);
  margin-top: 12px;
`;

const SubSectorsListStyle = styled.div`
  width: 100%;
  padding-top: 5px;
  columns: ${props => props.count > 30 ? Math.round( props.count / 40 ) : '1'};
  padding-left: 5px;
  font-size: 14px;

  @media (max-width: 1500px) {
    columns: ${props => props.count > 30 ? '2' : '1'};
    padding-bottom: 26px;
  }
`;

const SubSectorsList = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [ isSectorsListLoaded, setIsSectorsListLoaded ] = useState( false );
  const [ subSectorsList, setSubSectorsList ] = useState( [] );
  const [ sortedSubSectorsList, setSortedSubSectorsList ] = useState( [] );
  const [ searchSector, setSearchSector ] = useState( '' );
  const [ showTooltipID, setShowTooltipID ] = useState( '' );
  const [ positionTooltip, setPositionTooltip ] = useState( [] );
  const [ foundSector, setFoundSector ] = useState( [] );
  const [ selectedSectors, setSelectedSectors ] = useRecoilState( selectedSectorsAtom );
  const sectorsLimit = useRecoilValue( sectorsLimitAtom );

  const getSubSectorsList = () => {
    setIsSectorsListLoaded( false );
    const config = {
      method: 'get',
      url: `${baseURL}/sub_sectors?all=true`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios( config )
      .then( ( resp ) => {
        setSubSectorsList( resp.data.data );
        setIsSectorsListLoaded( true );
        // console.log( resp.data.data )
      } )
  }

  const showTooltip = ( e, id, countBrands ) => {
    let _deltaY = 0;
    let _heightBlock = 20 + 18 + (countBrands * 22);
    if ( (_heightBlock + e.clientY) > window.innerHeight && countBrands < 10 ) {
      _deltaY = window.innerHeight - (_heightBlock + e.clientY) - 10;
    } else if ( (_heightBlock + e.clientY) > window.innerHeight && countBrands > 10 ) {
      _deltaY = window.innerHeight - (_heightBlock / 2 + e.clientY) - 40;
    }
    setPositionTooltip( { "x": e.clientX + 15, "y": e.clientY + _deltaY } );
    setShowTooltipID( id );
  }

  useEffect( () => {
    let _filteredSectors = [];
    _filteredSectors = subSectorsList.filter( sector => sector.companies.length > 0 );

    _filteredSectors.sort( function ( a, b ) {
      if ( a.name.toLowerCase() > b.name.toLowerCase() ) {
        return 1;
      }
      if ( a.name.toLowerCase() < b.name.toLowerCase() ) {
        return -1;
      }
      return 0;
    } )

    setSortedSubSectorsList( _filteredSectors )

  }, [ subSectorsList ] )

  useEffect( () => {
    if ( !isSectorsListLoaded ) {
      getSubSectorsList()
    }
  }, [ isSectorsListLoaded ] )

  const handleCheckOption = ( e ) => {
    selectedSectors?.map( el => {
      if ( selectedSectors.map( el => el.id ).includes( e.target.value ) ) {
        let _filtered = selectedSectors.filter( item => item.id !== e.target.value );
        setSelectedSectors( _filtered )
      } else {
        let _temp = selectedSectors.concat( { id: e.target.value, name: e.target.name, isPublic: e.target.dataset.ispublic === 'true' } );
        setSelectedSectors( _temp );
      }
    } );

    if ( selectedSectors.length === 0 ) setSelectedSectors( [ { id: e.target.value, name: e.target.name, isPublic: e.target.dataset.ispublic === 'true' } ] )
  }

  const handleSearchSector = ( e ) => {
    setSearchSector( e.target.value );
  }

  useEffect( () => {
    let _found = [];
    subSectorsList.map( ( comp ) => {
      if ( comp.name.toUpperCase().indexOf( searchSector.toUpperCase() ) >= 0 ) {
        _found.push( comp )
      }
    } );
    setFoundSector( _found );
    setSortedSubSectorsList( _found );
  }, [ searchSector ] )

  if ( !isSectorsListLoaded ) {
    return <div className="d-flex justify-content-center align-items-center text-center min-vh-100">
      <div className="spinner-border m-5" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  }

  return <SubSectorListWrapper className="px-3">
    <h3 className="fw-400">Search Sectors</h3>
    <p className="fs-14px">Start typing below to search and filter through sector list</p>
    <div className="col-12 py-1 h-50px">
      {(subSectorsList.length) ? <div className="position-relative">
          <input
            type="text"
            value={searchSector}
            disabled={selectedSectors.length >= sectorsLimit}
            onChange={handleSearchSector}
            className="form-control w-100"
            placeholder="Search" />
          {searchSector && <i onClick={() => setSearchSector( '' )} className="fa fa-times-circle clear-field-button"></i>}
        </div> :
        <div className="d-flex justify-content-center align-items-center text-center">
          <div className="spinner-border m-5" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>}
    </div>
    <hr/>
    <h3 className="fw-400">Choose Sector</h3>
    <p className="fs-14px mt-2">Add sectors to your list by browsing through the sector lists below</p>
    <SubSectorListInner>
      <SubSectorsListStyle count={sortedSubSectorsList.filter( sector => sector.companies.length ).length}>
        {sortedSubSectorsList.length ? sortedSubSectorsList.map( ( sector, i ) => {
          let _countBrands = sector.companies.length;
          let _sortedCompanies = sector.companies.sort( function ( a, b ) {
            if ( a.name.toLowerCase() > b.name.toLowerCase() ) {
              return 1;
            }
            if ( a.name.toLowerCase() < b.name.toLowerCase() ) {
              return -1;
            }
            return 0;
          } )

          if ( _countBrands ) return <div
            className="form-check mb-2 w-250px position-relative"
            key={"select-" + sector.name + i}
            onMouseEnter={( e ) => showTooltip( e, sector.name, _countBrands )}
            onMouseLeave={() => setShowTooltipID( '' )}
          >
            <input
              className="form-check-input"
              type="checkbox"
              checked={selectedSectors.map( comp => comp.name ).includes( sector.name )}
              disabled={selectedSectors.length >= sectorsLimit && !selectedSectors.map( comp => comp.name ).includes( sector.name )}
              onChange={handleCheckOption}
              id={`select-checkbox-${sector.id}`}
              name={sector.name}
              value={sector.id} />
            <label
              className="form-check-label"
              htmlFor={`select-checkbox-${sector.id}`}>{sector.name} ({_countBrands})
            </label>
            <CompaniesTooltip
              count={_countBrands}
              show={showTooltipID === sector.name}
              position={positionTooltip}
            >
              <h5>{sector.name}</h5>
              <div className="companies">
                {_sortedCompanies.map( company => {
                  return <div className="title" key={company.name}>{company.name}{company.isPublic && <PublicTagStyle>public</PublicTagStyle>}</div>
                } )
                }
              </div>
            </CompaniesTooltip>
          </div>
        } ) : <>Sectors not found</>}
      </SubSectorsListStyle>
    </SubSectorListInner>
  </SubSectorListWrapper>
}

export default SubSectorsList;
