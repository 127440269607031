import styled from "styled-components";
import {
  generateRandomColor,
  generateRandomPastelColor,
} from "../../components/shared/helpers";

const randomColorsList = () => {
  let _colorList = [];

  for (let i = 0; i < 200; i++) {
    _colorList.push(generateRandomPastelColor());
  }
  return _colorList;
};

export const colorVariantsShort = [
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
];

export const colorVariantsCompany = [
  "#b1d8d3",
  "#eee2ed",
  "#d0e1ee",
  // '#207a6a',
  "#c287ba",
  "#78bbe7",
  "#858d15",
  // '#e31bd2',
  "#92a0a9",
  "#d0e1ef",
  "#209a61",
  "#d284ba",
  "#78bbe9",
  "#857d15",
  // '#e30bd2',
  ...randomColorsList(),
];

export const SortDropdown = styled.div`
  top: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;1px solid #EAECF0;
  width: 145px;
  position: absolute;
  border-radius: 10px;
  z-index: 3;
  background-color: #fff;
  box-shadow: 0 1px 8px -0.5px rgba(0, 0, 0, 0.14);
`;

export const SortDropdownOptionStyle = styled.div`
  cursor: pointer;
  padding: 8px 8px 8px 12px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  &:nth-of-type(1) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:nth-last-of-type(1) {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const CompaniesBySectorContainer = styled.div`
  border-radius: 8px;
  padding: 15px;
  z-index: 333;
  position: absolute;
  top: 110px;
  background-color: #fff;
  box-shadow: 0 1px 5px 0.5px rgba(0, 0, 0, 0.14);
`;

export const FilterByComponent = styled.div`
  border-radius: 20px;
  padding: 20px 30px;
  border: 1px solid #eaecf0;
  background-color: #fff;
  margin-bottom: 30px;
  box-shadow: 0 1px 8px -0.5px rgba(0, 0, 0, 0.14);
  font-size: 14px;

  p {
    color: rgba(101, 106, 107, 1);

    span {
      color: rgba(30, 30, 30, 1);
    }
  }

  .link-button {
    border-bottom: 1px solid rgba(227, 227, 227, 1);
    padding: 0 0 13px 0;
    display: flex;

    a {
      font-size: 20px;
      text-decoration: none;
      color: rgba(30, 30, 30, 1);
      margin-right: 40px;
      position: relative;
      transition: all 0.3s;

      &:hover {
        color: rgba(30, 30, 30, 0.7);
      }

      &:after {
        display: block;
        content: "";
        width: 100%;
        height: 3px;
        background-color: rgba(71, 143, 131, 0);
        position: relative;
        top: auto;
        bottom: -15px;
        transition: all 0.3s;
      }

      &.active {
        color: #b668ae;
      }

      &.active:after {
        background-color: #b668ae;
      }
    }
  }

  .form-control {
    font-weight: 400;
    border-radius: 10px;
    font-size: 16px;
  }

  .clear-field-button {
    right: -22px;
  }
`;

export const ItemsGridWrapper = styled.div`
  border-radius: 20px;
  padding: 30px 30px 20px 30px;
  border: 1px solid #eaecf0;
  background-color: #fff;
  //margin-bottom: 30px;
  box-shadow: 0 1px 8px -0.5px rgba(0, 0, 0, 0.14);
`;

export const ItemGrid = styled.div`
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 20px;
  break-inside: avoid;
  //background-color: rgba(177, 216, 211, 1);
  border-radius: 20px;
  padding: 12px 12px 12px 12px;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 20px;
    margin-top: -20px;
  }

  & > img {
    grid-row: 1 / -1;
    grid-column: 1;
  }
`;

export const SearchedCompanyList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;

export const SearchedItem = styled.div`
  padding: 5px 12px;
  border-radius: 8px;
  margin-right: 24px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 400;
  //color: rgba(16, 24, 40, 1);
  color: black;
  height: 40px;
  display: flex;
  align-items: center;
  opacity: 0.7;

  .remove-item {
    display: block;
    margin-left: 10px;
    position: relative;
    width: 12px;
    height: 12px;
    opacity: 1;

    &:hover {
      opacity: 0.7;
    }

    &:after,
    &:before {
      display: block;
      content: "";
      width: 12px;
      height: 1px;
      background: rgba(16, 24, 40, 1);
      z-index: -1;
    }

    &:after {
      transform: translate(1px, 5px) rotate(45deg);
    }

    &:before {
      transform: translate(1px, 6px) rotate(-45deg);
    }
  }
`;

export const MetricTitle = styled.div`
  position: absolute;
  top: 6px;
  left: 10px;
  border-radius: 20px;
  padding: 15px;
  //background-color: #CCE6E2;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p,
  h4 {
    text-align: right;
  }

  p {
    color: rgba(101, 106, 107, 1);
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

export const ImageContainer = styled.div`
  background-color: #fff;
  border-radius: 20px;
  // margin-top: 20px;
  // padding: 20px;
  cursor: pointer;
  overflow: hidden;
  .img {
    border-radius: 20px;
  }
`;

export const SearchCompanyOptionsWrapper = styled.div`
  border-radius: 8px;
  padding: 15px;
  z-index: 333;
  position: absolute;
  top: 110px;
  background-color: #fff;
  box-shadow: 0px 1px 5px 0.5px rgba(0, 0, 0, 0.14);
`;

export const CompaniesByLocationContainer = styled.div`
  border-radius: 8px;
  padding: 15px;
  z-index: 333;
  //top: 34%;
  position: absolute;
  max-height: 450px;
  overflow-y: scroll;
  background-color: #fff;
  box-shadow: 0px 1px 5px 0.5px rgba(0, 0, 0, 0.14);
`;

export const LogosToggle = styled.div`
  position: relative;
  width: 100%;

  @media screen and (min-width: 992px) {
    width: 160px;
    //position: absolute;
    left: auto;
    right: 0;
  }
`;

export const ListCompaniesBySector = styled.div`
  padding: 10px 10px 10px 36px;
  display: flex;
  flex-wrap: wrap;
  //flex-direction: column;
  max-height: 330px;
  overflow-y: auto;
  width: 100%;

  .form-check-label {
    margin: 0 12px;
  }
`;

export const ListMetricsByCategory = styled.div`
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  //flex-direction: column;
  max-height: 330px;
  overflow-y: auto;
  //width: 100%;
  width: 445px;
  box-shadow: 0px 1px 5px 0.5px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  top: calc(100% + 4px);
  z-index: 333;

  .form-check-label {
    margin: 0 12px;
  }
`;

export const LogoWrapperSmall = styled.div`
  width: 32px;
  height: 32px;
  overflow: hidden;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
`;
