import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from "./Modal"; // Import the modal component

const API_URL = process.env.REACT_APP_API_URL;

const SaveToListImageContainer = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

const Overlay = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 1);
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  border-radius: 8px;
  // padding: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15); /* New box shadow */
  min-width: 34px;
  min-height: 34px;
  align-items: center;
  justify-content: center;
`;

const SaveIconButton = styled.button`
  background: #fff;
  border: none;
  font-size: 24px;
  min-width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  padding: 12px;
`;

const SaveLabel = styled.span`
  font-size: 14px;
  margin-right: 8px;
`;

const SaveIcon = styled.span`
  font-size: 14px;
`;

const Selector = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DropdownItem = styled.span`
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
  min-height: 36px;
  padding: 0 8px;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const SavedCheck = styled.span`
  color: #4caf50;
  font-size: 18px;
  // padding: 0px 8px;
  margin-left: auto;
`;

const Title = styled.div`
  padding: 8px;
  font-size: 16px;
  text-align: center;
`;

const SavedLibTitle = styled.span`
  font-size: 14px;
  color: black;
  padding: 8px;
`;

const SaveToListImage = ({ imageSrc, title, companyId, onClick }) => {
  console.log("imageSrc: ", imageSrc);
  const userId = localStorage.getItem("id") ?? "";
  const [hovered, setHovered] = useState(false);
  const [lists, setLists] = useState([]);
  console.log("lists: ", lists);
  const [savedListIds, setSavedListIds] = useState(new Set()); // Track which lists have this image saved
  const [showSavedListName, setShowSavedListName] = useState(false);

  const [selectedList, setSelectedList] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  console.log("showDropdown: ", showDropdown);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [newListName, setNewListName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showSaveLabel, setShowSaveLabel] = useState(false);
  console.log("showModal: ", showModal);

  useEffect(() => {
    const fetchLists = async () => {
      try {
        const response = await fetch(`${API_URL}/lists/${userId}`);
        const data = await response.json();
        setLists(data);

        // Check if the image is already saved in any lists and update the savedListIds set
        const initialSavedListIds = new Set(
          data
            .filter((list) => list.mediaUrls.includes(imageSrc))
            .map((list) => list.id)
        );
        setSavedListIds(initialSavedListIds);
        if (initialSavedListIds.size > 0) {
          setSaved(true);
        }
      } catch (error) {
        console.error("Error fetching lists:", error);
      }
    };
    fetchLists();
  }, [imageSrc, userId]);

  const handleSave = async () => {
    console.log("lists.length: ", lists.length);
    if (lists.length === 0) {
      // Open modal if no lists exist
      setShowModal(true);
      return;
    }

    if (!selectedList) return;

    setSaving(true);
    try {
      // Save to the selected list
      await fetch(`${API_URL}/list/${selectedList}/media`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: title, url: imageSrc }),
      });

      setSaved(true);
      setShowDropdown(false);
    } catch (error) {
      console.error("Error saving to list:", error);
    } finally {
      setSaving(false);
    }
  };

  const handleCreateList = async () => {
    if (!newListName) return;

    setSaving(true);
    try {
      // Pass `imageSrc` in the body to be stored in `mediaUrls` when creating the new list
      const response = await fetch(`${API_URL}/list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: newListName,
          userId,
          mediaUrls: [imageSrc], // Initial media URL added to the mediaUrls array
        }),
      });
      const newList = await response.json();
      setLists([...lists, newList]);
      setSavedListIds(
        (prevSavedListIds) => new Set([...prevSavedListIds, newList.id])
      );
      setSaved(true);
      setShowModal(false);
      setNewListName(""); // Reset the new list name after creation
    } catch (error) {
      console.error("Error creating new list:", error);
    } finally {
      setSaving(false);
    }
  };

  const handleSaveClick = () => {
    setShowDropdown(!showDropdown);
    if (lists.length === 0) {
      setShowModal(true);
    }
  };

  const updateListMedia = async (listId, action) => {
    try {
      await fetch(`${API_URL}/list/${listId}/media`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url: imageSrc, action }),
      });

      // Update the local state to reflect the action taken
      setSavedListIds((prevSavedListIds) => {
        const updatedSavedListIds = new Set(prevSavedListIds);
        if (action === "add") {
          updatedSavedListIds.add(listId);
          setSaved(true);
        } else if (action === "remove") {
          setSaved(false);
          updatedSavedListIds.delete(listId);
        }
        return updatedSavedListIds;
      });
    } catch (error) {
      console.error("Error updating media URLs:", error);
    }
  };

  console.log("savedListIds: ", savedListIds);
  const handleListClick = (listId) => {
    // Check if this image is already saved in this list
    const isSavedInList = savedListIds.has(listId);

    // Call updateListMedia with the appropriate action
    const action = isSavedInList ? "remove" : "add";
    updateListMedia(listId, action);
  };

  return (
    <SaveToListImageContainer
      // onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => {
        setHovered(false);
        setShowDropdown(false);
      }}
    >
      <Image src={imageSrc} alt={title} />
      {hovered && (
        // {true && (
        <Overlay>
          {/* {!saved ? ( */}
          <>
            {!showDropdown ? (
              <SaveIconButton
                onClick={handleSaveClick}
                onMouseEnter={() => setShowSaveLabel(true)}
                onMouseLeave={() => setShowSaveLabel(false)}
              >
                {showSaveLabel && savedListIds.size >= 1 ? (
                  <SavedLibTitle>
                    {lists.find((l) => l.id === [...savedListIds][0]).title}{" "}
                  </SavedLibTitle>
                ) : showSaveLabel ? (
                  <SaveLabel>Save</SaveLabel>
                ) : null}
                {/* {showSaveLabel ? <SaveLabel>Save</SaveLabel> : null} */}
                {savedListIds.size >= 1 ? (
                  <SavedCheck>
                    <i className="fa fa-check-circle fs-18px"></i>
                  </SavedCheck>
                ) : (
                  <SaveIcon>+</SaveIcon>
                )}
              </SaveIconButton>
            ) : null}
            {showDropdown && (
              <Selector>
                {lists.map((list) => (
                  <DropdownItem
                    key={list.id}
                    // onClick={() => setSelectedList(list.id)}
                    onClick={() => handleListClick(list.id)}
                  >
                    {list.title}
                    {savedListIds.has(list.id) ? (
                      <SavedCheck>
                        <i className="fa fa-check-circle fs-18px"></i>
                      </SavedCheck>
                    ) : null}
                  </DropdownItem>
                ))}
                <DropdownItem onClick={() => setShowModal(true)}>
                  + Create New List
                </DropdownItem>
                {/* <button
                    onClick={handleSave}
                    disabled={!selectedList || saving}
                  >
                    {saving ? "Saving..." : "Save"}
                  </button> */}
              </Selector>
            )}
          </>
          {/* ) : (
            <SavedCheck
              onMouseEnter={() => setShowSavedListName(true)}
              onMouseLeave={() => setShowSavedListName(false)}
              onClick={() => setShowDropdown(true)}
            >
              <i className="fa fa-check-circle fs-18px"></i>
            </SavedCheck>
            // <SavedCheck>✔ Saved</SavedCheck>
          )} */}
        </Overlay>
      )}
      <Title>{title}</Title>

      {/* Render the modal for creating a new list */}
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSave={handleCreateList}
        newListName={newListName}
        setNewListName={setNewListName}
      />
    </SaveToListImageContainer>
  );
};

export default SaveToListImage;
