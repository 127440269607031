import React, { useContext, useEffect, useState } from "react";
import { AppSettings } from "../../config/app-settings";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  customCompAtom,
  locationsListAtom,
  locationsLoadedListAtom,
  needUpdateCompAtom,
  searchCompanyArrayAtom,
  trackerIDAtom,
} from "../../atoms/profileAtom";
import { APIHeaders, kitcut } from "../../components/shared/helpers";
import axios from "axios";
import moment from "moment";
import {
  LogoName,
  LogoNameTimeline,
  TableChartTimelineWrapper,
  TableChartWrapper,
} from "../Chart/TableChart";
import TitleCustomComp from "../../components/shared/TitleCustomComp";
import { remapResourceIDs } from "./MarketTrackerMap";
import CustomCompIcon from "../../components/shared/CustomCompIcon";
import TitleTagCustomComp from "../../components/shared/TitleTagCustomComp";
import styled from "styled-components";
import { colorPatternDefault } from "../../components/shared/Colors";
import { OverlayTrigger, Popover } from "react-bootstrap";

export const WrapperCustomComp = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 16px 16px 5px 16px;
`;

function hexToRGBA(hex, alpha) {
  // Remove '#' if present
  hex = hex.replace("#", "");

  // Parse hex values to RGB
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Return RGBA color
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

const renderTooltip = (text) => (
  <Popover
    id="popover-contained-bottom"
    style={{ color: "black", background: "white", fontSize: "14px" }}
  >
    {text}
  </Popover>
);

const remapMarketTrackerTimeLine = (data) => {
  console.log('data inside remapMarketTrackerTimeLine: ', data);
  let _data = [];

  data.map((loc) => {
    if (loc.openingDate) {
      let _times = 1;
      let _timesText = "";
      let _year = moment(loc.openingDate).year();
      console.log('_year: ', _year);
      let _month = moment(loc.openingDate).format("MMM");
      let _monthNumber = moment(loc.openingDate).format("MM");
      console.log('_data: ', _data);
      _data.map((entry) => {
        if (entry.city === loc.city && entry.year === _year) {
          _data =
            _data.filter(
              (el) => el.city !== entry.city && entry.year === _year
            ) ?? _data;
          _times = entry.times + 1;
        }
      });
      _timesText = _times > 1 ? " (" + _times + ")" : "";
      let _entry = {
        company_id: loc.company.id,
        company_name: loc.company.name,
        year: _year,
        month: +_monthNumber,
        city: loc.city,
        times: _times,
        entry_data: _month + " / " + loc.city + _timesText,
      };
      _data.push(_entry);
      // console.log( _entry );
    }
  });

  return _data;
};

const monthsMap = [
  {
    order: 1,
    name: "Jan",
  },
  {
    order: 2,
    name: "Feb",
  },
  {
    order: 3,
    name: "Mar",
  },
  {
    order: 4,
    name: "Apr",
  },
  {
    order: 5,
    name: "May",
  },
  {
    order: 6,
    name: "Jun",
  },
  {
    order: 7,
    name: "Jul",
  },
  {
    order: 8,
    name: "Aug",
  },
  {
    order: 9,
    name: "Sept",
  },
  {
    order: 10,
    name: "Oct",
  },
  {
    order: 11,
    name: "Nov",
  },
  {
    order: 12,
    name: "Dec",
  },
];

const MarketTrackerTimeline = ({ preview }) => {
  const [customComp, setCustomComp] = useRecoilState(customCompAtom);
  const [trackerID, setTrackerID] = useRecoilState(trackerIDAtom);
  const [loadedList, setLoadedList] = useRecoilState(locationsLoadedListAtom);
  const [searchCompanyArray, setSearchCompanyArray] = useRecoilState(
    searchCompanyArrayAtom
  );
  const [needUpdateComp, setNeedUpdateComp] =
    useRecoilState(needUpdateCompAtom);
  const [loadedLocationList, setLoadedLocationList] = useState(false);
  const [locationList, setLocationList] = useRecoilState(locationsListAtom);
  console.log("locationList: ", locationList);
  const [isLoadedComp, setIsLoadedComp] = useState(false);
  const [compName, setCompName] = useState("");
  const [remappedMarketTrackerData, setRemappedMarketTrackerData] = useState(
    []
  );
  console.log("remappedMarketTrackerData: ", remappedMarketTrackerData);
  const [yearsList, setYearsList] = useState([]);
  console.log("yearsList: ", yearsList);
  const [resourceIDs, setResourceIDs] = useState([]);
  const context = useContext(AppSettings);
  const [companiesList, setCompaniesList] = useState([]);
  const [isHideCompaniesId, setIsHideCompaniesId] = useState([]);
  const [needUpdateTable, setNeedUpdateTable] = useState(false);
  const [showLogo, setShowLogo] = useState(true);
  const [noLocations, setNoLocations] = useState(true);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();

  useEffect(() => {
    context.handleSetAppHeaderNone(false);
    context.handleSetAppSidebarNone(false);
    context.handleSetAppContentClass("mh-100vh-88");
    context.handleSetAppTitle("Market Tracker");
    context.handleSetAppIcon(<CustomCompIcon />);
    setResourceIDs([]);
    setRemappedMarketTrackerData([]);
    setLocationList([]);
    setCompaniesList([]);
    setIsLoadedComp(false);
    setLoadedLocationList(false);
    setYearsList([]);
  }, []);

  useEffect(() => {
    // if ( id !== trackerID || preview ) {
    if (id !== trackerID && !preview) {
      setSearchCompanyArray([]);
      setIsLoadedComp(false);
      setRemappedMarketTrackerData([]);
      setLocationList([]);
      setLoadedList(false);
      setCompaniesList([]);
      setLoadedLocationList(false);
      setYearsList([]);
      setCustomComp([]);
      setCompName("");
      setTrackerID(id);
    }
  }, [id, trackerID]);

  const getCustomComp = () => {
    setIsLoadedComp(false);
    const data = new FormData();
    const config = {
      method: "get",
      url: `${baseURL}/custom_comps/${id}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
      data: data,
    };
    axios(config).then((resp) => {
      setCustomComp(resp.data.data);
      setCompName(resp.data.data.name);
      // setCompMetrics( resp.data.data.custom_comp_metrics );
      setTimeout(() => {
        setIsLoadedComp(true);
      }, 500);
      console.log(resp.data.data);
    });
  };
  // console.log(customComp.resource_ids[0])
  // console.log(resourceIDs[0])

  const getCompanyList = () => {
    let params = "";
    resourceIDs.map((el) => {
      return (params = params + "&ids[]=" + el);
    });
    const config = {
      method: "get",
      url: `${baseURL}/companies?${params}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    if (params) {
      axios(config).then((resp) => {
        setCompaniesList(resp.data.data);
        setIsLoadedComp(true);
      });
    }
  };

  const getLocationsList = () => {
    setLoadedLocationList(false);
    let params = "&includeMetrics=true";
    resourceIDs.map((el) => {
      return (params = params + "&companyIds[]=" + el);
    });

    const config = {
      method: "get",
      url: `${baseURL}/locations?${params}`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios(config)
      .then((response) => {
        // console.log( response.data.data )
        setLocationList(response.data.data);
        setLoadedLocationList(true);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadedLocationList(true);
      });
  };

  useEffect(() => {
    context.handleSetAppContentClass("mh-100vh-88");
    setLoadedList(false);
  }, [id]);

  useEffect(() => {
    // console.log('resourceIDs', resourceIDs)
    if (resourceIDs && resourceIDs.length > 0) {
      console.log("getLocationsList");
      getLocationsList();
    }
  }, [companiesList, resourceIDs]);

  useEffect(() => {
    if (
      !preview &&
      customComp.resource_ids !== undefined &&
      customComp.resource_ids.length > 0
    ) {
      setSearchCompanyArray(remapResourceIDs(customComp.resource_ids));
    }
    setRemappedMarketTrackerData(remapMarketTrackerTimeLine(locationList));
  }, [locationList, trackerID, resourceIDs, customComp]);

  useEffect(() => {
    if (resourceIDs) {
      // console.log('getCompanyList')
      getCompanyList();
    }
  }, [resourceIDs]);

  useEffect(() => {
    let _years = [];
    remappedMarketTrackerData.map((location) => {
      if (!_years.includes(location.year)) {
        _years.push(location.year);
      }
    });
    _years.sort(function (a, b) {
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
    console.log("_years: ", _years);
    setYearsList(_years);

    _years.length === 0 ? setNoLocations(true) : setNoLocations(false);

    setTimeout(() => {
      setLoadedList(true);
    }, 1000);
  }, [remappedMarketTrackerData]);

  useEffect(() => {
    let _resourceIDs = [];
    if (
      preview ||
      searchCompanyArray.length !== resourceIDs.length ||
      (searchCompanyArray.length &&
        searchCompanyArray[0]?.id !== resourceIDs[0])
    ) {
      searchCompanyArray.map((comp) => _resourceIDs.push(comp.id));
      setResourceIDs(_resourceIDs);
    }
  }, [searchCompanyArray, trackerID]);

  useEffect(() => {
    if (!isLoadedComp && !preview) {
      getCustomComp();
    }
  }, [isLoadedComp, id, needUpdateComp]);

  useEffect(() => {}, [needUpdateTable]);

  useEffect(() => {
    if (customComp.length > 0) {
      setCompName(customComp.name);
      setResourceIDs(customComp.resource_ids);
    }
  }, [customComp, isLoadedComp]); //|| id !== trackerID && customComp.length === 0

  if (!isLoadedComp || !yearsList || !remappedMarketTrackerData) {
    return (
      <div className="d-flex justify-content-center align-items-center text-center min-vh-100">
        <div className="spinner-border m-5" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
  // console.log(remappedMarketTrackerData, loadedLocationList) // id === trackerID && +id === customComp.id

  const findLocations = (array, month, compId) => {
    return array.filter((item) => {
      // console.log(item)
      const companyId = item.company.id;
      const openingDate = new Date(item.openingDate);
      const yearMonth = openingDate.toISOString().slice(0, 7); // Extracts year and month
      return companyId === compId && yearMonth === month;
    });
  };

  function formatCities(cities) {
    const cityMap = {};
    cities.forEach((city) => {
      const match = city.match(/^(.*?)\s\((\d+)\)$/);
      if (match) {
        const cityName = match[1];
        const count = parseInt(match[2]);
        cityMap[cityName] = Math.max(cityMap[cityName] || 0, count);
      } else {
        cityMap[city] = (cityMap[city] || 0) + 1;
      }
    });

    const formattedCities = [];
    for (const cityName in cityMap) {
      const count = cityMap[cityName];
      if (count > 1) {
        formattedCities.push(`${cityName} (${count})`);
      } else {
        formattedCities.push(cityName);
      }
    }

    return formattedCities.join(", ");
  }

  const ToggleItem = ({ id }) => {
    const [checked, setChecked] = useState(isHideCompaniesId.includes(id));

    const handleToggleShowCompany = (id) => {
      setChecked(!checked);
      setNeedUpdateTable(!needUpdateTable);

      function addItemOrRemoveIfExists(array, item) {
        const index = isHideCompaniesId.indexOf(item);
        if (index !== -1) {
          array.splice(index, 1);
        } else {
          array.push(item);
        }
        return array;
      }
      let _isHideCompaniesId = addItemOrRemoveIfExists(isHideCompaniesId, id);
      setIsHideCompaniesId(_isHideCompaniesId);
    };

    useEffect(() => {
      setChecked(isHideCompaniesId.includes(id));
    }, [isHideCompaniesId]);

    return (
      <div className="form-check form-switch mx-3">
        <input
          className="form-check-input fs-18px"
          type="checkbox"
          id={"flexSwitchCheck-" + id}
          checked={checked}
          onChange={() => handleToggleShowCompany(id)}
        />
        <label
          className="form-check-label fz-12px lh-20"
          htmlFor={"flexSwitchCheck-" + id}
        ></label>
      </div>
    );
  };

  const TogglerCompanies = () => {
    // companiesList.map(d=>console.log(d.id));

    return companiesList.map((res, i) => {
      return <ToggleItem id={res.id} />;
    });
  };

  return (
    <div className={preview ? "p-4" : ""}>
      {isLoadedComp &&
        yearsList &&
        remappedMarketTrackerData &&
        loadedLocationList &&
        customComp && (
          <>
            <TitleTagCustomComp
              compName={compName}
              id={id}
              marketTracker
              isPopular={customComp.default_comp}
              ownerID={customComp.user_id}
              taggings={customComp.taggings}
              preview={preview}
              showLogo={() => setShowLogo(!showLogo)}
              isShowLogo={showLogo}
              noLocations={!noLocations}
            />
            <WrapperCustomComp>
              <TitleCustomComp
                compName={compName}
                id={id}
                marketTracker
                isPopular={customComp.default_comp}
                ownerID={customComp.user_id}
                taggings={customComp.taggings}
                preview={preview}
                showLogo={() => setShowLogo(!showLogo)}
                isShowLogo={showLogo}
                noLocations={!noLocations}
              />
              {!isLoadedComp && !loadedLocationList && (
                <div className="d-flex justify-content-center align-items-center text-center h-500px">
                  <div className="spinner-border m-5" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}

              {/*<div className="h-30px my-2 d-flex">*/}
              {/*  {companiesList.length > 1 && <TogglerCompanies />}*/}
              {/*</div>*/}

              {!noLocations ? (
                <TableChartTimelineWrapper
                  // className={preview ? ' py-2' : ''}
                  tableType="marketTrackerTimeline"
                  style={{
                    // maxHeight: 'calc(100vh - 280px)'
                    maxHeight: "calc(100vh - 250px)",
                  }}
                >
                  <table className="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="px-3 th-header"
                          style={{
                            textAlign: "left",
                            left: "-1px",
                            zIndex: "2",
                            width: "300px",
                            columns: 1,
                          }}
                        >
                          Company
                        </th>
                        {yearsList.map((el, i) => {
                          return (
                            <th scope="col" key={`company-${el}-${i}`}>
                              {el}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {resourceIDs &&
                        resourceIDs.map((ids) => {
                          return companiesList.map((comp, index) => {
                            if (
                              comp.id === +ids &&
                              !isHideCompaniesId.includes(comp.id)
                            ) {
                              return (
                                <tr
                                  key={`tr-${index}`}
                                  className="text-left"
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <LogoNameTimeline
                                    comp={comp}
                                    showLogo={showLogo}
                                    idx={index}
                                  />
                                  {yearsList.map((year) => {
                                    // let _entries;
                                    // _entries = remappedMarketTrackerData.filter( entry => (year === entry.year && comp.id === entry.company_id) );
                                    // _entries.sort( function ( a, b ) {
                                    //   if ( a.month > b.month ) {
                                    //     return 1;
                                    //   }
                                    //   if ( a.month < b.month ) {
                                    //     return -1;
                                    //   }
                                    //   return 0;
                                    // } )
                                    let _color;
                                    if (
                                      comp.color === undefined ||
                                      comp.color === null
                                    ) {
                                      _color = colorPatternDefault[index];
                                    } else {
                                      _color = comp.color;
                                    }
                                    // if ( _entries.length === 0 ) return <td><p>—</p></td>;

                                    return (
                                      <td key={"td-" + year + comp.id}>
                                        {monthsMap.map((months) => {
                                          console.log("months: ", months);
                                          console.log(
                                            "${year}-${months.order}`: ",
                                            `${year}-${
                                              months.order < 10
                                                ? "0" + months.order
                                                : months.order
                                            }`
                                          );
                                          console.log("comp.id: ", comp.id);
                                          let filteredLocations = findLocations(
                                            locationList,
                                            `${year}-${
                                              months.order < 10
                                                ? "0" + months.order
                                                : months.order
                                            }`,
                                            comp.id
                                          );
                                          console.log(
                                            "filteredLocations: ",
                                            filteredLocations
                                          );
                                          // let citiesFilteredLocations = "";
                                          const citiesFilteredLocations =
                                            filteredLocations.map(
                                              (en) => en.city
                                            );

                                          console.log(
                                            "citiesFilteredLocations: ",
                                            citiesFilteredLocations
                                          );
                                          const citiesString = formatCities(
                                            citiesFilteredLocations.filter(
                                              (c) => !!c
                                            )
                                          ); //.join( ', ' );

                                          if (citiesString.length === 0)
                                            return <p>{months.name}:</p>;
                                          return (
                                            <OverlayTrigger
                                              placement="bottom"
                                              delay={{ show: 400, hide: 400 }}
                                              overlay={renderTooltip(
                                                citiesString
                                              )}
                                            >
                                              <p
                                                style={{
                                                  backgroundColor:
                                                    citiesString.length
                                                      ? hexToRGBA(_color, 0.3)
                                                      : "transparent",
                                                }}
                                              >
                                                {months.name}:{" "}
                                                {citiesString.length
                                                  ? kitcut(citiesString, 15)
                                                  : ""}
                                              </p>
                                            </OverlayTrigger>
                                          );
                                        })}

                                        {/*{_entries.map( ent => {*/}
                                        {/*  return <p key={"p" + ent.entry_data}>{ent.entry_data}</p>*/}
                                        {/*} )}*/}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            }
                          });
                        })}
                    </tbody>
                  </table>
                </TableChartTimelineWrapper>
              ) : (
                <div>
                  {isLoadedComp && loadedLocationList && (
                    <h4>No Entry Date information available for locations</h4>
                  )}
                </div>
              )}
            </WrapperCustomComp>
          </>
        )}
    </div>
  );
};

export default MarketTrackerTimeline;
