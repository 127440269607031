import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  chosenTagsAtom,
  companiesLimitAtom,
  companiesListAtom,
  companiesLoadedListAtom,
  locationsListAtom,
  locationsLoadedListAtom,
  metricLimitAtom,
  newCustomCompNameAtom,
  newCustomCompTypeAtom,
  nextStepTitleAtom,
  stepOfCreationCompsAtom,
  tagsOptionsAtom,
} from "../../atoms/profileAtom";
import PerformanceMapIcon from "../../components/shared/PerfomanceMapIcon";
import TimeLineIcon from "../../components/shared/TimeLineIcon";
import { APIHeaders } from "../../components/shared/helpers";
import axios from "axios";
import { CheckWrapper } from "../custom-comps/NewCompSetStep1";
import CreatableSelect from "react-select/creatable";

const LabelTitle = styled.span`
  font-weight: 500;
  font-size: 18px;
  color: #000;
  display: block;
  line-height: 18px;
`;

const LabelDescription = styled.span`
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  margin-top: 10px;
  margin-bottom: 12px;
  color: #333333;
  display: block;
  min-height: 4.5rem;
`;

const SelectCard = styled.div`
  width: 400px;
  padding: 20px;
  //border: ${(props) =>
    props.selected === true ? "1px solid #aaa" : "1px solid #aaa"};
  box-shadow: ${(props) =>
    props.selected === true
      ? "0px 0px 4px 0px rgba(0, 0, 0, 0.13)"
      : "0px 0px 10px 0px #eee"};
  border-radius: 20px;
  margin-right: 25px;
  transition: all 0.3s;
  cursor: pointer;
  background-color: #fff;
  opacity: ${(props) => (props.selected === true ? "1" : "0.75")};

  &:hover {
    opacity: 1;
    box-shadow: 0px 0px 10px 0px #555;

    .check-wrapper {
      color: var(--bs-secondary);
      opacity: 0.7;
    }
  }

  &:nth-last-of-type(1) {
    margin-right: 0;
  }

  label {
    cursor: pointer;
    width: 100%;
  }

  .check-wrapper {
    transition: all 0.3s;
    opacity: 0.4;
  }
`;

const Card = styled.div`
  border-radius: 12px;
  background: #fff;
  padding: 32px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
`;

const NewCompSetStep1 = () => {
  const [compName, setCompName] = useRecoilState(newCustomCompNameAtom);
  const [compType, setCompType] = useRecoilState(newCustomCompTypeAtom);
  const [companiesLimit, setCompaniesLimit] =
    useRecoilState(companiesLimitAtom);
  const [metricLimit, setMetricLimit] = useRecoilState(metricLimitAtom);
  const [stepOfCreationComps, setStepOfCreationComps] = useRecoilState(
    stepOfCreationCompsAtom
  );
  const [nextStepTitle, setNextStepTitle] = useRecoilState(nextStepTitleAtom);
  const [locationList, setLocationList] = useRecoilState(locationsListAtom);
  const [loadedList, setLoadedList] = useRecoilState(locationsLoadedListAtom);
  const [tagsOptions, setTagsOptions] = useRecoilState(tagsOptionsAtom);
  const [chosenTags, setChosenTags] = useRecoilState(chosenTagsAtom);
  const [apiTags, setApiTags] = useState([]);
  const [loadedTags, setLoadedTags] = useState(false);
  const [defaultTag, setDefaultTag] = useState({});
  const baseURL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const handleName = (e) => {
    setCompName(e.target.value);
  };

  useEffect(() => {
    if (!locationList.length && !loadedList) {
      getLocationList();
    }
    if (tagsOptions.length === 0) {
      getTagsList();
    }
  }, []);

  const getTagsList = () => {
    setLoadedTags(false);
    const config = {
      method: "get",
      url: `${baseURL}/tags`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios(config)
      .then((resp) => {
        let _options = resp.data.data;
        setApiTags(resp.data.data);
        // let _filteredOption = _options.filter(t=>t.label === 'General Research')[0];
        // console.log(_filteredOption)
        setApiTags(resp.data.data);
        setTagsOptions(_options);
        // setDefaultTag(_filteredOption)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/user/login", { replace: true });
        }
      })
      .finally(() => {
        setLoadedTags(true);
      });
  };

  useEffect(() => {
    if (tagsOptions.length > 0) {
      let _filteredOption = tagsOptions.filter(
        (t) => t.label === "General Research"
      )[0];
      setDefaultTag(_filteredOption);
      setChosenTags([_filteredOption]);
    }
  }, [tagsOptions]);

  const getLocationList = () => {
    const config = {
      method: "get",
      url: `${baseURL}/locations`,
      headers: APIHeaders,
      withCredentials: true,
      credentials: "include",
    };
    axios(config)
      .then((response) => {
        // console.log( response.data.data )
        // setLocationList( response.data.data.filter(company => company.status === 'OPEN') );
        setLocationList(response.data.data);
      })
      .catch((error) => {
        console.log(error.response.status);
      })
      .finally(() => {
        setLoadedList(true);
      });
  };

  const handleType = (e) => {
    const type = e.target.value;
    setCompType(type);
    if (
      type === "CustomComps::MarketTimeline" ||
      type === "CustomComps::MarketTrackerMap"
    ) {
      setCompaniesLimit(4);
      // setNextStepTitle('Create Map');
    }
    if (compName) {
      setStepOfCreationComps(2);
    }
  };

  const handleChangeTag = (e) => {
    setChosenTags(e);
  };

  return (
    <Card className="pb-4 pt-4">
      <div className="row mx-2 pb-4 pt-4">
        <div className="row justify-content-between mb-2">
          <div className="col-md-6 col-12 mb-5">
            <div className="mb-3 d-flex align-items-center">
              <h4 className="color-neutral-black fw-400">
                My Market Tracker Name:
              </h4>
            </div>
            <div className="position-relative">
              <input
                className="form-control w100"
                type="text"
                value={compName}
                onChange={handleName}
                placeholder="e.g. VC-backed winners, Premium Coffee in LA, etc."
              />
              {compName && (
                <span
                  className="clear-field"
                  style={{ right: "15px" }}
                  onClick={() => setCompName("")}
                >
                  <i className="fa fa-times"></i>
                </span>
              )}
            </div>
          </div>
          {loadedTags ||
          (tagsOptions.length > 0 &&
            defaultTag &&
            Object.keys(defaultTag).length > 0) ? (
            <div className="col-md-8 col-12 mb-4">
              <div className="d-flex align-items-center mb-3">
                <h4 className="color-neutral-black fw-400">Project tag(s):</h4>
                <span className="px-2">
                  Search for existing tag or create a new one
                </span>
              </div>
              <CreatableSelect
                onChange={(e) => handleChangeTag(e)}
                classNamePrefix="select"
                defaultValue={defaultTag}
                isLoading={tagsOptions.length === 0 && !loadedTags}
                name="tags"
                isClearable={false}
                isMulti
                placeholder="Select project tag or create new"
                options={tagsOptions}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "#b787b3 !important"
                      : "var(--app-component-border-color) ",
                    boxShadow: state.isFocused
                      ? "0 0 0 0.25rem rgba(159, 95, 153, 0.25) !important"
                      : "0 0 0 0.25rem rgba(159, 95, 153, 0)",
                  }),
                }}
              />
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center text-center h-40px">
              <div className="spinner-border m-5" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </div>
        <div className="col-12 mb-4">
          <h4 className="color-neutral-black fw-400 mb-3">
            Create a Timeline or Map:
          </h4>
          <div className="d-flex mb-5 justify-content-start">
            <SelectCard selected={compType === "CustomComps::MarketTimeline"}>
              <input
                className="form-check-input d-none"
                type="radio"
                id="comp-table"
                name="type-comp"
                checked={compType === "CustomComps::MarketTimeline"}
                onChange={handleType}
                onClick={handleType}
                value="CustomComps::MarketTimeline"
              />
              <label htmlFor="comp-table">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <TimeLineIcon size="50" />
                    <div className="w-10px"></div>
                    <LabelTitle>Market Entry Timeline</LabelTitle>
                  </div>
                  <CheckWrapper
                    className="check-wrapper"
                    selected={compType === "CustomComps::MarketTimeline"}
                  >
                    {compType === "CustomComps::MarketTimeline" ? (
                      <i className="fa fa-check-circle fs-18px"></i>
                    ) : (
                      <i className="fa-regular fa-circle fs-18px"></i>
                    )}
                  </CheckWrapper>
                </div>
                <LabelDescription>
                  See a timeline displaying when a brand entered a new market;
                  pick up to 4 brands
                </LabelDescription>
              </label>
            </SelectCard>
            <SelectCard selected={compType === "CustomComps::MarketTrackerMap"}>
              <input
                className="form-check-input d-none"
                type="radio"
                id="comp-map"
                name="type-map"
                checked={compType === "CustomComps::MarketTrackerMap"}
                onChange={handleType}
                onClick={handleType}
                value="CustomComps::MarketTrackerMap"
              />
              <label htmlFor="comp-map">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <PerformanceMapIcon size="50" />
                    <div className="w-10px"></div>
                    <LabelTitle>Market Performance Map</LabelTitle>
                  </div>
                  <CheckWrapper
                    className="check-wrapper"
                    selected={compType === "CustomComps::MarketTrackerMap"}
                  >
                    {compType === "CustomComps::MarketTrackerMap" ? (
                      <i className="fa fa-check-circle fs-18px"></i>
                    ) : (
                      <i className="fa-regular fa-circle fs-18px"></i>
                    )}
                  </CheckWrapper>
                </div>
                <LabelDescription>
                  See the footprint of any brand’s locations and the performance
                  of those locations; pick up to 4 brands
                </LabelDescription>
              </label>
            </SelectCard>
          </div>
        </div>
        {/*<div className="d-flex justify-content-end">*/}
        {/*  <Link to=""*/}
        {/*        className={compType && compName ? "fs-16px mx-5 btn w-150px text-light bg-green2 text-uppercase" : "fs-16px mx-5 btn w-150px text-light bg-green2 text-uppercase disabled"}*/}
        {/*        onClick={() => setStepOfCreationComps( 2 )}*/}
        {/*  >Next</Link>*/}
        {/*</div>*/}
      </div>
    </Card>
  );
};

export default NewCompSetStep1;
